import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgScreenShrinkIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M9.175 7.423a.6.6 0 0 1-.598-.598V4.29a.598.598 0 0 1 1.195 0v1.093l3.207-3.207a.598.598 0 1 1 .846.846l-3.207 3.207h1.093a.598.598 0 1 1 0 1.195zM6.825 8.577c.33 0 .598.267.598.598v2.536a.598.598 0 1 1-1.195 0v-1.093L3.02 13.825a.598.598 0 1 1-.846-.846l3.207-3.207H4.29a.598.598 0 0 1 0-1.195z"
    />
  </svg>
);
export default SvgScreenShrinkIcon;
