import type { CSSProperties, FC } from "react";
import React, { useCallback, useMemo, memo } from "react";
import { Flipper } from "react-flip-toolkit";
import ItemTypesEnum, {
  ItemComponents,
  OtherSettingTypesEnum,
} from "../type/ItemTypes";
import { getDefaultItem } from "../type/Interface";
import { icons } from "../../assets";
import { useList } from "../store/listContext";
import DragSortItem from "./DragSortItem";
import { renderCustomItemSetting } from "../../utils/methods";

const { AddIcon, FlowIcon } = icons;

const buttonStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: 4,
  padding: "10px 8px",
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "16px",
  textWrap: "nowrap",
  borderRadius: 8,
  border: "1px solid #F0F0F2",
};

const buttonContainerStyle: CSSProperties = {
  display: "flex",
  gap: 8,
  alignItems: "center",
  padding: "16px 0px",
};

const containerStyle: CSSProperties = {
  padding: "16px 0px",
  display: "flex",
  flexDirection: "column",
  gap: 8,
};

const TabPages: FC = () => {
  const {
    list,
    moveFirstLayer,
    addFirstLayer,
    pageDefaultSetting,
    customItemList,
  } = useList();

  const pages = useMemo(() => {
    return list.filter((v) => v.type === ItemTypesEnum.PAGE);
  }, [list]);

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number, item: any) => {
      moveFirstLayer(item.id, hoverIndex);
    },
    [moveFirstLayer]
  );

  const handleAddPage = () => {
    const defaultPageItem = getDefaultItem(
      ItemTypesEnum.PAGE,
      pageDefaultSetting
    );
    addFirstLayer(defaultPageItem.originalItem);
  };
  const handleWorkflows = () => {
    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.FORMCONTROL,
      "onWorkflows"
    );
  };

  const renderItem = (item: any, index: number) => {
    const Component = ItemComponents[item.type].ShortItem;
    return (
      <DragSortItem key={item.id} item={item} index={index} onMove={handleMove}>
        <Component item={item} index={index + 1} fieldName={item.name} />
      </DragSortItem>
    );
  };

  return (
    <div>
      <div style={buttonContainerStyle}>
        <button type="button" onClick={handleAddPage} style={buttonStyle}>
          <AddIcon />
          <span>Add Section</span>
        </button>

        <button type="button" onClick={handleWorkflows} style={buttonStyle}>
          <FlowIcon />
          <span>Workflows</span>
        </button>
      </div>

      <div style={containerStyle}>
        <Flipper flipKey={pages}>{pages.map(renderItem)}</Flipper>
      </div>
    </div>
  );
};

export default memo(TabPages);
