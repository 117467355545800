import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgLongTextIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#42DA6D"}
      d="M1.333 4.667C1.333 4.298 1.632 4 2 4h12a.667.667 0 1 1 0 1.333H2a.667.667 0 0 1-.667-.666M1.333 8c0-.368.299-.667.667-.667h12a.667.667 0 1 1 0 1.334H2A.667.667 0 0 1 1.333 8M2 10.667A.667.667 0 1 0 2 12h5.333a.667.667 0 0 0 0-1.333z"
    />
  </svg>
);
export default SvgLongTextIcon;
