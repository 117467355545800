import type { CSSProperties, FC } from "react";
import React, { useState, useCallback, memo, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { Handle, Position, NodeToolbar, useReactFlow } from "@xyflow/react";
import CustomHandle from "../components/CustomHandle";
import styled from "styled-components";
import { useFlow } from "../store/flowContext";
import FlowState from "../type/FlowState";
import ItemTypes from "../type/ItemTypes";
import {
  renderCustomComponent,
  generateEdge,
  generateNodeAndEdge,
} from "../../utils/methods";

const CenteredIconContainer = styled.div`
  position: relative;
  background-color: lightgray;

  &::after {
    content: "+";
    position: absolute;
    top: -6px;
    left: 4px;
    color: #fcfcfc;
    font-size: 28px;
  }
`;

const text_updater_node_label: CSSProperties = {
  display: "block",
  color: "#777",
  fontSize: "12px",
};

const right_handle: CSSProperties = {
  width: "28px",
  height: "28px",
  backgroundColor: "#000821",
  cursor: "pointer",
};

const menu_text: CSSProperties = {
  fontFamily: "poppins",
  fontSize: "14px",
  color: "#262D42",
  fontWeight: 400,
  lineHeight: "20px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
};

const menu_text_layout: CSSProperties = {
  padding: "2px 8px 2px 8px",
};

const menu_text_layout_border: CSSProperties = {
  borderBottom: "1px solid #F0F0F2",
};

const menu_text_button: CSSProperties = {
  width: "100%",
  height: "100%",
  ...menu_text_layout,
  textAlign: "left",
};

const disabled: CSSProperties = {
  cursor: "no-drop",
};

const disabled_text: CSSProperties = {
  color: "#a6a6a6",
};

export interface ButtonNodeProps {
  id: string;
  data: any;
  type: string;
  isConnectable: boolean;
  selected: boolean;
  positionAbsoluteX: number;
  positionAbsoluteY: number;
}

const ButtonNode: FC<ButtonNodeProps> = (props) => {
  const { id, data, type, isConnectable, selected } = props;

  const {
    update,
    flowState,
    isValidConnectionButton,
    onChangeNodesData,
    onAddNodeAndEdge,
    customItemList,
  } = useFlow();
  const { screenToFlowPosition } = useReactFlow();

  const readOnly = flowState === FlowState.PREVIEW;

  const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false);

  const handleOnChange = (e: any) => {
    onChangeNodesData(id, e);
  };

  const handleOnChangeById = (_id: string, e: any) => {
    onChangeNodesData(_id, e);
  };

  const newProps = {
    id,
    flowState,
    data,
    connectNodes: [],
    connectToSteps: [],
    onChange: handleOnChange,
    onChangeById: handleOnChangeById,
    selected,
    update,
  };

  const handleOnClick = () => {
    setIsVisibleMenu(true);
  };

  const handleOnClickByClose = (e: any) => {
    e.stopPropagation();
    setIsVisibleMenu(false);
  };

  const handleAddType = useCallback(
    (_type: ItemTypes, _event: any) => {
      _event.stopPropagation();

      if (isConnectable) {
        const _id = uuidv4();
        const newNode = {
          id: _id,
          type: _type,
          position: screenToFlowPosition({
            x: _event.clientX + 250,
            y: _event.clientY,
          }),
          data: { ...(customItemList?.[_type]?.["defaultSetting"] || {}) },
          origin: [0.5, 0.0],
        };
        const newEdge = generateEdge(id, _id, type, _type);
        onAddNodeAndEdge(newNode, newEdge);

        if (_type === ItemTypes.STEP) {
          const newNodeAndEdge = generateNodeAndEdge(
            newNode.id,
            newNode.type,
            ItemTypes.BUTTON,
            { ...(customItemList?.[ItemTypes.BUTTON]?.["defaultSetting"] || {}) },
            {
              x: newNode.position.x + 250,
              y: newNode.position.y,
            }
          );
          onAddNodeAndEdge(newNodeAndEdge.node, newNodeAndEdge.edge);
        }

        setIsVisibleMenu(false);
      }
    },
    [
      isConnectable,
      screenToFlowPosition,
      customItemList,
      id,
      type,
      onAddNodeAndEdge,
    ]
  );

  const checkAddType = isValidConnectionButton(id);

  const componentMemo = useMemo(() => {
    return customItemList?.[type] ? (
      renderCustomComponent(customItemList, type, "Component", newProps)
    ) : (
      <label htmlFor="text" style={text_updater_node_label}>
        {type}
      </label>
    );
  }, [customItemList]);

  const menuList = [
    { key: ItemTypes.STEP, title: "Add a step" },
    { key: ItemTypes.SENDEMAIL, title: "Send Emails" },
    { key: ItemTypes.BACKTOPREVIOUS, title: "Back to previous" },
    { key: ItemTypes.CREATENEWVERSION, title: "Create new version" },
    { key: ItemTypes.CLOSE, title: "Close" },
  ];

  return (
    <div>
      <CustomHandle id={type} type="target" isConnectable={isConnectable} />

      <div onClick={handleOnClickByClose}>{componentMemo}</div>

      <Handle
        id={`${type}Out`}
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={right_handle}
        onClick={handleOnClick}
      >
        <CenteredIconContainer />
      </Handle>

      {readOnly ? null : (
        <NodeToolbar
          isVisible={isVisibleMenu && selected}
          position={Position.Bottom}
          style={{
            padding: "0px 8px 8px 8px",
            gap: "0px",
            borderRadius: "12px",
            backgroundColor: "#E5F4FF",
          }}
        >
          <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <span style={menu_text}>Workflow Options</span>
          </div>
          <div
            style={{
              paddingTop: "8px",
              paddingBottom: "8px",
              borderRadius: "8px",
              backgroundColor: "#FEFEFE",
            }}
          >
            {menuList.map((v: { key: ItemTypes; title: string }, i: number) => {
              const isDisabled =
                !checkAddType[v.key as keyof typeof checkAddType];

              return (
                <div
                  style={
                    menuList.length - 1 === i ? {} : menu_text_layout_border
                  }
                  key={`menu_${i}`}
                >
                  <button
                    style={{
                      ...menu_text_button,
                      ...(isDisabled ? disabled : {}),
                    }}
                    onClick={(e: any) => {
                      if (isDisabled) return;
                      handleAddType(v.key, e);
                    }}
                    // disabled={isDisabled}
                  >
                    <span
                      style={{
                        ...menu_text,
                        ...(isDisabled ? disabled_text : {}),
                      }}
                    >
                      {v.title}
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
        </NodeToolbar>
      )}
    </div>
  );
};

export default memo(ButtonNode);
