import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgMultipleSelectionIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#A96ECE"}
      fillRule="evenodd"
      d="M1.333 3c0-.92.746-1.667 1.667-1.667h8.667c.92 0 1.666.746 1.666 1.667v8.666c0 .92-.746 1.667-1.666 1.667H3c-.92 0-1.667-.746-1.667-1.667zM3 2.666A.333.333 0 0 0 2.667 3v8.666c0 .184.149.334.333.334h8.667c.184 0 .333-.15.333-.334V3a.333.333 0 0 0-.333-.334z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#A96ECE"}
      fillRule="evenodd"
      d="M14.667 4c.368 0 .666.298.666.666v9.355c0 .725-.587 1.312-1.312 1.312H4.667a.667.667 0 0 1 0-1.333H14V4.666c0-.368.298-.666.667-.666M10.427 4.82a.667.667 0 0 1 .085.94l-3.333 4a.667.667 0 0 1-1 .027l-2-2.147a.667.667 0 0 1 .975-.909L6.64 8.325l2.849-3.419a.667.667 0 0 1 .939-.085"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMultipleSelectionIcon;
