import type { FC } from "react";
import React from "react";
import { useFlow } from "./store/flowContext";
import {
  renderCustomComponent,
  generateNodeAndEdge,
  generateDefaultNodeAndEdge,
} from "../utils/methods";
import ItemTypes from "./type/ItemTypes";
import { BaseContainerData } from "../form/type/Interface";

const SettingArea: FC = () => {
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    update,
    onChangeNodesData,
    onAddNodeAndEdge,
    onDeleteNodes,
    customItemList,
    isValidateFlow,
    onConnectNode,
    findConnectButton,
    findConnectStep,
    findEdgesConnectStep,
    flowState,
    isFirstStep,
    showList,
  } = useFlow();

  const target = (nodes || []).find((v) => v.selected);

  const handleOnChange = (e: any) => {
    onChangeNodesData(target.id, e);
  };

  const handleOnChangeById = (_id: string, e: any) => {
    onChangeNodesData(_id, e);
  };

  const handleOnAddButton = (attr: any) => {
    if (!target) {
      return;
    }

    const newAttr = {
      ...(customItemList?.[ItemTypes.BUTTON]?.["defaultSetting"] || {}),
      ...(attr || {}),
    };
    const newNodeAndEdge = generateNodeAndEdge(
      target.id,
      target.type,
      ItemTypes.BUTTON,
      newAttr,
      {
        x: target.position.x + 500,
        y: target.position.y,
      }
    );
    onAddNodeAndEdge(newNodeAndEdge.node, newNodeAndEdge.edge);
  };

  const handleOnRemoveButton = (_id: string) => {
    onDeleteNodes(_id);
  };

  const findEdges = edges
    .filter((edge: any) => edge.source === target?.id)
    .map((v) => v.target);
  const connectNodes: any[] = nodes.filter((v) => findEdges.includes(v.id));
  const connectToSteps: any[] = [];

  const handleOnConnectStep = (targetId: string) => {
    onConnectNode(target?.id, targetId);
  };

  const onGenerateDefaultData = (formData: BaseContainerData[] | any[]) => {
    const defaultData = generateDefaultNodeAndEdge(formData, customItemList);
    setNodes(defaultData.node);
    setEdges(defaultData.edge);
    return defaultData;
  };

  const newProps = {
    id: target?.id,
    flowState,
    data: target?.data,
    connectNodes, // step:前面全部的 button node, BackToPrevious CreateNewVersion:前面全部的 step node
    connectToSteps, // BackToPrevious CreateNewVersion 才有
    onChange: handleOnChange,
    onChangeById: handleOnChangeById,
    onAddButton: handleOnAddButton, // step 才有
    onRemoveButton: handleOnRemoveButton, // step 才有
    onConnectStep: handleOnConnectStep, // BackToPrevious CreateNewVersion 才有
    update,
    isFirstStep: isFirstStep[0]?.id === target?.id,
  };

  switch (target?.type) {
    case ItemTypes.STEP:
      newProps.connectNodes = findConnectButton(target.id);
      break;
    case ItemTypes.BACKTOPREVIOUS:
      newProps.connectNodes = findConnectStep(target.id);
      newProps.connectToSteps = findEdgesConnectStep(
        target.id,
        newProps.connectNodes
      );
      break;
    case ItemTypes.CREATENEWVERSION: {
      newProps.connectNodes = findConnectStep(target.id);
      newProps.connectToSteps = findEdgesConnectStep(
        target.id,
        newProps.connectNodes
      );
      break;
    }

    default:
      break;
  }

  const dataString = showList
    ? [
        <pre>{JSON.stringify(isValidateFlow, null, 2)}</pre>,
        <div>----------------------</div>,
        <div>{target && <pre>{JSON.stringify(target, null, 2)}</pre>}</div>,
        <div>----------------------</div>,
        <pre>{JSON.stringify({ nodes, edges }, null, 2)}</pre>,
      ]
    : "";

  // node setting
  if (target && customItemList?.[target.type]) {
    return (
      <div>
        {renderCustomComponent(
          customItemList,
          target.type,
          "Setting",
          newProps
        )}
        {dataString}
      </div>
    );
  }

  // base setting
  if (!target && customItemList?.["settingArea"]) {
    return (
      <div>
        {renderCustomComponent(customItemList, "settingArea", "Setting", {
          data: null,
          connectEdges: [],
          connectNodes: [],
          onChange: () => {},
          onChangeById: handleOnChangeById,
          update,
          onGenerateDefaultData,
        })}
        {dataString}
      </div>
    );
  }

  return <div>{dataString}</div>;
};

export default SettingArea;
