import type { FC, ReactNode } from "react";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { isEqual, debounce } from "lodash"; // 使用 lodash 的 isEqual 進行深度比較
import { v4 as uuidv4 } from "uuid";

import { ListContext } from "./store/listContext"; // 导入 ListContext
import { historyStore } from "./store/historyStore";

import {
  tempPageId,
  tempRowId,
  BaseContainerData,
  BaseItemData,
  FindSecondContainer,
  SearchItem,
  generateDefaultItem,
} from "./type/Interface";

import ItemTypesEnum, { AllItemKeys } from "./type/ItemTypes";
import { FormState } from "./type/AttrTypes";

import {
  checkNotDuplicateIds,
  IsPathIdInTable,
  deepCopyWithNewId,
  // findDuplicateIds,
  validateDataStructure,
  isStructureDifferent,
  // normalizeData,
} from "../utils/methods";

export type CustomItemList = {
  [key: string]: {
    Component?: ReactNode | ((e: any) => void);
    Setting?: ReactNode | ((e: any) => void);
    defaultSetting?: any;

    // Table
    DailogComponent?: ReactNode | ((e: any) => void);

    // formControl
    onMenu?: (e: any) => void;
    onPage?: (e: any) => void;
    onUndo?: (e: any) => void;
    onRedo?: (e: any) => void;

    // itemSetting
    onSetting?: (e: string) => void;
    onCopy?: (e: string) => void;
    onDelete?: (e: string) => void;

    // Tab
    onWorkflows?: (e: any) => void;

    onRowDuplicate?: (e: any) => void;
    onColumnDuplicate?: (e: any) => void;
    onRowAssignFieldsToSteps?: (e: any) => void;
    onRowDelete?: (e: any) => void;
    onColumnDelete?: (e: any) => void;
    onColumnAssignFieldsToSteps?: (e: any) => void;
  };
};

export interface DragContainerProps {
  value: BaseContainerData[];
  steps?: any[];
  stepButtons?: any[];
  stepTeams?: any[];
  currentStepId?: string;
  currentTeamIds?: number[];
  hasTeamPermission?: boolean;
  isProjectManager?: boolean;
  customStepButtons?: React.ReactNode;

  onChange: (value: any) => void;
  children: React.ReactNode;

  style?: React.CSSProperties;
  className?: string;
  state: FormState;

  customItemList?: CustomItemList;
  itemList?: string[];
  showList?: boolean;
  isCanResizableBox?: boolean;

  pageDefaultSetting?: any;
  rowDefaultSetting?: any;
}

/**
 * 目前為五層結構
 * 1. Page
 * 2. Row
 * 3. Components
 * 4. Table.components 如果物件類型為 table, components 為二維陣列
 * 5. 二維陣列中的內容, Table 的 components 會多包一層 Row
 * 1 > 2*n > 3*n > 4.[ 5*[6*n], 5*[6*n] ]
 *
 * Rule table: 如果物件類型為 屬性 table, 則內包一層 Row, 再往下處理 components
 * Rule intpuTable: 如果物件類型為 屬性 inputTable, 則處理其 components 屬性
 * 依照層級宣告移動方法
 */
const DragContainer: FC<DragContainerProps> = (props) => {
  const {
    children,
    value,
    steps = [],
    stepButtons = [],
    stepTeams = [],
    currentStepId,
    currentTeamIds,
    hasTeamPermission,
    isProjectManager,
    customStepButtons,

    onChange,
    style,
    className,
    state = FormState.PREVIEW,

    customItemList,
    itemList = AllItemKeys,
    showList = false,
    isCanResizableBox = false,

    pageDefaultSetting,
    rowDefaultSetting,
  } = props;

  const [list, setList] = useState<BaseContainerData[]>(value);
  const [targetItem, setTargetItem] = useState<any>({ id: null, item: null });
  const [targetCell, setTargetCell] = useState<any>({
    rowIndex: null,
    colIndex: null,
  });
  const [activeTab, setActiveTab] = useState<string>("Fields");

  // Undo Redo Start
  const handleDataChange = (_data: BaseContainerData[]) => {
    historyStore.goNext(_data);
  };
  const debouncedSaveHistory = useCallback(debounce(handleDataChange, 500), []);

  // Undo
  const handlePreviousStep = useCallback(() => {
    setList(historyStore.goBack());
  }, []);

  // Redo
  const handleNextStep = useCallback(() => {
    setList(historyStore.goForward());
  }, []);
  // Undo Redo End

  useEffect(() => {
    if (!isEqual(list, value)) {
      setList(value);
    }
  }, [value]);

  useEffect(() => {
    if (!isEqual(list, value)) {
      onChange(list);
      debouncedSaveHistory(list);
    }
  }, [list]);

  const onChangreActiveTab = useCallback(
    (id: string) => {
      setActiveTab(id);
    },
    [setActiveTab]
  );

  const validateDataStructureMemo = useMemo(() => {
    let isStructure = validateDataStructure(value);
    if (!isStructure) {
      // try {
      //   const fixData = normalizeData(value, {
      //     pageDefaultSetting,
      //     rowDefaultSetting,
      //   });
      //   setList(fixData);
      // } catch (error) {}
      isStructure = true;
    }
    return isStructure;
  }, [value]);

  /* 共用事件 搜尋物件 */
  /**
   * 遞迴尋找子物件
   * Rule table
   * Rule intpuTable
   * @param id string
   * @param currentPath number[]
   * @returns SearchItem | null
   */
  const recursionFindChildrenPathById = useCallback(
    (
      id: string,
      currentPath?: number[],
      prevList?: BaseContainerData[]
    ): SearchItem | null => {
      const data = prevList || list;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const path = [...(currentPath || []), i];

        // 如果當前物件符合條件，則回傳包含 pathId 和 item 的結果
        if (item.id === id) {
          return {
            pathId: path, // 返回該物件的路徑
            originalIndex: i,
            originalItem: item,
          };
        }

        // 如果物件類型為 table，則進一步處理其 components 屬性
        if (item.type === ItemTypesEnum.TABLE) {
          const tableItem = item as BaseItemData;
          if (Array.isArray(tableItem.components)) {
            for (
              let rowIndex = 0;
              rowIndex < tableItem.components.length;
              rowIndex++
            ) {
              const row = tableItem.components[rowIndex];
              for (let colIndex = 0; colIndex < row.length; colIndex++) {
                const cell = row[colIndex];

                if (cell.type === ItemTypesEnum.ROW) {
                  const findTableRowChildrenItemIndex =
                    cell.components.findIndex((v: BaseItemData) => v.id === id);
                  if (findTableRowChildrenItemIndex !== -1) {
                    return {
                      pathId: [
                        ...path,
                        rowIndex,
                        colIndex,
                        findTableRowChildrenItemIndex,
                      ],
                      originalIndex: findTableRowChildrenItemIndex,
                      originalItem:
                        cell.components[findTableRowChildrenItemIndex],
                    };
                  }
                }

                if (cell.id === id) {
                  return {
                    pathId: [...path, rowIndex, colIndex], // 在 pathId 中加入 table 的位置
                    originalIndex: -1,
                    originalItem: cell,
                  };
                }
              }
            }
          }
        }

        // 如果有 components，則遞迴搜尋
        if (item.components && item.components.length > 0) {
          const result = recursionFindChildrenPathById(
            id,
            path,
            item.components
          );
          if (result) {
            return result;
          }
        }
      }

      // 沒有找到目標 ID 時，返回 null
      return null;
    },
    [list]
  );

  /**
   * 依照路徑取子物件
   * Rule table
   * Rule intpuTable
   * @param currentPath number[]
   * @returns BaseContainerData | BaseItemData | null
   */
  const getChildrenByPathId = useCallback(
    (
      pathId: number[],
      prevList?: BaseContainerData[]
    ): BaseContainerData | BaseItemData | null => {
      let data = prevList || list;
      let current = null;
      for (const index of pathId) {
        if (!data || typeof data !== "object") {
          return null;
        }
        current = data[index];
        if (data[index]?.components) {
          data = data[index].components;
        } else if (data[index]?.type === ItemTypesEnum.TABLE) {
          data = data[index].components;
        } else if (Array.isArray(data)) {
          data = data[index] as any;
        }
      }
      return current;
    },
    [list]
  );

  /**
   * 依照 groupId 找出所有符合條件的物件
   */
  const findComponentsByGroupId = useCallback(
    (
      groupId: string,
      currentPath: number[] = [],
      prevList?: BaseContainerData[]
    ): SearchItem[] => {
      const data = prevList || list;
      let result: SearchItem[] = [];

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const path = [...currentPath, i];

        // 如果當前物件的 setting.groupId 符合條件，則加入結果
        if (item.setting?.groupId === groupId) {
          result.push({
            pathId: path,
            originalIndex: i,
            originalItem: item,
          });
        }

        // 如果是 TABLE，則遍歷其 components
        if (item.type === ItemTypesEnum.TABLE) {
          const tableItem = item as BaseItemData;
          if (Array.isArray(tableItem.components)) {
            for (
              let rowIndex = 0;
              rowIndex < tableItem.components.length;
              rowIndex++
            ) {
              const row = tableItem.components[rowIndex];
              for (let colIndex = 0; colIndex < row.length; colIndex++) {
                const cell = row[colIndex];

                if (cell.setting?.groupId === groupId) {
                  result.push({
                    pathId: [...path, rowIndex, colIndex],
                    originalIndex: -1,
                    originalItem: cell,
                  });
                }

                if (cell.type === ItemTypesEnum.ROW) {
                  const matchedChildren = cell.components
                    .map((v: { setting: { groupId: string } }, index: any) =>
                      v.setting?.groupId === groupId
                        ? {
                            pathId: [...path, rowIndex, colIndex, index],
                            originalIndex: index,
                            originalItem: v,
                          }
                        : null
                    )
                    .filter(Boolean) as SearchItem[];
                  result.push(...matchedChildren);
                }
              }
            }
          }
        }

        // 如果有 components，則遞迴搜尋
        if (item.components && item.components.length > 0) {
          result = result.concat(
            findComponentsByGroupId(groupId, path, item.components)
          );
        }
      }

      return result;
    },
    [list]
  );

  /* 選取物件 */
  // 設定目前點擊的 物件/子物件
  const targetChildrenId = useCallback(
    (id: string) => {
      const findItem = recursionFindChildrenPathById(id);
      if (
        findItem &&
        !isEqual(targetItem, { id, item: findItem.originalItem })
      ) {
        setTargetItem({
          id: id,
          item: findItem?.originalItem,
        });
      }
    },
    [recursionFindChildrenPathById, targetItem]
  );

  // 清除目前的 物件/子物件
  const clearTargetItem = useCallback(() => {
    if (
      !isEqual(targetItem, {
        id: null,
        item: null,
      })
    )
      setTargetItem({
        id: null,
        item: null,
      });
  }, [targetItem]);

  const targetCellIndex = useCallback(
    (rowIndex: number, colIndex: number) => {
      if (!isEqual(targetCell, { rowIndex, colIndex }))
        setTargetCell({ rowIndex, colIndex });
    },
    [targetCell]
  );
  const clearTargetCellIndex = useCallback(() => {
    if (!isEqual(targetCell, { rowIndex: null, colIndex: null }))
      setTargetCell({ rowIndex: null, colIndex: null });
  }, [targetCell]);

  /* 第一層 First Layer (Container)物件移動事件 */
  // 尋找 First Layer 物件
  const findIndexById = (id: string, data: any[]) => {
    return data.findIndex((c) => `${c.id}` === `${id}`);
  };

  // 尋找 First Layer 物件
  const findFirstLayer = useCallback(
    (id: string) => {
      const itemIndex = findIndexById(id, list);
      if (itemIndex !== -1) {
        return {
          item: list[itemIndex],
          index: itemIndex,
        };
      } else {
        return {
          item: undefined,
          index: -1,
        };
      }
    },
    [list]
  );

  // 添加 First Layer 物件
  const addFirstLayer = useCallback(
    (item: BaseContainerData) => {
      setList((prevList) =>
        update(prevList, {
          $push: [item],
        })
      );
    },
    [setList]
  );

  // 移動 First Layer 物件
  const moveFirstLayer = useCallback(
    (id: string, atIndex: number) => {
      const { item, index } = findFirstLayer(id);
      if (item) {
        setList(
          update(list, {
            $splice: [
              [index, 1],
              [atIndex, 0, item],
            ],
          })
        );
      }
    },
    [findFirstLayer, list, setList]
  );

  // 移除 First Layer 物件
  const removeFirstLayerById = useCallback(
    (id: string) => {
      setList((prevList) => prevList.filter((v) => `${v.id}` !== `${id}`));
    },
    [setList]
  );

  // 更新 First Layer Id
  const updateFirstLayerId = useCallback(
    (id: string, newId: string) => {
      setList((prevList) => {
        const index = findIndexById(id, prevList);
        if (index !== -1) {
          const newList = structuredClone(prevList);
          newList[index].id = newId;
          return newList;
        } else {
          return prevList;
        }
      });
    },
    [setList]
  );

  // 更新 First Layer Attr
  const updateFirstLayerAttr = useCallback(
    (attr: any) => {
      setList((prevList) => {
        const newList = structuredClone(prevList);
        for (const item of newList) {
          if (item.type === ItemTypesEnum.PAGE) {
            Object.assign(item, {
              childSetting: { ...item.childSetting, ...attr },
            });
          }
        }
        return newList;
      });
    },
    [setList]
  );

  const updateTempFirstLayerId = useCallback(() => {
    setList((prevList) => {
      const findItem = findFirstLayer(tempPageId);
      if (findItem.item) {
        const newPrevList = structuredClone(prevList);
        if (findItem.item.components.length > 0) {
          newPrevList[findItem.index].id = uuidv4();
        } else {
          newPrevList.splice(findItem.index, 1);
        }
        return newPrevList;
      } else {
        return prevList;
      }
    });
  }, [findFirstLayer]);

  /* 第二層 Second Layer (Container)物件移動事件 */
  // 尋找 Second Layer 物件
  const findSecondLayer = useCallback(
    (id: string, prevList?: Array<BaseContainerData>) => {
      const result: FindSecondContainer = {
        parentId: "",
        parentIndex: -1,
        index: -1,
        item: undefined,
      };
      (prevList || list).forEach((v, i) => {
        const index = v.components.findIndex((vv) => vv.id === id);
        if (index !== -1) {
          result.parentId = v.id;
          result.parentIndex = i;
          result.index = index;
          result.item = v.components[index];
        }
      });
      return result;
    },
    [list]
  );

  // 添加 Second Layer 物件，新增時需要複製父層的 childSetting
  const addSecondLayer = useCallback(
    (index: number, item: any, innerIndex?: number) => {
      setList((prevList) => {
        const newItem = {
          ...item,
          childSetting: {
            ...item.childSetting,
            ...prevList[index].childSetting,
          },
        };
        const newPrevList = update(prevList, {
          [index]: {
            components:
              innerIndex !== undefined
                ? {
                    $splice: [[innerIndex, 0, newItem]],
                  }
                : {
                    $push: [newItem],
                  },
          },
        });

        // 檢查因為操作導致ID重複
        if (checkNotDuplicateIds(newPrevList)) {
          return newPrevList;
        } else {
          return prevList;
        }
      });
    },
    [setList]
  );

  // 移動 Second Layer 物件
  const moveSecondLayer = useCallback(
    (id: string, atParentIndex: number) => {
      const { parentIndex, index, item } = findSecondLayer(id, list);
      if (item && parentIndex !== atParentIndex) {
        setList((prevList) => {
          let newPrevList = structuredClone(prevList);
          // 確定要移動的物件在原本的 父層 存在
          const sourceRowComponentsIndex = prevList[
            parentIndex
          ].components.findIndex((v) => v.id === id);

          // 確定要移動的物件在其他的 父層 中不存在
          const checkItem = prevList[atParentIndex].components.findIndex(
            (item) => item.id === id
          );

          if (
            sourceRowComponentsIndex !== -1 &&
            checkItem === -1 &&
            parentIndex !== atParentIndex
          ) {
            newPrevList = update(prevList, {
              [parentIndex]: {
                components: {
                  $splice: [[index, 1]], // 移除指定 index 的 item
                },
              },
              [atParentIndex]: {
                components: {
                  $push: [item], // 添加 item 到 target 父層
                },
              },
            });
          }

          // 如果在其他的 父層 中存在(表示可能重複)，則移除重複並還原到原本的 父層
          if (checkItem !== -1) {
            newPrevList = update(prevList, {
              [atParentIndex]: {
                components: {
                  $splice: [[checkItem, 1]],
                },
              },
              [parentIndex]: {
                components: {
                  $splice: [[index, 0, item]],
                },
              },
            });
          }

          // 檢查因為操作導致ID重複
          if (checkNotDuplicateIds(newPrevList)) {
            return newPrevList;
          } else {
            return prevList;
          }
        });
      }
    },
    [findSecondLayer, list, setList]
  );

  // 移動子物件 同 Row
  const moveSecondLayerOnSameLayer = useCallback(
    (id: string, atIndex: number, atParentIndexIndex: number) => {
      const { parentIndex, index, item } = findSecondLayer(id);
      // atParentIndexIndex === parentIndex && 在 TabCurrentFields 會有問題
      if (atParentIndexIndex !== -1) {
        setList((prevList) => {
          const newPrevList = update(prevList, {
            [parentIndex]: {
              components: {
                $splice: [
                  [index, 1],
                  [atIndex, 0, item],
                ],
              },
            },
          } as any);

          // 檢查因為操作導致ID重複
          if (checkNotDuplicateIds(newPrevList)) {
            return newPrevList;
          } else {
            return prevList;
          }
        });
      }
    },
    [findSecondLayer, setList]
  );

  // 移除 Second Layer 物件
  // Table 底下共用 Row 物件, 刪除時增加判斷
  const removeSecondLayerById = useCallback(
    (id: string) => {
      setList((prevList) => {
        clearTargetItem();
        const { parentIndex, index, item } = findSecondLayer(id, prevList);
        if (item) {
          return update(prevList, {
            [parentIndex]: {
              components: {
                $splice: [[index, 1]],
              },
            },
          });
        } else {
          const newList = structuredClone(prevList);
          const findItem = recursionFindChildrenPathById(id, [], newList);
          if (findItem?.originalItem && IsPathIdInTable(findItem.pathId)) {
            findItem.originalItem.components = [];
            return newList;
          }
          return prevList;
        }
      });
    },
    [clearTargetItem, findSecondLayer, recursionFindChildrenPathById]
  );

  // 更新 Second Layer Id
  const updateSecondLayerId = useCallback(
    (id: string, newId: string) => {
      setList((prevList) => {
        const findItem = recursionFindChildrenPathById(id, [], prevList);
        if (!findItem) {
          return prevList;
        }
        const newPrevList = structuredClone(prevList);
        const findTargetItem = getChildrenByPathId(
          findItem.pathId,
          newPrevList
        );
        if (findTargetItem) {
          findTargetItem.id = newId;
          return newPrevList;
        } else {
          return prevList;
        }
      });
    },
    [recursionFindChildrenPathById, getChildrenByPathId, setList]
  );

  const updateTempSecondLayerId = useCallback(() => {
    setList((prevList) => {
      const findItem = recursionFindChildrenPathById(tempRowId, [], prevList);
      if (!findItem) {
        return prevList;
      }
      const newPrevList = structuredClone(prevList);
      const findTargetItem = getChildrenByPathId(findItem.pathId, newPrevList);
      if (findTargetItem) {
        if (findTargetItem?.components.length > 0) {
          findTargetItem.id = uuidv4();
        } else {
          newPrevList[findItem.pathId[0]].components.splice(
            findItem.pathId[1],
            1
          );
        }
        return newPrevList;
      } else {
        return prevList;
      }
    });
  }, [recursionFindChildrenPathById, getChildrenByPathId, setList]);

  // 更新 Second Layer Height
  const updateSecondLayerHeight = useCallback(
    (id: string, newHeight: number) => {
      setList((prevList) => {
        const findItem = recursionFindChildrenPathById(id, [], prevList);
        if (!findItem) {
          return prevList;
        }
        const newPrevList = structuredClone(prevList);
        const findTargetItem = getChildrenByPathId(
          findItem.pathId,
          newPrevList
        );
        if (findTargetItem) {
          findTargetItem.height = newHeight;
          return newPrevList;
        } else {
          return prevList;
        }
      });
    },
    [recursionFindChildrenPathById, getChildrenByPathId, setList]
  );

  // 更新全部 Second Layer 屬性資料
  const updateSecondLayersAttr = useCallback(
    (attr: any) => {
      setList((prevList) => {
        const newList = structuredClone(prevList);
        function traverse(itemList: BaseContainerData[]) {
          for (const item of itemList) {
            // 如果類型不是 page 則更新屬性
            if (item.type !== ItemTypesEnum.PAGE) {
              Object.assign(item, {
                childSetting: { ...item.childSetting, ...attr },
              });
            }

            // 如果有子組件，繼續遞迴檢查
            if (item.components && item.components.length > 0) {
              traverse(item.components);
            }
          }
        }
        traverse(newList);
        return newList;
      });
    },
    [setList]
  );

  const removeEmptySecondLayers = useCallback(() => {
    setList((prevList) => {
      const newList = structuredClone(prevList);
      newList.forEach((page: BaseContainerData) => {
        page.components = page.components.filter(
          (v) => v.components.length > 0
        );
      });
      return newList;
    });
  }, []);

  const addTempRowOnEveryPageLayer = useCallback(() => {
    setList((prevList) => {
      const newList = structuredClone(prevList);
      newList.forEach((page: BaseContainerData) => {
        if (
          page.components.length === 0 ||
          page.components.some(
            (v) => v.components.length !== 0 && !v.id.includes(tempRowId)
          )
        ) {
          const defaultPageItem = generateDefaultItem(
            ItemTypesEnum.ROW,
            rowDefaultSetting
          );
          defaultPageItem.id = `${tempRowId}_${uuidv4()}`;
          page.components.push(defaultPageItem);
        }
      });
      return newList;
    });
  }, [rowDefaultSetting]);

  /* 第三層 物件移動事件 */
  // 添加 Child 物件
  /**
   * 根據給定的 ID，將 item 加入到對應物件的 components 中，新增時需要複製父層的 childSetting
   * @param item - 要插入的物件
   * @param pathId - 要插入的路徑 ID
   * @param atIndex - (可選) 插入的位置索引，若未提供則為 push 到尾端
   * @returns 是否成功插入
   */
  const addChildren = useCallback(
    (item: BaseItemData, pathId: number[], atIndex?: number): void => {
      setList((prevList) => {
        const newPrevList = structuredClone(prevList);
        const targetParent = getChildrenByPathId(pathId, newPrevList);
        if (!targetParent) {
          // || targetParent?.type === ItemTypes.PAGE
          // console.error(`Item with id ${targetId} not found.`);
          return prevList;
        }

        const newItem = {
          ...structuredClone(item),
          ...(targetParent as BaseContainerData)?.childSetting,
        };

        if (
          [ItemTypesEnum.TABLE, ItemTypesEnum.INPUTTABLE].includes(newItem.type)
        ) {
          newItem.components.forEach((row: []) => {
            row.forEach((col: any) => {
              col.id = uuidv4();
            });
          });
        }

        if (typeof atIndex === "number") {
          targetParent.components.splice(atIndex, 0, newItem);
        } else {
          targetParent.components.push(newItem);
        }

        // 檢查因為操作導致ID重複
        if (checkNotDuplicateIds(newPrevList)) {
          return newPrevList;
        } else {
          return prevList;
        }
      });
    },
    [getChildrenByPathId, setList]
  );

  /**
   * 根據給定的 ID，將 Child 物件 移動到對應物件的 Second Layer 中。
   * @param id - 要移動的目標 ID
   * @param targetPathId - 要插入的路徑
   * @param atIndex - (可選) 插入的位置索引，若未提供則為 push 到尾端
   * @returns 是否成功插入
   */
  const moveChildren = useCallback(
    (id: string, targetPathId: number[], atIndex?: number): boolean => {
      setList((prevList) => {
        const newList = structuredClone(prevList);
        // 1. 找到要移動的物件和其所在的路徑
        const { originalItem, pathId } = recursionFindChildrenPathById(
          id,
          [],
          newList
        ) || {
          originalItem: null,
          pathId: [],
        };

        // 2. 使用深拷貝的方式更新 list 狀態
        if (!originalItem || !pathId) {
          // console.error(`Item with id ${id} not found.`);
          return prevList;
        }

        // 3. 移除原始位置的物件
        const parent = getChildrenByPathId(pathId.slice(0, -1), newList);
        if (!parent || !parent.components) {
          // console.error(`Failed to locate parent for item ${id}.`);
          return prevList;
        }
        const indexToRemove = pathId[pathId.length - 1];
        parent.components.splice(indexToRemove, 1);

        // 4. 將物件插入到目標位置
        const targetParent = getChildrenByPathId(targetPathId, newList);
        if (!targetParent || !targetParent.components) {
          // console.error(
          //   `Failed to locate target parent for path ${targetPathId}.`
          // );
          return prevList;
        }

        if (typeof atIndex === "number") {
          targetParent.components.splice(atIndex, 0, originalItem);
        } else {
          targetParent.components.push(originalItem);
        }

        // 5. 返回更新後的 list
        // 檢查因為操作導致ID重複
        if (checkNotDuplicateIds(newList)) {
          if (isEqual(prevList, newList)) return prevList;
          return newList;
        } else {
          return prevList;
        }
      });

      return true;
    },
    [recursionFindChildrenPathById, getChildrenByPathId, setList]
  );

  // 移除 Child 物件
  const removeChildren = useCallback(
    (id: string) => {
      setList((prevList) => {
        const findTargetItem = recursionFindChildrenPathById(id, [], prevList);
        if (!findTargetItem) {
          return prevList;
        }
        const newPrevList = structuredClone(prevList);

        if (IsPathIdInTable(findTargetItem.pathId)) {
          const targetParent = getChildrenByPathId(
            findTargetItem.pathId.slice(0, -1),
            newPrevList
          );
          if (Array.isArray(targetParent?.components)) {
            targetParent.components.splice(findTargetItem.pathId[5], 1);
          }
        } else {
          const targetParent = getChildrenByPathId(
            findTargetItem.pathId.slice(0, -1),
            newPrevList
          );
          targetParent?.components?.splice(findTargetItem.originalIndex, 1);
        }

        return newPrevList;
      });
    },
    [getChildrenByPathId, recursionFindChildrenPathById]
  );

  // 更新 Child 物件 Id
  const updateChildrenId = useCallback(
    (id: string, newId: string) => {
      setList((prevList) => {
        const newPrevList = structuredClone(prevList);
        const findTargetItem = recursionFindChildrenPathById(
          id,
          [],
          newPrevList
        );
        if (!findTargetItem) {
          console.error(`Item with id ${id} not found.`);
          return prevList;
        }
        findTargetItem.originalItem.id = newId;
        return newPrevList;
      });
    },
    [recursionFindChildrenPathById]
  );

  // 更新子物件 value
  const updateChildrenValue = useCallback(
    (id: string, value: any) => {
      let callbackData;
      setList((prevList) => {
        const findTargetItem = recursionFindChildrenPathById(id, [], prevList);
        if (findTargetItem) {
          const newList = structuredClone(prevList);
          const findItem = getChildrenByPathId(
            findTargetItem.pathId,
            newList
          ) as BaseItemData;
          if (findItem) {
            findItem.value = value;
            callbackData = newList;
            return newList;
          } else {
            callbackData = prevList;
            return prevList;
          }
        }
        callbackData = prevList;
        return prevList;
      });
      return structuredClone(callbackData);
    },
    [recursionFindChildrenPathById, getChildrenByPathId, setList]
  );

  // 更新子物件 width
  const updateChildrenWidth = useCallback(
    (id: string, newWidth: any) => {
      setList((prevList) => {
        const findTargetItem = recursionFindChildrenPathById(id, [], prevList);
        if (findTargetItem) {
          const newList = structuredClone(prevList);
          const findItem = getChildrenByPathId(
            findTargetItem.pathId,
            newList
          ) as BaseItemData;
          if (findItem) {
            findItem.width = newWidth;
            return newList;
          } else {
            return prevList;
          }
        }
        return prevList;
      });
    },
    [recursionFindChildrenPathById, getChildrenByPathId, setList]
  );

  // 更新子物件屬性資料
  const updateChildrenAttr = useCallback(
    (id: string, attr: any) => {
      let callbackData;
      setList((prevList) => {
        const findTargetItem = recursionFindChildrenPathById(id, [], prevList);
        if (findTargetItem) {
          const newList = structuredClone(prevList);
          const findItem = getChildrenByPathId(
            findTargetItem.pathId,
            newList
          ) as BaseItemData;
          if (findItem) {
            Object.assign(findItem, {
              ...attr,
              id: findItem.id,
            });
            callbackData = newList;
            return newList;
          } else {
            callbackData = prevList;
            return prevList;
          }
        }
        callbackData = prevList;
        return prevList;
      });
      return structuredClone(callbackData);
    },
    [recursionFindChildrenPathById, getChildrenByPathId, setList]
  );

  // 更新全部子物件屬性資料
  const updateChildrensAttr = useCallback(
    (attr: any) => {
      let callbackData;
      setList((prevList) => {
        const newList = structuredClone(prevList);
        function traverse(itemList: BaseContainerData[]) {
          for (const item of itemList) {
            // 如果類型不是 page 和 row，更新屬性
            if (
              item.type !== ItemTypesEnum.PAGE &&
              item.type !== ItemTypesEnum.ROW
            ) {
              Object.assign(item, { ...attr, id: item.id });
            }

            // 如果有子組件，繼續遞迴檢查
            if (item.components && item.components.length > 0) {
              traverse(item.components);
            }
          }
        }
        traverse(newList);
        callbackData = newList;
        return newList;
      });
      return structuredClone(callbackData);
    },
    [setList]
  );

  /**
   * Table.value 如果物件類型為 table, 則進一步處理其 components 屬性
   */
  const moveTableRow = useCallback(
    (id: string, dragIndex: number, atIndex: number) => {
      setList((prevList) => {
        const findItem = recursionFindChildrenPathById(id, [], prevList);
        if (!findItem) return prevList;
        const { pathId } = findItem;

        const newList = structuredClone(prevList);
        const newTable =
          newList[pathId[0]].components[pathId[1]].components[pathId[2]]
            .components;
        const [draggedRow] = newTable.splice(dragIndex, 1);
        newTable.splice(atIndex, 0, draggedRow);

        return newList;
      });
    },
    [recursionFindChildrenPathById]
  );

  /**
   * Table.value 如果物件類型為 table, 則進一步處理其 components 屬性
   */
  const moveTableCol = useCallback(
    (id: string, dragIndex: number, atIndex: number) => {
      setList((prevList) => {
        const findItem = recursionFindChildrenPathById(id, [], prevList);
        if (!findItem) return prevList;
        const { pathId } = findItem;
        const newList = structuredClone(prevList);
        const newTable =
          newList[pathId[0]].components[pathId[1]].components[pathId[2]]
            .components;

        newTable.forEach((row: any[]) => {
          const [draggedRow] = row.splice(dragIndex, 1);
          row.splice(atIndex, 0, draggedRow);
        });

        return newList;
      });
    },
    [recursionFindChildrenPathById]
  );

  const moveTableChildren = useCallback(
    (id: string, itemPathId: number[], targetPathId: number[]) => {
      setList((prevList) => {
        const newList = structuredClone(prevList);
        const findTableItem = getChildrenByPathId(
          itemPathId.slice(0, 3),
          newList
        );

        if (IsPathIdInTable(itemPathId)) {
          if (findTableItem) {
            const [item] = findTableItem.components[itemPathId[3]][
              itemPathId[4]
            ].components.splice(itemPathId?.[5] || 0, 1);

            if (!item || item.id !== id) {
              return prevList;
            }

            if (targetPathId.length > 4) {
              findTableItem.components[targetPathId[3]][
                targetPathId[4]
              ].components.splice(targetPathId?.[5] || 0, 0, item);
            } else {
              findTableItem.components[targetPathId[3]][
                targetPathId[4]
              ].components.push(item);
            }
            return newList;
          }
        } else {
          //
        }

        return prevList;
      });
    },
    [getChildrenByPathId]
  );

  const addLongTextToTable = useCallback(
    (itemPathId: number[], item: BaseItemData | any) => {
      setList((prevList) => {
        const newList = structuredClone(prevList);
        const findTableItem = getChildrenByPathId(
          itemPathId.slice(0, 3),
          newList
        );

        if (
          IsPathIdInTable(itemPathId) &&
          findTableItem?.type === ItemTypesEnum.TABLE
        ) {
          findTableItem.components[itemPathId[3]][itemPathId[4]].components = [
            Object.assign(item, { id: uuidv4() }),
          ];
          return newList;
        } else {
          //
        }
        return prevList;
      });
    },
    [getChildrenByPathId]
  );

  /**
   * Rule table
   * Rule intpuTable
   */
  const copyItem = useCallback(
    (id: string) => {
      const findItem = recursionFindChildrenPathById(id);
      if (findItem?.originalItem?.type) {
        switch (findItem.originalItem.type) {
          case ItemTypesEnum.PAGE: {
            const newItem = structuredClone(findItem.originalItem);
            addFirstLayer(deepCopyWithNewId(newItem));
            break;
          }
          case ItemTypesEnum.ROW: {
            const newItem = structuredClone(findItem.originalItem);
            if (IsPathIdInTable(findItem.pathId)) {
              updateChildrenAttr(id, {
                components: [
                  ...newItem.components,
                  ...deepCopyWithNewId(newItem.components),
                ],
              });
            } else {
              addSecondLayer(
                findItem.pathId[0],
                deepCopyWithNewId(newItem),
                findItem.pathId?.[1] ? findItem.pathId?.[1] + 1 : undefined
              );
            }

            break;
          }
          default:
            // TODO copy children
            break;
        }
      }
    },
    [
      addFirstLayer,
      addSecondLayer,
      recursionFindChildrenPathById,
      updateChildrenAttr,
    ]
  );

  /**
   * 填寫資料用的複製
   */
  const copyTable = useCallback(
    (id: string, groupId: string) => {
      const findItem = recursionFindChildrenPathById(id);
      if (findItem?.originalItem?.type === ItemTypesEnum.TABLE) {
        const newItem = deepCopyWithNewId(
          structuredClone(findItem.originalItem)
        );
        // 判斷是否為原始資料
        newItem.setting.groupId = groupId;
        newItem.setting.isDefault = false;
        const defaultPageItem = generateDefaultItem(
          ItemTypesEnum.ROW,
          rowDefaultSetting
        );
        defaultPageItem.components = [newItem];

        // const tables = findComponentsByGroupId(groupId);

        addSecondLayer(
          findItem.pathId[0],
          defaultPageItem,
          findItem.pathId?.[1]
          // tables[tables.length - 1].pathId?.[1]
          //   ? tables[tables.length - 1].pathId?.[1] + 1
          //   : undefined
        );
      }
    },
    [addSecondLayer, recursionFindChildrenPathById]
  );

  const memoizedValue = useMemo(() => {
    return {
      // 基礎資料
      list,
      setList,
      steps,
      stepButtons,
      stepTeams,
      currentStepId,
      currentTeamIds,
      hasTeamPermission,
      isProjectManager,
      targetItem,
      targetCell,
      itemList,
      customStepButtons,

      activeTab,
      onChangreActiveTab,

      // 帶入參數
      customItemList,
      showList,
      state,
      isCanResizableBox,
      pageDefaultSetting,
      rowDefaultSetting,

      // 選取物件
      targetChildrenId,
      clearTargetItem,
      targetCellIndex,
      clearTargetCellIndex,

      // 共用事件 搜尋物件
      recursionFindChildrenPathById,
      getChildrenByPathId,
      copyItem,
      copyTable,

      // 第一層 First Layer (Container)物件移動事件
      findFirstLayer,
      addFirstLayer,
      moveFirstLayer,
      removeFirstLayerById,
      updateFirstLayerId,
      updateFirstLayerAttr,
      updateTempFirstLayerId,

      // 第二層 Second Layer (Container)物件移動事件
      findSecondLayer,
      addSecondLayer,
      moveSecondLayer,
      moveSecondLayerOnSameLayer,
      removeSecondLayerById,
      updateSecondLayerId,
      updateTempSecondLayerId,
      updateSecondLayerHeight,
      updateSecondLayersAttr,
      removeEmptySecondLayers,
      addTempRowOnEveryPageLayer,

      // 第三層 物件移動事件
      addChildren,
      moveChildren,
      updateChildrenId,
      removeChildren,
      updateChildrenWidth,
      updateChildrenValue,
      updateChildrenAttr,
      updateChildrensAttr,

      // Table Row Col 移動事件
      moveTableRow,
      moveTableCol,
      moveTableChildren,
      addLongTextToTable,

      // Undo Redo
      handlePreviousStep,
      handleNextStep,
    };
  }, [
    list,
    steps,
    stepButtons,
    stepTeams,
    currentStepId,
    currentTeamIds,
    hasTeamPermission,
    isProjectManager,
    targetItem,
    targetCell,
    itemList,
    activeTab,
    onChangreActiveTab,
    customItemList,
    showList,
    state,
    isCanResizableBox,
    pageDefaultSetting,
    rowDefaultSetting,
    targetChildrenId,
    clearTargetItem,
    targetCellIndex,
    clearTargetCellIndex,
    recursionFindChildrenPathById,
    getChildrenByPathId,
    copyItem,
    copyTable,
    findFirstLayer,
    addFirstLayer,
    moveFirstLayer,
    removeFirstLayerById,
    updateFirstLayerId,
    updateFirstLayerAttr,
    updateTempFirstLayerId,
    findSecondLayer,
    addSecondLayer,
    moveSecondLayer,
    moveSecondLayerOnSameLayer,
    removeSecondLayerById,
    updateSecondLayerId,
    updateTempSecondLayerId,
    updateSecondLayerHeight,
    updateSecondLayersAttr,
    removeEmptySecondLayers,
    addTempRowOnEveryPageLayer,
    addChildren,
    moveChildren,
    updateChildrenId,
    removeChildren,
    updateChildrenWidth,
    updateChildrenValue,
    updateChildrenAttr,
    updateChildrensAttr,
    moveTableRow,
    moveTableCol,
    moveTableChildren,
    addLongTextToTable,
    handlePreviousStep,
    handleNextStep,
    customStepButtons,
  ]);

  const childrenMemo = useMemo(() => children, [children]);

  return (
    <ListContext.Provider value={memoizedValue}>
      {validateDataStructureMemo ? (
        <div style={style} className={className}>
          <DndProvider backend={HTML5Backend}>{childrenMemo}</DndProvider>
        </div>
      ) : (
        <div>Data structure error.</div>
      )}
    </ListContext.Provider>
  );
};

export default DragContainer;
