import type { CSSProperties } from "react";
import React, { useState, useMemo, useRef, memo } from "react";
import type { XYCoord } from "react-dnd";
import { useDrag, useDrop } from "react-dnd";
import { useList } from "../../store/listContext";
import { FormState } from "../../type/AttrTypes";
import { icons } from "../../../assets";
const { DragIcon } = icons;

interface DraggableRowProps {
  index: number;
  item: any;
  children: React.ReactNode;
  itemType?: string;
  valueKey?: string;
  alwaysShowDrag?: boolean;
  iconSettingMenu?: React.ReactNode | React.JSX.Element;
  iconPosition?: "right" | "rightFloat" | "left";
  direction?: "vertical" | "horizontal";
  useTr?: boolean;
  useTdByDragIcon?: boolean;
}

const dragIconStyle: CSSProperties = {
  position: "absolute",
  cursor: "grab",
  // backgroundColor: "#FEFEFE",
  // border: "1px solid #F0F0F2",
  // boxShadow: "0px 0px 8px 0px #0029A514",
  // borderRadius: "6px",
};

const iconRightStyle: CSSProperties = {
  top: "50%",
  right: 0,
  transform: "translateY(-50%)",
};
const iconRightFloatStyle: CSSProperties = {
  top: "50%",
  right: "-15px",
  transform: "translateY(-50%)",
};
const iconLeftStyle: CSSProperties = {
  position: "unset",
  left: 0,
};
const iconPositionStyle: { [key: string]: CSSProperties } = {
  right: iconRightStyle,
  rightFloat: iconRightFloatStyle,
  left: iconLeftStyle,
};

const DraggableRow: React.FC<DraggableRowProps> = ({
  index,
  item,
  children,
  itemType = "inputTableRow",
  valueKey = "components",
  alwaysShowDrag = false,
  iconSettingMenu,
  iconPosition = "rightFloat",
  direction = "vertical",
  useTr = true,
  useTdByDragIcon = true,
}) => {
  const { state, moveTableRow } = useList();

  const dropRef = useRef<HTMLTableRowElement>(null);
  const [hovered, setHovered] = useState(false); // 控制鼠標懸停狀態

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const handleonMouseEnter = () => {
    setHovered(!readOnly && true);
  };
  const handleonMouseLeave = () => {
    setHovered(false);
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: itemType,
    item: { index, item, isIndependent: true },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: itemType,
    hover: (item: { index: number; item: any }, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (item.index === index || !isOver) return;

      if (Array.isArray(item.item[valueKey])) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (direction === "vertical") {
          const hoverBoundingRect =
            dropRef.current?.getBoundingClientRect() as DOMRect;
          const clientOffset = monitor.getClientOffset();
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return false;
          }

          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return false;
          }
        } else if (direction === "horizontal") {
          const hoverBoundingRect =
            dropRef.current?.getBoundingClientRect() as DOMRect;
          const clientOffset = monitor.getClientOffset();
          const hoverMiddleX =
            (hoverBoundingRect.left + hoverBoundingRect.right) / 2;
          const hoverClientX = (clientOffset as XYCoord).x;

          if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return false;
          }

          if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return false;
          }
        }

        moveTableRow(item.item.id, dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  preview(drop(dropRef));

  const dragIcon = (
    <div
      ref={drag} // 拖拽行为绑定在这个符号上
      key={`${item?.id || ""}_dragIcon_${index}`}
      style={{
        ...dragIconStyle,
        padding: iconSettingMenu ? 0 : "4px",
        ...(iconPositionStyle[iconPosition] || {}),
        display: hovered || alwaysShowDrag ? "block" : "none",
      }}
    >
      {iconSettingMenu || <DragIcon />}
    </div>
  );

  if (useTr) {
    const newChildren = React.Children.toArray(children);
    if (!useTdByDragIcon) {
      const lastChild = newChildren[newChildren.length - 1];
      if (React.isValidElement(lastChild)) {
        newChildren[newChildren.length - 1] = React.cloneElement(lastChild, {
          ...lastChild.props,
          children: [
            ...React.Children.toArray(lastChild.props.children),
            dragIcon,
          ],
        });
      }
    }
    return (
      <tr
        key={`tr_${index}`}
        ref={dropRef}
        style={{
          opacity: isDragging ? 0.5 : 1,
          position: "relative",
        }}
        onMouseEnter={handleonMouseEnter}
        onMouseLeave={handleonMouseLeave}
      >
        {useTdByDragIcon ? (
          <>
            {children}
            <td style={{ width: 0 }}>{dragIcon}</td>
          </>
        ) : (
          newChildren
        )}
      </tr>
    );
  } else {
    return (
      <div
        key={`tr_${index}`}
        ref={dropRef}
        style={{
          opacity: isDragging ? 0.5 : 1,
          position: "relative",
          display: "flex",
          flexDirection: iconPosition === "left" ? "row-reverse" : "row",
          alignItems: "center",
        }}
        onMouseEnter={handleonMouseEnter}
        onMouseLeave={handleonMouseLeave}
      >
        <div style={{ flex: 1 }}>{children}</div>
        {dragIcon}
      </div>
    );
  }
};

export default memo(DraggableRow);
