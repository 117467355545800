import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgDropdownIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#A96ECE"}
      fillRule="evenodd"
      d="M3.333 2.666a.667.667 0 0 0-.666.667v9.333a.666.666 0 0 0 .666.667h9.334a.666.666 0 0 0 .666-.667V3.333a.667.667 0 0 0-.666-.667zM1.92 1.92a2 2 0 0 1 1.414-.586h9.334a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .586-1.414"
      clipRule="evenodd"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#A96ECE"}
      fillRule="evenodd"
      d="M5.529 6.862c.26-.26.682-.26.942 0L8 8.39l1.529-1.528a.667.667 0 1 1 .942.942l-2 2a.667.667 0 0 1-.942 0l-2-2a.667.667 0 0 1 0-.942"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDropdownIcon;
