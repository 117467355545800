import type { CSSProperties, FC } from "react";
import React, { memo, useMemo } from "react";
import { useFlow } from "../store/flowContext";
import FlowState from "../type/FlowState";
import CustomNodeToolbar from "../components/CustomNodeToolbar";
import CustomHandle from "../components/CustomHandle";
import { renderCustomComponent } from "../../utils/methods";

const text_updater_node_label: CSSProperties = {
  display: "block",
  color: "#777",
  fontSize: "12px",
};

export interface SendEmailNodeProps {
  id: string;
  data: any;
  type: string;
  isConnectable: boolean;
  selected: boolean;
}

const SendEmailNode: FC<SendEmailNodeProps> = (props) => {
  const { id, data, type, isConnectable, selected } = props;

  const { update, flowState, onChangeNodesData, customItemList } = useFlow();

  const readOnly = flowState === FlowState.PREVIEW;

  const handleOnChange = (e: any) => {
    onChangeNodesData(id, e);
  };

  const handleOnChangeById = (_id: string, e: any) => {
    onChangeNodesData(_id, e);
  };

  const newProps = {
    id,
    flowState,
    data,
    connectNodes: [],
    connectToSteps: [],
    onChange: handleOnChange,
    onChangeById: handleOnChangeById,
    selected,
    update,
  };

  const componentMemo = useMemo(() => {
    return customItemList?.[type] ? (
      renderCustomComponent(customItemList, type, "Component", newProps)
    ) : (
      <label htmlFor="text" style={text_updater_node_label}>
        {type}
      </label>
    );
  }, [customItemList]);

  return (
    <div>
      <CustomHandle id={type} type="target" isConnectable={isConnectable} />

      <div>{componentMemo}</div>
      {readOnly ? null : (
        <CustomNodeToolbar id={id} type={type} selected={selected} />
      )}
    </div>
  );
};

export default memo(SendEmailNode);
