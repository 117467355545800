import type { CSSProperties, FC } from "react";
import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";

const handleStyle: CSSProperties = {
  width: 10,
  height: 10,
  borderRadius: 15,
};

export interface CustomHandleProps {
  id: string;
  type: "target" | "source";
  isConnectable: boolean;
  outPosition?: Position;
}

const CustomHandle: FC<CustomHandleProps> = (props) => {
  const { id, type, isConnectable, outPosition } = props;

  if (type === "target") {
    return (
      <Handle
        id={`${id}In`}
        type="target"
        position={Position.Left}
        style={handleStyle}
        isConnectable={isConnectable}
      />
    );
  } else {
    return (
      <Handle
        id={`${id}Out`}
        type="source"
        position={outPosition || Position.Right}
        style={handleStyle}
        isConnectable={isConnectable}
      />
    );
  }
};

export default memo(CustomHandle);
