import { v4 as uuidv4 } from "uuid";

const types = [
  "title",
  "textarea",
  "labelWithSingleLineInput",
  "labelWithMultiLineInput",
  "image",
  "imageWithSingleLineInput",
  "signature",
  "date",
  "table",
  "tableWithChecklist",
  "break",
];

const datasFlowTypeId = [1, 2, 3];

const datasFlowTypeIdName = [
  [
    "Submission Evaluation",
    "Checker Evaluation",
    "Approval Evaluation",
    "Complete Evaluation",
    "Closing Evaluation",
  ],
  ["Submission Evaluation", "Checker Evaluation", "Approval Evaluation"],
  ["Submission Evaluation", "Approval Evaluation"],
  [
    "Submission Evaluation",
    "Verify Evaluation",
    "Checker Evaluation",
    "Approval Evaluation",
    "Complete Evaluation",
    "Closing Evaluation",
  ],
  [
    "Submission Evaluation",
    "Verify Evaluation",
    "Approval Evaluation",
    "Acknowledge Evaluation",
    "Complete Evaluation",
    "Completion Checker Evaluation",
    "Closing Evaluation",
  ],
  [
    "Submission Evaluation",
    "Verify Evaluation",
    "Checker Evaluation",
    "Approval Evaluation",
    "Acknowledge Evaluation",
    "Complete Evaluation",
    "Completion Checker Evaluation",
    "Closing Evaluation",
  ],
];

const datas: any[] = [];

const convertPage = (data: any) => {
  const page = data
    .filter((item: any) => item.components.length !== 0)
    .map((item: any) => {
      const cols = item.components.map((component: any) => {
        switch (component.type) {
          case "title": {
            return {
              id: uuidv4(),
              class: "",
              value: component.value || "",
              visible: false,
              required: false,
              readonly: false,
              type: "longText",
              errors: [],
              setting: {
                localAlignment: "none",
              },
            };
          }
          case "textarea": {
            return {
              id: uuidv4(),
              class: "",
              value: component.value || "",
              visible: false,
              required: false,
              readonly: false,
              type: "longText",
              errors: [],
              setting: {
                localAlignment: "none",
              },
            };
          }
          case "labelWithSingleLineInput": {
            return {
              id: uuidv4(),
              class: "",
              value: component.value || "",
              visible: false,
              required: false,
              readonly: false,
              type: "longText",
              errors: [],
              setting: {
                localAlignment: "top",
                label: component.labelValue || "",
              },
            };
          }
          case "labelWithMultiLineInput": {
            break;
          }
          case "image": {
            return {
              id: uuidv4(),
              class: "",
              value: "",
              visible: false,
              required: false,
              readonly: false,
              type: "image",
              setting: {
                imageAlignment: "Left",
              },
            };
          }
          case "imageWithSingleLineInput": {
            break;
          }
          case "signature": {
            return {
              id: uuidv4(),
              class: "",
              value: {
                label: component.labelValue,
                description: `${component.dataLabel1Value} ${component.dataLabel2Value}`,
              },
              visible: false,
              required: false,
              readonly: false,
              setting: {
                localAlignment: "Top",
                useDefaultAlignment: true,
              },
              type: "signature",
            };
          }
          case "date": {
            break;
          }
          case "table": {
            const tableRow = component.data.map((_row: any) => {
              return _row.row.map((_col: any) => {
                if (_col.edit && !!_col.createdBy)
                  return {
                    id: uuidv4(),
                    type: "row",
                    name: _col.value,
                    components: [],
                  };
                else
                  return {
                    id: uuidv4(),
                    type: "row",
                    components: [
                      {
                        id: uuidv4(),
                        class: "",
                        value: _col.value || "",
                        visible: false,
                        required: false,
                        readonly: false,
                        type: "longText",
                        errors: [],
                        setting: {
                          localAlignment: "none",
                        },
                      },
                    ],
                  };
              });
            });

            return {
              id: uuidv4(),
              class: "",
              value: "",
              visible: false,
              required: false,
              readonly: false,
              components: tableRow,
              type: "table",
            };
          }
          case "tableWithChecklist": {
            const inputTableRow = component.data.map(
              (_row: any, _rowIndex: number) => {
                if (_rowIndex === 0) {
                  const inputTableCol = _row.row.map((_col: any) => {
                    return {
                      type: "InputTableNonEditableText",
                      title: _col.value,
                      id: uuidv4(),
                    };
                  });
                  inputTableCol.push(
                    ...[
                      {
                        type: "InputTableSingleSelection",
                        title: "Yes",
                        id: uuidv4(),
                      },
                      {
                        type: "InputTableSingleSelection",
                        title: "No",
                        id: uuidv4(),
                      },
                      {
                        id: uuidv4(),
                        type: "InputTableSingleSelection",
                        title: "NA",
                      },
                      {
                        id: uuidv4(),
                        type: "InputTableTextInput",
                        title: "Remark",
                      },
                    ]
                  );
                  return inputTableCol;
                } else {
                  const inputTableCol = _row.row.map(
                    (_col: any, _colIndex: number) => {
                      if (_colIndex === 0) {
                        return {
                          type: "InputTableNonEditableText",
                          title: _rowIndex,
                          id: uuidv4(),
                        };
                      } else {
                        return {
                          type: "InputTableNonEditableText",
                          value: _col.value,
                          id: uuidv4(),
                        };
                      }
                    }
                  );
                  inputTableCol.push(
                    ...[
                      {
                        type: "InputTableSingleSelection",
                        value: "",
                        id: uuidv4(),
                      },
                      {
                        type: "InputTableSingleSelection",
                        value: "",
                        id: uuidv4(),
                      },
                      {
                        type: "InputTableSingleSelection",
                        value: "",
                        id: uuidv4(),
                      },
                      {
                        id: uuidv4(),
                        type: "InputTableTextInput",
                        value: "",
                      },
                    ]
                  );
                  return inputTableCol;
                }
              }
            );

            return {
              id: uuidv4(),
              class: "",
              value: "",
              visible: false,
              required: false,
              readonly: false,
              components: inputTableRow,
              type: "inputTable",
              setting: {
                defaultType: "InputTableSingleSelection",
                columnTypeOption: "Multi-type",
              },
            };
          }
          case "break": {
            break;
          }
        }
      });

      return {
        id: uuidv4(),
        class: "",
        type: "row",
        childSetting: {},
        components: cols.filter((col: any) => col),
      };
    });

  page.push({
    id: uuidv4(),
    class: "",
    type: "row",
    childSetting: {},
    components: [
      {
        id: uuidv4(),
        class: "",
        value: "",
        visible: false,
        required: false,
        readonly: false,
        setting: {
          localAlignment: "Top",
          useDefaultAlignment: true,
        },
        type: "signature",
      },
    ],
  });

  return page;
};

export const convertTest = () => {
  const pages = datas.map((page, index) => {
    const rows = convertPage(page);
    return {
      id: uuidv4(),
      name: datasFlowTypeIdName[datas.length - 1][index],
      class: "",
      type: "page",
      childSetting: {},
      components: rows,
    };
  });
  return pages;
};

export const getDefautlHeaderData = () => {
  return [
    {
      childSetting: {},
      components: [
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            items: {
              company: {
                id: "company",
                label: "Company",
                order: 2,
                enabled: false,
              },
              project: {
                id: "project",
                label: "Project",
                order: 3,
                enabled: true,
              },
              validDate: {
                id: "validDate",
                label: "Valid Date",
                order: 7,
                enabled: false,
              },
              submittalDate: {
                id: "submittalDate",
                label: "Submittal Date",
                order: 1,
                enabled: true,
              },
              projectDuration: {
                id: "projectDuration",
                label: "Project Duration",
                order: 4,
                enabled: false,
              },
              projectLocation: {
                id: "projectLocation",
                label: "Project Location",
                order: 5,
                enabled: false,
              },
              referenceNumber: {
                id: "referenceNumber",
                label: "Reference No.",
                order: 0,
                enabled: true,
              },
              projectDescription: {
                id: "projectDescription",
                label: "Project Description",
                order: 6,
                enabled: false,
              },
            },
          },
          components: null,
          id: uuidv4(),
          type: "headerSection",
          class: "",
        },
      ],
      id: uuidv4(),
      type: "row",
      class: "",
    },
    {
      childSetting: {},
      components: [
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            label: "Trade",
            other: false,
            choices: "custom",
            options: [
              "Hoardg",
              "Demoltn",
              "TERS",
              "Earthwks",
              "Survy",
              "Instrumtn",
              "Scffold",
              "Wall_Inst",
              "Wall_Fin",
              "Flr_Lay",
              "Tiles",
              "Ironmngry",
              "P_Wall",
              "Sanitrywre",
              "Anti_Term",
              "Waterprf",
              "Carpt",
              "Vinyl",
              "Timbr_Flr",
              "Raised_Flr",
              "Opr_Wall",
              "Drywall_P",
              "Acoustic_Sys",
              "Paint",
              "Fireprf",
              "Soundprf",
              "False_Ceiling",
              "Alum_Glazing",
              "Stainless_Stl",
              "Metal_Wrk",
              "Door",
              "Toilet_Rltd",
              "Furnit_Fitmnt",
              "Sys_Furnit",
              "Retract_Seats",
              "Perform_Louvre",
              "Rollr_Shuttr",
              "Roof",
              "Alumn_Comp",
              "Trellis",
              "Gate",
              "Fencing",
              "Solar_Panl",
              "Signage",
              "Play court, Sports equipment",
              "Fitness ",
              "Frmwrk & Rbar",
              "Concretr",
              "Precast_Install",
              "Conc",
              "Rbar",
              "Sys_Frmwrk",
              "Precast",
              "Piling",
              "Precast Conc",
              "Pre Post",
              "Struc_Stl",
              "Struc_Repair",
              "Elec",
              "PSG",
              "ACMV",
              "Fire_P",
              "Lift",
              "AV",
              "Landscape",
              "Drainage",
              "External",
              "Contract",
              "Plan",
              "General",
              "test",
              "Site_Off",
              "Shaft_Const",
              "Pipe_Jacking",
              "Pipe_Laying",
              "Pipe_Sleeving",
              "Site_Prep",
              "ELV",
              "MEP_Clear",
              "Ceil_Insp",
              "Archi Wall",
              "Surface_Top",
              "Others",
            ],
            multiple: false,
            otherLabel: "Other",
          },
          components: null,
          id: uuidv4(),
          type: "dropdown",
          class: "",
        },
      ],
      id: uuidv4(),
      type: "row",
      class: "",
    },
    {
      childSetting: {},
      components: [
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            label: "Block",
            other: false,
            choices: "custom",
            options: [
              "936A\t",
              "936B\t",
              "936C\t",
              "936D\t",
              "936E\t",
              "936 (MSCP)\t",
              "Others",
            ],
            multiple: false,
            otherLabel: "Other",
          },
          components: null,
          id: uuidv4(),
          type: "dropdown",
          class: "",
        },
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            label: "Storey",
            other: false,
            choices: "custom",
            options: [
              "1\t",
              "2\t",
              "3\t",
              "4\t",
              "5\t",
              "6\t",
              "7\t",
              "8\t",
              "9\t",
              "10\t",
              "11\t",
              "12\t",
              "13\t",
              "14\t",
              "Others",
            ],
            multiple: false,
            otherLabel: "Other",
          },
          components: null,
          id: uuidv4(),
          type: "dropdown",
          class: "",
        },
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            label: "Unit",
            other: false,
            choices: "custom",
            options: ["2R-1\t", "2R-2\t", "4R\t", "5R\t", "Others"],
            multiple: false,
            otherLabel: "Other",
          },
          components: null,
          id: uuidv4(),
          type: "dropdown",
          class: "",
        },
      ],
      id: uuidv4(),
      type: "row",
      class: "",
    },
    {
      childSetting: {},
      components: [
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            label: "Description",
          },
          components: null,
          id: uuidv4(),
          type: "longText",
          class: "",
          errors: [],
        },
      ],
      id: uuidv4(),
      type: "row",
      class: "",
    },
  ];
};

export const getDefautlEndData = () => {
  return [
    {
      childSetting: {},
      components: [
        {
          visible: false,
          required: false,
          readonly: false,
          value: {
            files: [],
            label: "Upload",
            photos: [],
            description: "Please refer to attached",
          },
          setting: {
            allowedTypes: [
              "pdf",
              "doc",
              "docx",
              "xls",
              "xlsx",
              "csv",
              "txt",
              "rtf",
              "html",
              "zip",
              "mp3",
              "cad",
              "dwg",
              "jpg",
              "jpeg",
              "png",
              "gif",
              "svg",
            ],
            localAlignment: "Top",
            useDefaultAlignment: true,
          },
          components: null,
          id: uuidv4(),
          type: "upload",
          class: "",
        },
      ],
      id: uuidv4(),
      type: "row",
      class: "",
    },
    {
      childSetting: {},
      components: [
        {
          visible: false,
          required: false,
          readonly: false,
          value: "",
          setting: {
            label: "Remarks",
          },
          components: null,
          id: uuidv4(),
          type: "longText",
          class: "",
          errors: [],
        },
      ],
      id: uuidv4(),
      type: "row",
      class: "",
    },
  ];
};
