import type { CSSProperties, FC } from "react";
import React from "react";
import { useList } from "../store/listContext";

export interface TabsProps {
  list: Array<{
    key: string;
    tab: React.ReactNode;
  }>;
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    borderBottom: "1px solid #F0F0F2",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: 36,
  },
  button: {
    padding: "8px",
    fontFamily: "poppins",
    fontSize: "14px",
    color: "#595F6F",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    textWrap: "nowrap",
  },
  buttonActive: {
    color: "#0029A5",
    borderBottom: "2px solid #0029A5",
  },
  buttonHover: {},
  content: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
    paddingLeft: "16px",
    paddingRight: "16px",
    height: 0,
  },
};

const Tabs: FC<TabsProps> = ({ list }) => {
  const { activeTab, onChangreActiveTab } = useList();

  const handleTabClick = (tab: string) => {
    onChangreActiveTab(tab);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {list.map((tab) => (
          <button
            key={tab.key}
            style={{
              ...styles.button,
              ...(activeTab === tab.key ? styles.buttonActive : {}),
            }}
            onClick={() => handleTabClick(tab.key)}
          >
            {tab.key}
          </button>
        ))}
      </div>

      <div style={styles.content}>
        {list
          .filter((v) => v.key === activeTab)
          .map((v) => (
            <div key={v.key}>{v.tab}</div>
          ))}
      </div>
    </div>
  );
};

export default Tabs;
