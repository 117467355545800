import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgCopyIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M7.167 2a2 2 0 0 0-2 2H6.5c0-.368.298-.667.667-.667H12.5c.368 0 .667.299.667.667v5.333A.667.667 0 0 1 12.5 10v1.333a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#262D42"}
      fillRule="evenodd"
      d="M4.5 4.667a2 2 0 0 0-2 2V12a2 2 0 0 0 2 2h5.333a2 2 0 0 0 2-2V6.667a2 2 0 0 0-2-2zm-.667 2c0-.369.299-.667.667-.667h5.333c.368 0 .667.298.667.667V12a.667.667 0 0 1-.667.667H4.5A.667.667 0 0 1 3.833 12z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCopyIcon;
