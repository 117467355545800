import type { FC } from "react";
import React, { memo } from "react";
import { Flipped } from "react-flip-toolkit";
import { useSortProperty } from "../hook/useSortProperty";

export interface DragSortItemProps {
  children: React.ReactNode;
  item: any;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number, item: any) => void;
}

const DragSortItem: FC<DragSortItemProps> = ({
  children,
  item,
  index,
  onMove,
}) => {
  const dropRef = React.useRef<HTMLDivElement>(null);

  const { drag, drop, style } = useSortProperty({
    id: item.id,
    itemType: `${item.type}List`,
    index,
    onMove,
    dropRef,
    canDrag: true,
  });

  drag(drop(dropRef));

  return (
    <Flipped flipId={item.id.toString()}>
      <div ref={dropRef} style={style}>
        {children}
      </div>
    </Flipped>
  );
};

export default memo(DragSortItem);
