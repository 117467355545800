import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgInputTableIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#E89B09"}
      fillRule="evenodd"
      d="M4 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm0 1.333A.667.667 0 0 0 3.333 4v2H6V3.333zm3.333 0V6h5.334V4A.667.667 0 0 0 12 3.333zM6 7.333H3.333V12c0 .368.299.667.667.667h2zm1.333 5.334V7.333h5.334V12a.667.667 0 0 1-.667.667z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInputTableIcon;
