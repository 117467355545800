import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgColumnIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#7A9BFF"}
      fillRule="evenodd"
      d="M3.8 2h1.8c.986 0 1.8.79 1.8 1.784v8.432C7.4 13.21 6.586 14 5.6 14H3.8c-.986 0-1.8-.79-1.8-1.784V3.784C2 2.79 2.814 2 3.8 2m1.8 1.2H3.8c-.34 0-.6.269-.6.584v8.432c0 .315.26.584.6.584h1.8c.34 0 .6-.269.6-.584V3.784a.59.59 0 0 0-.6-.584m4.8 0c-.34 0-.6.269-.6.584v8.432c0 .315.26.584.6.584h1.8c.34 0 .6-.269.6-.584V3.784a.59.59 0 0 0-.6-.584zm-1.8.584C8.6 2.79 9.414 2 10.4 2h1.8c.986 0 1.8.79 1.8 1.784v8.432C14 13.21 13.186 14 12.2 14h-1.8c-.986 0-1.8-.79-1.8-1.784z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgColumnIcon;
