const AttrTypes = {
  // FORMAT: "format",
};

export enum FormState {
  CREATE = 1,
  FILLOUT = 2,
  PREVIEW = 3,
}

export enum PhoneSizeState {
  SMALL = 320,
  MEDIUM = 360,
  LARGE = 480,
}

export const tableChildrenKey = "components";

export default AttrTypes;
