import type { FC, CSSProperties } from "react";
import React, { useMemo, memo } from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import CommonItemHOC, { ComponentAndSettingProps } from "./base/CommonItemHOC";
import { FormState, tableChildrenKey } from "../type/AttrTypes";
import ItemTypesEnum, {
  TableData,
  InputTableEnum,
  OtherSettingTypesEnum,
} from "../type/ItemTypes";
import { useList } from "../store/listContext";
import BaseChild, { textStyle } from "./base/BaseChildren";
import { InputTableIcon } from "../../assets/icons";

import {
  renderCustomComponent,
  renderCustomItemSetting,
  inputTableAddRow,
  inputTableAddColumn,
  inputTableUpdateCell,
  inputTableDeleteRow,
  inputTableDeleteColumn,
} from "../../utils/methods";
import { DraggableRow, DraggableColumn } from "./tableComponents";

import InputTableCellHead from "./tableComponents/InputTableCellHead";
import InputTableSingleSelection from "./tableComponents/InputTableSingleSelection";
import InputTableMultipleSelection from "./tableComponents/InputTableMultipleSelection";
import InputTableTextInput from "./tableComponents/InputTableTextInput";
import InputTableDropdown from "./tableComponents/InputTableDropdown";

const tableStyle: CSSProperties = {
  borderCollapse: "collapse",
  width: "100%",
  backgroundColor: "#F0F0F2",
};

export const BaseItem: FC<ComponentAndSettingProps> = memo((props) => {
  const { data, isCurrentStep } = props;
  const id = data.id;

  const { state, customItemList, updateChildrenAttr } = useList();

  const tableData: TableData = data[tableChildrenKey];

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const addRow = () => {
    const newTable = inputTableAddRow(tableData);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });
  };

  const addColumn = () => {
    const newTable = inputTableAddColumn(tableData);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });
  };

  // 更新單元格內容
  const updateCell = (rowIndex: number, colIndex: number, attr: any) => {
    const newTable = inputTableUpdateCell(tableData, rowIndex, colIndex, attr);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });
  };

  const handleRowDelete = (index: number) => {
    const newTable = inputTableDeleteRow(tableData, index);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });

    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.INPUTTABLE,
      "onRowDelete"
    );
  };
  const handleColumnDelete = (index: number) => {
    const newTable = inputTableDeleteColumn(tableData, index);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });

    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.INPUTTABLE,
      "onColumnDelete"
    );
  };
  const RowFieldAction = (index: number) => {
    if (customItemList?.[OtherSettingTypesEnum.MENUCOMPONENT]?.Component)
      return renderCustomComponent(
        customItemList,
        OtherSettingTypesEnum.MENUCOMPONENT,
        "Component",
        {
          onRowDelete: () => handleRowDelete(index),
          iconName: "DragDefault",
        }
      );
    return null;
  };
  const ColumnFieldAction = (index: number) => {
    if (customItemList?.[OtherSettingTypesEnum.MENUCOMPONENT]?.Component)
      return renderCustomComponent(
        customItemList,
        OtherSettingTypesEnum.MENUCOMPONENT,
        "Component",
        {
          onColumnDelete: () => handleColumnDelete(index),
          iconName: "DragDefault",
        }
      );
    return null;
  };

  const renderHead = (head: any, colIndex: number) => {
    if (colIndex === 0) {
      return (
        <th key={`draggable_${colIndex}`}>
          <div></div>
        </th>
      );
    }

    return (
      <th key={`draggable_${colIndex}`}>
        <DraggableColumn
          index={colIndex}
          item={data}
          itemType={`${ItemTypesEnum.INPUTTABLE}_COLUMN`}
          valueKey={tableChildrenKey}
          iconSettingMenu={ColumnFieldAction(colIndex)}
        >
          <Flipped
            key={`${id}_0_${colIndex}`}
            flipId={`${id}_0_${colIndex}`}
            translate={true}
            scale={false}
          >
            <div style={{ display: "flex" }}>
              <InputTableCellHead
                item={head}
                onChange={(e: any) => updateCell(0, colIndex, e)}
              />
              <div
                onClick={() =>
                  updateCell(0, colIndex, {
                    type: InputTableEnum.MULTIPLESELECTION,
                  })
                }
              >
                <InputTableIcon />
              </div>
            </div>
          </Flipped>
        </DraggableColumn>
      </th>
    );
  };

  const renderRow = (row: any, rowIndex: number) => {
    if (rowIndex === 0) {
      return null;
    }

    return (
      <DraggableRow
        key={`${id}_draggable_${rowIndex}`}
        index={rowIndex}
        item={data}
        itemType={`${ItemTypesEnum.INPUTTABLE}_ROW`}
        valueKey={tableChildrenKey}
        iconSettingMenu={RowFieldAction(rowIndex)}
      >
        {row.map((cell: any, colIndex: number) => (
          <td key={`${rowIndex}_${colIndex}`}>
            <Flipped
              flipId={`${rowIndex}_${colIndex}`}
              translate={true}
              scale={false}
            >
              {renderItem(rowIndex, colIndex, cell)}
            </Flipped>
          </td>
        ))}
      </DraggableRow>
    );
  };

  // 渲染物件
  const renderItem = (rowIndex: number, colIndex: number, cell: any) => {
    if (rowIndex === 0 && colIndex === 0) {
      return <div></div>;
    }

    if (colIndex === 0) {
      return (
        <InputTableCellHead
          item={cell}
          defaultValue="Row"
          onChange={(e: any) => updateCell(rowIndex, colIndex, e)}
        />
      );
    }

    const itemType = tableData[0][colIndex]?.type;
    const newProps = {
      item: cell,
      onChange: (e: any) => updateCell(rowIndex, colIndex, e),
      isCurrentStep,
      readOnly,
    };

    switch (itemType) {
      case InputTableEnum.DROPDOWN: {
        return <InputTableDropdown {...newProps} />;
      }
      case InputTableEnum.MULTIPLESELECTION: {
        return (
          <InputTableMultipleSelection
            {...newProps}
            onChange={(e: any) => updateCell(rowIndex, colIndex, e)}
          />
        );
      }
      case InputTableEnum.SINGLESELECTION: {
        return (
          <InputTableSingleSelection
            {...newProps}
            onChange={(e: any) => updateCell(0, colIndex, e)}
            rowIndex={rowIndex}
            colIndex={colIndex}
          />
        );
      }
      case InputTableEnum.TEXTINPUT: {
        return <InputTableTextInput {...newProps} />;
      }
      default:
        return <div>-</div>;
    }
  };

  return (
    <div>
      <div style={{ textAlign: "end" }}>
        <button onClick={addColumn}>Add column</button>
      </div>
      <Flipper flipKey={tableData}>
        <table border={1} style={tableStyle}>
          <thead>
            <tr key={`${id}_tr_0`}>{tableData[0].map(renderHead)}</tr>
          </thead>
          <tbody>{tableData.map(renderRow)}</tbody>
        </table>
      </Flipper>
      <div>
        <button onClick={addRow}>Add row</button>
      </div>
    </div>
  );
});

export const Item: FC<any> = memo(function Item(props) {
  return (
    <CommonItemHOC
      {...props}
      itemType={ItemTypesEnum.INPUTTABLE}
      defaultRender={BaseItem}
    />
  );
});

export const Add: FC = memo(function Add() {
  const data = {
    [tableChildrenKey]: [
      [
        { type: InputTableEnum.SINGLESELECTION },
        { type: InputTableEnum.SINGLESELECTION, title: "Col" },
        { type: InputTableEnum.SINGLESELECTION, title: "Col" },
        { type: InputTableEnum.SINGLESELECTION, title: "Col" },
      ],
      [
        { type: InputTableEnum.SINGLESELECTION, title: "Row" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
      ],
      [
        { type: InputTableEnum.SINGLESELECTION, title: "Row" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
      ],
      [
        { type: InputTableEnum.SINGLESELECTION, title: "Row" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
        { type: InputTableEnum.SINGLESELECTION, value: "" },
      ],
    ],
    setting: {
      isDefault: true,
      isCanDuplication: false,
    },
  };
  return (
    <BaseChild.Add itemType={ItemTypesEnum.INPUTTABLE} data={data}>
      <div
        style={{
          ...BaseChild.iconStyle,
          backgroundColor: "#FDF5E6",
        }}
      >
        <InputTableIcon />
      </div>
      <span>Input Table</span>
    </BaseChild.Add>
  );
});

export const Preview: FC = memo(function Preview() {
  return (
    <BaseChild.Preview>
      <div
        style={{
          ...BaseChild.iconStyle,
          backgroundColor: "#FDF5E6",
        }}
      >
        <InputTableIcon />
      </div>
      <span>Input Table</span>
    </BaseChild.Preview>
  );
});

export const ShortItem: FC<{
  index: number;
  fieldName: string;
  onClick: () => void;
}> = memo(function ShortItem({ index, fieldName, onClick }) {
  return (
    <BaseChild.ShortItem onClick={onClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          fontSize: 14,
        }}
      >
        <div
          style={{
            ...BaseChild.iconStyle,
            backgroundColor: "#FDF5E6",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px",
            width: 48,
          }}
        >
          <InputTableIcon />
          <span>{index && `${index}.`}</span>
        </div>
        <span style={textStyle}>{fieldName ?? "Input Table"}</span>
      </div>
    </BaseChild.ShortItem>
  );
});

export const Setting = (props: any) => {
  const { data } = props;

  return (
    <div>
      <div style={{ backgroundColor: "#ffffff" }}>{data?.id}</div>
    </div>
  );
};

export default {
  Item,
  Add,
  Preview,
  ShortItem,
  Setting: memo(Setting),
  BaseItem,
};
