import { isEqual } from "lodash";

type HistoryState<T> = {
  past: T[];
  current: T | null;
  future: T[];
};

class HistoryStore<T> {
  private history: HistoryState<T> = { past: [], current: null, future: [] };
  private listeners: Set<() => void> = new Set();

  // 訂閱變更
  subscribe(listener: () => void): () => void {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  // 通知所有訂閱者
  private notify(): void {
    this.listeners.forEach((listener) => listener());
  }

  // 設定新狀態
  goNext(newData: T): void {
    if (isEqual(newData, this.history.current)) {
      return;
    }
    if (this.history.current !== null) {
      this.history.past.push(this.history.current);
    }
    this.history.current = newData;
    this.history.future = [];
    this.notify();
  }

  // 回到上一步
  goBack(): any {
    if (this.history.past.length > 0) {
      if (this.history.current !== null) {
        this.history.future.unshift(this.history.current);
      }
      this.history.current = this.history.past.pop() ?? null;
      this.notify();
    }
    return this.history.current;
  }

  // 前進到下一步
  goForward(): any {
    if (this.history.future.length > 0) {
      if (this.history.current !== null) {
        this.history.past.push(this.history.current);
      }
      this.history.current = this.history.future.shift() ?? null;
      this.notify();
    }
    return this.history.current;
  }

  // 獲取當前的歷史狀態
  getHistory(): HistoryState<T> {
    return this.history;
  }
}

// 泛型 HistoryStore，可以存任何類型的歷史記錄
export const historyStore = new HistoryStore<any>();
