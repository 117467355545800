import type { CSSProperties, FC } from "react";
import React, { useRef, useState, memo, useMemo, useCallback } from "react";
import { isEqual } from "lodash";
import { Flipped } from "react-flip-toolkit";
import ItemTypesEnum, { OtherSettingTypesEnum } from "../../type/ItemTypes";
import { BaseContainerData, generateDefaultItem } from "../../type/Interface";
import { FormState } from "../../type/AttrTypes";
import { useList } from "../../store/listContext";
import { useMoveFormProperty } from "../../hook/useMoveFormProperty";
import Row from "./Row";
import BaseChild, { textStyle } from "./BaseChildren";
import { DeleteConfirm } from "../../components";
import { renderCustomComponent } from "../../../utils/methods";

import { icons } from "../../../assets";
import "react-resizable/css/styles.css";

const { DragIcon, PageIcon, MoreIcon, FingerIcon } = icons;

const pageStyle: CSSProperties = {
  // height: "297mm",
  height: "fit-content",
  backgroundColor: "#FEFEFE",
  padding: "12px 8px",
  margin: "4px 0px",
};

const noChildrenStyle: CSSProperties = {
  // padding: "1rem 1rem",
  // marginBottom: ".5rem",
  // position: "relative", // 为了定位拖拽符号
  // cursor: "default", // 避免整个行都显示为可拖拽
};

const editStyle: CSSProperties = {
  // border: "1px dashed gray",
};

const dragAreaStyle: CSSProperties = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "103%",
};

const dragHandleStyle: CSSProperties = {
  cursor: "move",
};

const deleteHandleStyle: CSSProperties = {
  cursor: "pointer",
};

const pageTitleStyle: CSSProperties = {
  textAlign: "end",
};

const previewTextStyle: CSSProperties = {
  fontSize: "12px",
  lineHeight: "16px",
  color: "#BFC1C7",
  fontWeight: 500,
};

export interface PageProps {
  id: string;
  item: BaseContainerData;
  pathIndex: number[];
}

export const Item: FC<PageProps> = ({ id, item, pathIndex }) => {
  const {
    state,
    showList,
    // targetItem,
    removeFirstLayerById,
    targetChildrenId,
  } = useList();

  const children = item.components as BaseContainerData[];

  const dropRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hovered, setHovered] = useState(false); // 控制鼠標懸停狀態

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  // const isTarget = targetItem.id === id;

  const { drag, drop, preview, isDragging, handlerId } = useMoveFormProperty({
    id,
    pathIndex,
    item,
    itemType: item.type,
    dropRef,
    canDrag: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSettingTarget = (_event: any) => {
    _event.stopPropagation();
    targetChildrenId(id);
  };

  const handleDelete = () => {
    removeFirstLayerById(id);
  };

  const renderItem = useCallback(
    (_item: BaseContainerData, _index: number) =>
      _item.type === ItemTypesEnum.ROW ? (
        <Row.Item
          key={_item.id}
          id={_item.id}
          item={_item}
          pathIndex={[...pathIndex, _index]}
        />
      ) : null,
    [pathIndex]
  );

  const renderItemMemo = useMemo(
    () => children.map(renderItem),
    [children, renderItem]
  );

  preview(drop(dropRef)); // 绑定 drop 和 preview

  return (
    <Flipped
      flipId={id}
      translate={true} // 僅啟用移動
      scale={false} // 禁用縮放
    >
      <div data-page-id={id}>
        <div style={pageTitleStyle}>
          <div>
            <p
              style={{
                padding: "0px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 4,
                color: "#BFC1C7",
                fontSize: 12,
              }}
            >
              {item?.name || "Untitled"}
            </p>
          </div>
        </div>
        <div
          style={{
            ...pageStyle,
            padding: state === FormState.PREVIEW ? "0px" : "12px 8px",
            minHeight: state === FormState.CREATE ? "297mm" : "auto",
          }}
          data-handler-id={handlerId}
          ref={dropRef}
        >
          <div
            style={{
              ...(children.length > 0 ? {} : noChildrenStyle),
              ...(readOnly ? {} : editStyle),
              opacity: isDragging ? 0.5 : 1,
              position: "relative",
              minHeight: children.length > 0 ? "auto" : "100px",
              height: "100%",
              display: "grid",
              gap: 8,
            }}

            // onMouseEnter={() => setHovered(!readOnly && true)}
            // onMouseLeave={() => setHovered(false)}
          >
            {renderItemMemo}
            {children.length <= 1 && state === FormState.CREATE && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                    padding: "32px 16px",
                  }}
                >
                  <FingerIcon />
                  <p
                    className=" typo-paragraph-sm text-gray-800"
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      lineHeight: "16px",
                      color: "#595f6f",
                      fontWeight: 400,
                    }}
                  >
                    Drag or double click a field from the field panel
                  </p>
                </div>
              </div>
            )}
            {showList && hovered ? (
              <div style={dragAreaStyle}>
                <div
                  ref={drag}
                  style={dragHandleStyle}
                  // onClick={handleSettingTarget}
                >
                  <DragIcon />
                </div>

                <div style={deleteHandleStyle}>
                  <DeleteConfirm onClick={handleDelete} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Flipped>
  );
};

const newPageStyle: CSSProperties = {
  padding: "4px",
  cursor: "grab",
  gap: "4px",
};

export const Add: FC = () => {
  const { pageDefaultSetting } = useList();
  const item = generateDefaultItem(ItemTypesEnum.PAGE, pageDefaultSetting);
  const dropRef = useRef<HTMLDivElement>(null);

  const { drag, preview, isDragging } = useMoveFormProperty({
    id: item.id,
    pathIndex: [-1],
    item,
    itemType: item.type,
    dropRef,
    canDrag: true,
  });

  preview(drag(dropRef));

  return (
    <div
      ref={dropRef}
      style={{
        ...newPageStyle,
        backgroundColor: isDragging ? "lightgreen" : "lightyellow",
      }}
    >
      {ItemTypesEnum.PAGE.toLocaleUpperCase()}
    </div>
  );
};

export const Preview = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const { findFirstLayer } = useList();
  const { item: findItem } = findFirstLayer(id);

  return (
    <div>
      <p
        style={{
          ...previewTextStyle,
          textAlign: "right",
          width: "100%",
          margin: "8px 0px",
        }}
      >
        {findItem?.name || ""}
      </p>
      <div style={{ backgroundColor: "#ffffff" }}>{children}</div>
    </div>
  );
};

export const ShortItem: FC<{
  index: number;
  item: BaseContainerData;
  fieldName: string;
  onClick: () => void;
}> = memo(function ShortItem({ index, item, fieldName, onClick }) {
  const { customItemList, removeFirstLayerById, updateChildrenAttr, copyItem } =
    useList();
  const inputRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleEdit = () => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
    setIsEdit(true);
  };
  const handleCloseEdit = () => setIsEdit(false);

  const handleOnChange = (e: any) => {
    updateChildrenAttr(item.id, {
      name: e.target.value,
    });
  };

  const handleDuplicate = () => {
    copyItem(item.id);
  };
  const handleDelete = () => {
    removeFirstLayerById(item.id);
  };

  return (
    <BaseChild.ShortItem onClick={onClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 4,
          fontSize: 14,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
            textWrap: "nowrap",
          }}
        >
          <div
            style={{
              backgroundColor: "#EDF9FE",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "4px",
              borderRadius: 4,
              width: 48,
            }}
          >
            <PageIcon fill="#4BC7F1" />
            <span>{index && `${index}.`}</span>
          </div>
          <div
            style={{
              display: isEdit ? "block" : "none",
              flex: 1,
              width: "100%",
            }}
          >
            <input
              ref={inputRef}
              value={fieldName}
              onChange={handleOnChange}
              onBlur={handleCloseEdit}
              style={{ width: "100%" }}
              className="focus:border-b focus:border-primary-400 focus:bg-gray-950/5 placeholder:italic placeholder:text-gray-700 placeholder:typo-paragraph-md"
            />
          </div>
          <div
            style={{
              display: isEdit ? "none" : "flex",
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
              textWrap: "nowrap",
            }}
            onClick={handleEdit}
          >
            <span style={textStyle}>{fieldName ?? "Untitled Page"}</span>
          </div>
        </div>

        {customItemList?.[OtherSettingTypesEnum.MENUCOMPONENT]?.Component ? (
          renderCustomComponent(
            customItemList,
            OtherSettingTypesEnum.MENUCOMPONENT,
            "Component",
            {
              onClose: handleClose,
              onEdit: handleEdit,
              onDuplicate: handleDuplicate,
              onDelete: handleDelete,
            }
          )
        ) : (
          <div onClick={handleOpen}>
            <MoreIcon />
          </div>
        )}
      </div>
    </BaseChild.ShortItem>
  );
});

export const Setting = () => {
  return <div style={{ backgroundColor: "#ffffff" }} />;
};

export default {
  Item: memo(Item, (prevProps, nextProps) => {
    // console.debug("page", prevProps, nextProps);
    return isEqual(prevProps, nextProps);
    // return isEqual(omit(prevProps, "item"), omit(nextProps, "item"));
  }),
  Add,
  Preview,
  ShortItem,
  Setting: memo(Setting),
};
