import { createContext, useContext } from "react";
import {
  FindParentItem,
  FindSecondContainer,
  SearchItem,
  BaseContainerData,
  BaseItemData,
} from "../type/Interface";
import { FormState } from "../type/AttrTypes";

import { CustomItemList } from "../DragContainer";

// 定义 context 的类型，包括 list 和 moveItem 函数
interface ListContextType {
  // 基礎資料
  list: BaseContainerData[];
  setList: (value: any) => void;
  targetItem: { id: string; item: any };
  targetCell: { rowIndex: number; colIndex: number };

  steps?: any[];
  stepButtons?: any[];
  stepTeams?: any[];
  currentStepId?: string;
  currentTeamIds?: number[];
  hasTeamPermission?: boolean;
  isProjectManager?: boolean;
  customStepButtons?: React.ReactNode;

  activeTab: string; // 0 | 1 | 2
  onChangreActiveTab: (id: string) => void;

  // 帶入參數
  itemList: string[];
  customItemList?: CustomItemList;
  showList: boolean;
  state: FormState;
  isCanResizableBox: boolean;
  pageDefaultSetting: any;
  rowDefaultSetting: any;

  // 選取物件
  targetChildrenId: (id: string) => void;
  clearTargetItem: () => void;
  targetCellIndex: (rowIndex: number, colIndex: number) => void;
  clearTargetCellIndex: () => void;

  // 共用事件 搜尋物件
  recursionFindChildrenPathById: (
    id: string,
    currentPath?: number[],
    prevList?: any[]
  ) => SearchItem | null;

  getChildrenByPathId: (
    pathId: number[],
    prevList?: BaseContainerData[]
  ) => BaseContainerData | null;

  copyItem: (id: string) => void;
  copyTable: (id: string, groupId: string) => void;

  // 第一層 First Layer (Container)物件移動事件
  findFirstLayer: (id: string) => FindParentItem;
  addFirstLayer: (item: BaseContainerData) => void;
  moveFirstLayer: (id: string, atIndex: number) => void;
  removeFirstLayerById: (id: string) => void;
  updateFirstLayerId: (id: string, newId: string) => void;
  updateFirstLayerAttr: (attr: any) => void;
  updateTempFirstLayerId: () => void;

  // 第二層 Second Layer (Container)物件移動事件
  findSecondLayer: (id: string, item?: Array<any>) => FindSecondContainer;
  addSecondLayer: (index: number, item: any) => void;
  moveSecondLayer: (id: string, atParentIndex: number) => void;
  moveSecondLayerOnSameLayer: (
    id: string,
    atIndex: number,
    atParentIndex: number
  ) => void;
  removeSecondLayerById: (id: string) => void;
  updateSecondLayerId: (id: string, newId: string) => void;
  updateTempSecondLayerId: () => void;
  updateSecondLayerHeight: (id: string, newHeight: number) => void;
  updateSecondLayersAttr: (attr: any) => void;
  removeEmptySecondLayers: () => void;
  addTempRowOnEveryPageLayer: () => void;

  // 第三層 物件移動事件
  addChildren: (item: BaseItemData, pathId: number[], atIndex?: number) => void;
  moveChildren: (
    childId: string,
    targetPathId: number[],
    atIndex?: number
  ) => boolean;
  updateChildrenId: (id: string, newId: string) => void;
  removeChildren: (id: string) => void;
  updateChildrenValue: (
    id: string,
    value: any
  ) => undefined | BaseContainerData[];
  updateChildrenWidth: (id: string, newWidth: number) => void;
  updateChildrenAttr: (
    id: string,
    attr: any
  ) => undefined | BaseContainerData[];
  updateChildrensAttr: (attr: any) => undefined | BaseContainerData[];

  // Table Row Col 移動事件
  moveTableRow: (id: string, dragIndex: number, atIndex: number) => void;
  moveTableCol: (id: string, dragIndex: number, atIndex: number) => void;
  moveTableChildren: (
    id: string,
    itemPathId: number[],
    targetPathId: number[]
  ) => void;
  addLongTextToTable: (itemPathId: number[], item: BaseItemData | any) => void;

  // Undo Redo
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

// 创建上下文
export const ListContext = createContext<ListContextType | undefined>(
  undefined
);

// 创建一个自定义 Hook 用于访问上下文
export const useList = () => {
  const context = useContext(ListContext);
  if (context === undefined) {
    throw new Error("useList must be used within a ListProvider");
  }
  return context;
};
