import type { CSSProperties, FC } from "react";
import React, { useState, useMemo, memo, useCallback } from "react";
import { Flipper } from "react-flip-toolkit";
import ItemTypesEnum, {
  ItemComponents,
  OtherSettingTypesEnum,
} from "../type/ItemTypes";
import { useList } from "../store/listContext";
import DragSortItem from "./DragSortItem";
import { renderCustomComponent } from "../../utils/methods";

const selectContainerStyle: CSSProperties = {
  padding: "16px 0px 8px 0px",
};

const containerStyle: CSSProperties = {
  padding: "16px 0px",
  display: "flex",
  flexDirection: "column",
  gap: 8,
};

const emptyStateStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: 8,
  padding: "0px 8px",
};
const emptyStateHeaderStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "24px",
};

const emptyStateDescriptionStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#595F6F",
};

const TabCurrentFields: FC = () => {
  const { list, moveSecondLayerOnSameLayer, customItemList } = useList();
  const [pageId, setPageId] = useState<string>(list[0]?.id);

  const pages = useMemo(() => {
    return list.filter((v) => v.type === ItemTypesEnum.PAGE);
  }, [list]);

  const rows = useMemo(() => {
    const page = list.find(
      (v) => v.type === ItemTypesEnum.PAGE && v.id === pageId
    );
    return page ? page.components : [];
  }, [list, pageId]);

  const handleChangePage = (e: any) => setPageId(e.target.value);

  const renderItem = (row: any, index: number) => {
    const component = row.components.map((field: any) =>
      renderItemChildren(field, index + 1)
    );
    return (
      <DragSortItem key={row.id} item={row} index={index} onMove={handleMove}>
        {component}
      </DragSortItem>
    );
  };

  const renderItemChildren = (item: any, index: number) => {
    const Component = ItemComponents[item.type].ShortItem;
    const fieldName = item?.name || item?.setting?.label || undefined;
    return (
      <div key={item.id}>
        <Component index={index} fieldName={fieldName} />
      </div>
    );
  };

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number, item: any) => {
      const pageIndex = list.findIndex((v) => v.id === pageId);
      moveSecondLayerOnSameLayer(item.id, hoverIndex, pageIndex);
    },
    [list]
  );

  const renderItemEmptyState = () => {
    return (
      <div style={emptyStateStyle}>
        <h6 style={emptyStateHeaderStyle}>No Fields Added Yet</h6>
        <p style={emptyStateDescriptionStyle}>
          Fields you add to the form will appear here for easy management. Start
          building your form by adding fields.
        </p>
      </div>
    );
  };

  return (
    <div>
      <div style={selectContainerStyle}>
        {customItemList?.[OtherSettingTypesEnum.SELECTCOMPONENT]?.Component ? (
          renderCustomComponent(
            customItemList,
            OtherSettingTypesEnum.SELECTCOMPONENT,
            "Component",
            { data: pages, onChange: setPageId }
          )
        ) : (
          <select value={pageId} onChange={handleChangePage}>
            {pages.map((v, i) => (
              <option key={v.id} value={v.id}>
                {v.name || `Page ${i + 1}`}
              </option>
            ))}
          </select>
        )}
      </div>
      <div style={containerStyle}>
        {rows.length > 0 ? (
          <Flipper flipKey={rows}>{rows.map(renderItem)}</Flipper>
        ) : (
          renderItemEmptyState()
        )}
      </div>
    </div>
  );
};

export default memo(TabCurrentFields);
