import type { CSSProperties, FC } from "react";
import React, { memo } from "react";
import { Position, NodeToolbar } from "@xyflow/react";
import { useFlow } from "../store/flowContext";
import ItemTypes from "../type/ItemTypes";
import { icons } from "../../assets";

const { SettingIcon, CopyIcon, DeleteIcon } = icons;

const toolbar_node: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  padding: "4px",
  gap: "4px",
  borderRadius: "8px",
  backgroundColor: "#FEFEFE",
};

export interface CustomNodeToolbarProps {
  id: string;
  type: string;
  selected: boolean;
  disabledDelete?: boolean;
}

export const BaseCustomNodeToolbar: FC<CustomNodeToolbarProps> = (props) => {
  const { id, disabledDelete = false } = props;
  const { onCopyNodes, onDeleteNodes, customItemList } = useFlow();
  const handleSetting = () => {
    if (
      typeof customItemList?.[ItemTypes.NODETOOLBAR]?.onSetting === "function"
    )
      customItemList[ItemTypes.NODETOOLBAR].onSetting(id);
  };
  const handleCopy = () => {
    onCopyNodes(id);
    if (typeof customItemList?.[ItemTypes.NODETOOLBAR]?.onCopy === "function")
      customItemList[ItemTypes.NODETOOLBAR].onCopy(id);
  };
  const handleDelete = () => {
    onDeleteNodes(id);
    if (typeof customItemList?.[ItemTypes.NODETOOLBAR]?.onDelete === "function")
      customItemList[ItemTypes.NODETOOLBAR].onDelete(id);
  };
  return (
    <div style={toolbar_node}>
      <div>
        <button style={{ padding: 8 }} onClick={handleSetting}>
          <SettingIcon />
        </button>
      </div>
      <div>
        <button style={{ padding: 8 }} onClick={handleCopy}>
          <CopyIcon />
        </button>
      </div>
      <div>
        <button
          style={{ padding: 8 }}
          disabled={disabledDelete}
          onClick={handleDelete}
        >
          <DeleteIcon fill={disabledDelete ? "gray" : "#D6296C"} />
        </button>
      </div>
    </div>
  );
};

const CustomNodeToolbar: FC<CustomNodeToolbarProps> = (props) => {
  const { selected } = props;
  return (
    <NodeToolbar isVisible={selected} position={Position.Bottom}>
      <BaseCustomNodeToolbar {...props} />
    </NodeToolbar>
  );
};

export default memo(CustomNodeToolbar);
