import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgShortTextIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#42DA6D"}
      d="M2 5.333a.667.667 0 1 0 0 1.333h12a.667.667 0 1 0 0-1.333zM2 8.666A.667.667 0 0 0 2 10h8a.667.667 0 1 0 0-1.334z"
    />
  </svg>
);
export default SvgShortTextIcon;
