import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgUndoIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M13.258 12a.75.75 0 0 0 .742-.76l-.021-.88c-.109-4.573-5.771-6.79-9.081-3.555L3.52 8.15V5.743c0-.41-.34-.743-.76-.743S2 5.333 2 5.743v4.201c0 .41.34.743.76.743h4.3c.42 0 .76-.332.76-.743 0-.41-.34-.742-.76-.742H4.594l1.378-1.347c2.364-2.31 6.408-.727 6.486 2.54l.021.88c.01.41.358.734.778.725"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUndoIcon;
