import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgSubFormIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#99958B"}
      fillRule="evenodd"
      d="M3.325 3.325c.209-.208.491-.325.786-.325h7.778A1.11 1.11 0 0 1 13 4.111v1.111a1.11 1.11 0 0 1-1.111 1.111H4.11A1.11 1.11 0 0 1 3 5.223V4.11c0-.295.117-.577.325-.786m8.564.786H4.11v1.111h7.778zM3.325 7.77c.209-.208.491-.326.786-.326h2.222a1.11 1.11 0 0 1 1.111 1.112v3.333A1.11 1.11 0 0 1 6.334 13H4.11A1.11 1.11 0 0 1 3 11.889V8.556c0-.295.117-.578.325-.786m3.008.786H4.111v3.333h2.222z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#99958B"}
      d="M9.111 7.444a.556.556 0 0 0 0 1.112h3.333a.556.556 0 1 0 0-1.112zM8.556 10.222c0-.307.248-.555.555-.555h3.333a.556.556 0 1 1 0 1.11H9.111a.556.556 0 0 1-.555-.555M9.111 11.889a.556.556 0 1 0 0 1.111h3.333a.556.556 0 1 0 0-1.111z"
    />
  </svg>
);
export default SvgSubFormIcon;
