import type { CSSProperties, FC } from "react";
import React, { memo, useMemo } from "react";
import { Position } from "@xyflow/react";
import { useFlow } from "../store/flowContext";
import FlowState from "../type/FlowState";
import CustomNodeToolbar from "../components/CustomNodeToolbar";
import CustomHandle from "../components/CustomHandle";
import { renderCustomComponent } from "../../utils/methods";

const text_updater_node_label: CSSProperties = {
  display: "block",
  color: "#777",
  fontSize: "12px",
};

export interface BackToPreviousProps {
  id: string;
  data: any;
  type: string;
  isConnectable: boolean;
  selected: boolean;
}

const BackToPreviousNode: FC<BackToPreviousProps> = (props) => {
  const { id, data, type, isConnectable, selected } = props;

  const {
    update,
    flowState,
    onChangeNodesData,
    customItemList,
    onConnectNode,
    findConnectStep,
    findEdgesConnectStep,
  } = useFlow();

  const readOnly = flowState === FlowState.PREVIEW;
  const connectNodes = findConnectStep(id);
  const connectToSteps = findEdgesConnectStep(id, connectNodes);

  const handleOnChange = (e: any) => {
    onChangeNodesData(id, e);
  };

  const handleOnChangeById = (_id: string, e: any) => {
    onChangeNodesData(_id, e);
  };

  const handleOnConnectStep = (targetId: string) => {
    onConnectNode(id, targetId);
  };

  const newProps = {
    id,
    flowState,
    data,
    connectNodes, // 前面全部的 step node
    connectToSteps, // 已經連線的 step
    onChange: handleOnChange,
    onChangeById: handleOnChangeById,
    selected,
    onConnectStep: handleOnConnectStep, // BackToPrevious CreateNewVersion 才有
    update,
  };

  const componentMemo = useMemo(() => {
    return customItemList?.[type] ? (
      renderCustomComponent(customItemList, type, "Component", newProps)
    ) : (
      <label htmlFor="text" style={text_updater_node_label}>
        {type}
      </label>
    );
  }, [customItemList]);

  return (
    <div>
      <CustomHandle id={type} type="target" isConnectable={isConnectable} />

      <div>{componentMemo}</div>
      {readOnly ? null : (
        <CustomNodeToolbar id={id} type={type} selected={selected} />
      )}

      <CustomHandle
        id={type}
        type="source"
        isConnectable={isConnectable}
        outPosition={Position.Bottom}
      />
    </div>
  );
};

export default memo(BackToPreviousNode);
