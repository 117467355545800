import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgCloseIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M12.862 4.212a.667.667 0 0 0 0-.943l-.132-.131a.667.667 0 0 0-.943 0L8 6.926 4.212 3.138a.667.667 0 0 0-.943 0l-.131.131a.667.667 0 0 0 0 .943L6.926 8l-3.788 3.787a.667.667 0 0 0 0 .943l.131.132c.26.26.683.26.943 0L8 9.074l3.787 3.788c.26.26.683.26.943 0l.132-.132a.667.667 0 0 0 0-.943L9.074 8z"
    />
  </svg>
);
export default SvgCloseIcon;
