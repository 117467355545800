import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgHeaderSectionIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#99958B"}
      fillRule="evenodd"
      d="M.933 4A3.067 3.067 0 0 1 4 .934h8A3.067 3.067 0 0 1 15.067 4v8A3.067 3.067 0 0 1 12 15.067H4A3.067 3.067 0 0 1 .933 12zM4 2.267c-.957 0-1.733.776-1.733 1.733v.534h11.466V4c0-.957-.776-1.733-1.733-1.733zm9.733 3.6H2.267V12c0 .958.776 1.734 1.733 1.734h8c.957 0 1.733-.776 1.733-1.734zM3.333 8c0-.368.299-.666.667-.666h8a.667.667 0 1 1 0 1.333H4A.667.667 0 0 1 3.333 8m0 3.2c0-.368.299-.666.667-.666h4.8a.667.667 0 0 1 0 1.333H4a.667.667 0 0 1-.667-.667"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHeaderSectionIcon;
