import type { FC } from "react";
import React, { useMemo, memo } from "react";
import { useList } from "../../store/listContext";
import { FormState } from "../../type/AttrTypes";
import { InputTableEnum } from "../../type/ItemTypes";

import InputTableSingleSelection from "./InputTableSingleSelection";
import InputTableMultipleSelection from "./InputTableMultipleSelection";
import InputTableTextInput from "./InputTableTextInput";
import InputTableDropdown from "./InputTableDropdown";

const TableCell: FC<any> = (props) => {
  const { children } = props;
  const { state } = useList();

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const handleChange = (e: any) => {
    onChange({ title: e.target.value });
  };

  if (React.isValidElement(children)) {
    return children;
  }

  const { item, onChange } = props;

  switch (item.type) {
    case InputTableEnum.ROWHEAD: {
      return (
        <div>
          <input
            type="text"
            value={item?.title || "Row"}
            onChange={handleChange}
            style={{ width: "100%" }}
            disabled={readOnly}
          />
        </div>
      );
    }
    case InputTableEnum.COLUMNHEAD: {
      return (
        <div>
          <input
            type="text"
            value={item?.title || "Col"}
            onChange={handleChange}
            style={{ width: "100%" }}
            disabled={readOnly}
          />
          X
        </div>
      );
    }
    case InputTableEnum.DROPDOWN: {
      return <InputTableDropdown {...props} />;
    }
    case InputTableEnum.MULTIPLESELECTION: {
      return <InputTableMultipleSelection {...props} />;
    }
    case InputTableEnum.SINGLESELECTION: {
      return <InputTableSingleSelection {...props} />;
    }
    case InputTableEnum.TEXTINPUT: {
      return <InputTableTextInput {...props} />;
    }
    default:
      return <div>-</div>;
  }
};

export default memo(TableCell);
