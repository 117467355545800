import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgScreenfullIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M13.4 2.091a.6.6 0 0 1 .6.601v2.514a.6.6 0 1 1-1.202 0V4.143L9.635 7.307a.6.6 0 1 1-.85-.85l3.163-3.164h-1.063a.6.6 0 1 1 0-1.202zM2.692 14a.6.6 0 0 1-.6-.601v-2.514a.601.601 0 0 1 1.201 0v1.063l3.164-3.163a.6.6 0 1 1 .85.85l-3.164 3.163h1.063a.6.6 0 1 1 0 1.202z"
    />
  </svg>
);
export default SvgScreenfullIcon;
