import type { FC } from "react";
import React, { memo } from "react";

const InputTableSingleSelection: FC<any> = (props) => {
  const { rowIndex, colIndex, onChange, readOnly } = props;

  const handleChange = (e: any) => {
    onChange({ value: e.target.value });
  };

  return (
    <div>
      <input
        value={`${rowIndex}_SingleSelection_${colIndex}`}
        onChange={handleChange}
        name={`SingleSelection_${colIndex}`}
        type="radio"
        disabled={readOnly}
      />
    </div>
  );
};

export default memo(InputTableSingleSelection);
