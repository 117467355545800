import {
  FormCondition,
  FormConditionsRequest,
  FormDetail,
  FormPageData,
  WorkflowValidation,
} from '@/lib/form/formTemplateSetting/formTemplateSetting.interface';
import { formTemplateSettingApi } from '@/lib/form/formTemplateSetting/formTemplateSettingAPI';
import { StepRecord } from '@/lib/submissions/submissions.interface';
import { workflowStepToStepRecords } from '@/lib/submissionTemplates/submissionTemplateSettingSlice';
import {
  DeviceType,
  Orientation,
} from '@app/form/template/[formTemplateId]/_component/Preview/preview.interface';
import { cardStepDefaultData } from '@app/form/template/[formTemplateId]/_component/Workflow/CardData';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { utils } from '@gobuid/flow-template';
import { getComponentTypeInfo } from '@/utils/formTemplateMap';

const { validateFlowData } = utils;

export const workflowStepMap = (nodes: any[]) => {
  if (!nodes) return [];
  return nodes
    .filter((node) => node.type === 'step')
    .map((v) => ({
      id: v.id,
      type: v.type,
      teamIds: v.data.teamIds,
      name: v.data.stepName,
      pageIds: v.data.pageIds,
    }));
};

export const workflowButtonMap = (nodes: any[]) => {
  if (!nodes) return [];
  return nodes
    .filter((node) => node.type === 'button')
    .map((v) => ({
      id: v.id,
      type: v.type,
      name: v.data.name,
      color: v.data.color,
    }));
};

const PAGE_DEFAULT_DATA = [
  {
    id: uuidv4(),
    name: 'Untitled',
    type: 'page',
    childSetting: {},
    class: '',
    components: [
      {
        id: uuidv4(),
        type: 'row',
        childSetting: {},
        class: '',
        components: [
          {
            id: uuidv4(),
            type: 'headerSection',
            class: '',
            components: null,
            readonly: false,
            required: false,
            visible: false,
            value: '',
            setting: {
              items: {
                referenceNumber: {
                  id: 'referenceNumber',
                  label: 'Reference No.',
                  order: 1,
                  enabled: true,
                },
                submittalDate: {
                  id: 'submittalDate',
                  label: 'Submittal Date',
                  order: 2,
                  enabled: true,
                },
                company: {
                  id: 'company',
                  label: 'Company',
                  order: 3,
                  enabled: false,
                },
                project: {
                  id: 'project',
                  label: 'Project',
                  order: 0,
                  enabled: true,
                },
                projectDuration: {
                  id: 'projectDuration',
                  label: 'Project Duration',
                  order: 4,
                  enabled: false,
                },
                projectLocation: {
                  id: 'projectLocation',
                  label: 'Project Location',
                  order: 5,
                  enabled: false,
                },
                projectDescription: {
                  id: 'projectDescription',
                  label: 'Project Description',
                  order: 6,
                  enabled: false,
                },
                validDate: {
                  id: 'validDate',
                  label: 'Valid Date',
                  order: 7,
                  enabled: false,
                },
              },
            },
          },
        ],
      },
    ],
  },
];

export const CONDITION_MODE = {
  DEFAULT: 'default',
  CREATE: 'create',
  EDIT: 'edit',
  READONLY: 'readonly',
} as const;

export type ConditionMode =
  (typeof CONDITION_MODE)[keyof typeof CONDITION_MODE];

interface InitialState {
  pages: FormPageData[] | null;
  photos: any[] | null;
  files: any[] | null;
  tempResource: {
    photos: any[];
    files: any[];
  };
  fieldSettingVisible: boolean;
  fieldMenuVisible: boolean;
  preview: {
    device: DeviceType;
    orientation: Orientation;
    isEditMode: boolean;
    copyPages: FormPageData[] | null;
    selectedStep: {
      id: string;
      value: string;
      label: string;
    };
    selectedTeamId: number | null;
  };
  workflow: {
    nodes: any[] | null;
    edges: any[] | null;
    steps: any[] | null;
    teams: number[] | null;
    buttons: any[] | null;
    validation: WorkflowValidation;
  };
  formDetail: FormDetail | null;
  isFormDetailReady: boolean;
  view: {
    stepRecords: StepRecord[] | null;
    isStepInfoVisible: boolean;
  };
  formConditions: {
    conditionMode: ConditionMode;
    hasConditions: boolean;
    currentSections: any[] | null;
    currentFields: any[] | null;
    currentConditionId: string | null;
    conditions: {
      formCondition: FormCondition | null;
      templateId: number;
    } | null;
    selectedSection: {
      pageId: string;
      name: string;
    } | null;
    selectedField: {
      fieldId: string;
      name: string;
      index: number;
      type: string;
    } | null;
  };
}

const initialState: InitialState = {
  pages: [],
  photos: [],
  files: [],
  tempResource: {
    photos: [],
    files: [],
  },
  fieldSettingVisible: false,
  fieldMenuVisible: true,
  preview: {
    device: 'desktop',
    orientation: 'portrait',
    isEditMode: false,
    copyPages: [],
    selectedStep: {
      id: '',
      value: '',
      label: '',
    },
    selectedTeamId: null,
  },
  workflow: {
    nodes: [],
    edges: [],
    teams: [],
    steps: [],
    buttons: [],
    validation: {
      isAllSendEmail: false,
      isAllButtonClose: false,
      isAllBackToPreviousClose: false,
      isAllCreateNewVersionClose: false,
      isAllClose: false,
    },
  },
  formDetail: {
    name: '',
    folderName: '',
    folderId: null,
    referenceNumberPrefix: '',
    referenceNumberSuffix: '',
    referenceNumberStartsFrom: '',
    validTimeStepId: null,
    validTimeDays: null,
  },
  isFormDetailReady: false,
  view: {
    stepRecords: null,
    isStepInfoVisible: true,
  },
  formConditions: {
    conditionMode: CONDITION_MODE.DEFAULT,
    hasConditions: false,
    currentSections: null,
    currentFields: null,
    currentConditionId: null,
    conditions: null,
    selectedSection: null,
    selectedField: null,
  },
};

const formTemplateSettingSlice = createSlice({
  name: 'formTemplateSetting',
  initialState,
  reducers: {
    setPages: (state, action) => {
      const pages = action.payload;
      state.pages = pages;
      state.preview.copyPages = pages;
      state.formConditions.currentSections = pages.filter(
        (v) => v.type === 'page',
      );
      state.formConditions.currentFields = pages
        .flatMap((page) => page.components || [])
        .filter((v) => v.type === 'row');

      const pageFilter = pages.filter((v) => v.type === 'page');
      state.formConditions.currentSections = pageFilter.map((page) => ({
        pageId: page.id,
        name: page.name,
      }));

      const rowFilter = pages
        .flatMap((page) => {
          // 先為每個 component 添加 pageId
          return (page.components || []).map((component) => ({
            ...component,
            pageId: page.id,
            pageName: page.name,
          }));
        })
        .filter((v) => v.type === 'row');

      state.formConditions.currentFields = rowFilter.map((row) => {
        const component = row.components?.[0];
        console.log('component', component);

        const { icon, color, label } = getComponentTypeInfo(component.type);

        return {
          fieldId: row.components?.[0]?.id,
          name: row.components?.[0]?.setting?.label || label,
          pageId: row.pageId,
          pageName: row.pageName,
          type: row.components?.[0]?.type,
          icon,
          color,
        };
      });
    },
    setFieldSettingVisible: (state, action) => {
      state.fieldSettingVisible = action.payload;
    },
    setFieldMenuVisible: (state, action) => {
      state.fieldMenuVisible = action.payload;
    },
    setDevice: (state, action) => {
      state.preview.device = action.payload;
    },
    setOrientation: (state, action) => {
      state.preview.orientation = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.preview.isEditMode = action.payload;
    },
    setFormDetail: (state, action) => {
      state.formDetail = action.payload;
    },
    setFormDetailReady: (state, action) => {
      state.isFormDetailReady = action.payload;
    },
    setWorkflowValidation: (state, action) => {
      state.workflow.validation = action.payload;
    },
    setWorkflowNodes: (state, action) => {
      state.workflow.nodes = action.payload;
      state.workflow.steps = workflowStepMap(action.payload);
      state.workflow.buttons = workflowButtonMap(action.payload);
      state.preview.selectedStep = {
        id: state.workflow.steps?.[0]?.id ?? '',
        value: state.workflow.steps?.[0]?.id ?? '',
        label: state.workflow.steps?.[0]?.name ?? '',
      };
      const teams = new Set<number>();
      action.payload
        .filter((v) => v.type === 'step')
        .forEach((v) => {
          v.data.teamIds.forEach((teamId: number) => {
            teams.add(teamId);
          });
        });
      state.workflow.teams = Array.from(teams);
    },
    setWorkflowEdges: (state, action) => {
      state.workflow.edges = action.payload;
    },
    setSelectedStep: (state, action) => {
      state.preview.selectedStep = action.payload;
    },
    setSelectedTeamId: (state, action) => {
      state.preview.selectedTeamId = action.payload;
    },
    setIsStepInfoVisible: (state, action) => {
      state.view.isStepInfoVisible = action.payload;
    },
    setTempResource: (state, action) => {
      state.tempResource = action.payload;
    },
    setHasConditions: (state, action) => {
      state.formConditions.hasConditions = action.payload;
    },
    setConditionMode: (state, action) => {
      state.formConditions.conditionMode = action.payload;
    },
    setCurrentConditionId: (state, action) => {
      state.formConditions.currentConditionId = action.payload;
    },
    setConditions: (state, action) => {
      state.formConditions.conditions = action.payload;
    },
    setUpdateNestedConditionComparisonType: (state, action) => {
      const { conditionIndex, newType } = action.payload;
      const condition =
        state.formConditions.conditions?.formCondition?.nestedConditions[
          conditionIndex
        ];
      if (condition) {
        condition.comparisonCondition = {
          ...condition.comparisonCondition,
          type: newType,
        };
      }
    },
    setUpdateNestedConditionLogicType: (state, action) => {
      const { conditionIndex, newType } = action.payload;
      const condition =
        state.formConditions.conditions?.formCondition?.nestedConditions[
          conditionIndex
        ];

      if (condition) {
        condition.logicType = newType;
      }
    },
    setUpdateNestedConditionDoFieldType: (state, action) => {
      const { conditionIndex, newType } = action.payload;
      const condition =
        state.formConditions.conditions?.formCondition?.nestedConditions[
          conditionIndex
        ];

      if (condition) {
        condition.fieldCondition = newType;
      }
    },
    setSelectedSection: (state, action) => {
      state.formConditions.selectedSection = action.payload;
    },
    setSelectedField: (state, action) => {
      state.formConditions.selectedField = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormBody.matchFulfilled,
      (state, action) => {
        state.files = action.payload.files ?? [];
        state.photos = action.payload.photos ?? [];
        state.pages = action.payload.pages ?? PAGE_DEFAULT_DATA;
        state.preview.copyPages = action.payload.pages ?? PAGE_DEFAULT_DATA;
        const pageFilter = action.payload.pages.filter(
          (v) => v.type === 'page',
        );
        state.formConditions.currentSections = pageFilter.map((page) => ({
          pageId: page.id,
          name: page.name,
        }));
        state.formConditions.selectedSection = {
          pageId: pageFilter[0].id,
          name: pageFilter[0].name,
        };
        const rowFilter = action.payload.pages
          .flatMap((page) => {
            // 先為每個 component 添加 pageId
            return (page.components || []).map((component) => ({
              ...component,
              pageId: page.id, // 添加頁面 ID
              pageName: page.name, // 可選：添加頁面名稱
            }));
          })
          .filter((v) => v.type === 'row');

        state.formConditions.currentFields = rowFilter.map((row) => {
          const component = row.components?.[0];

          const { icon, color, label } = getComponentTypeInfo(component.type);

          return {
            fieldId: row.components?.[0]?.id,
            name: row.components?.[0]?.setting?.label || label,
            pageId: row.pageId,
            pageName: row.pageName,
            type: row.components?.[0]?.type,
            icon,
            color,
          };
        });
      },
    );
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormWorkflow.matchFulfilled,
      (state, action) => {
        const nodes = action.payload.nodes ?? [
          {
            id: uuidv4(),
            type: 'step',
            position: { x: 0, y: 0 },
            data: cardStepDefaultData,
          },
        ];
        const edges = action.payload.edges ?? [];
        const steps = workflowStepMap(nodes);

        state.workflow.nodes = nodes;
        state.workflow.edges = edges;
        state.workflow.steps = steps;
        state.workflow.buttons = workflowButtonMap(nodes);
        state.preview.selectedStep = {
          id: state.workflow.steps?.[0]?.id ?? '',
          value: state.workflow.steps?.[0]?.id ?? '',
          label: state.workflow.steps?.[0]?.name ?? '',
        };
        state.view.stepRecords = workflowStepToStepRecords(steps);
        state.workflow.validation = validateFlowData(nodes, edges);
        const teams = new Set<number>();
        steps.forEach((v) => {
          v.teamIds.forEach((teamId: number) => {
            teams.add(teamId);
          });
        });
        state.workflow.teams = Array.from(teams);
      },
    );
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormDetail.matchFulfilled,
      (state, action) => {
        state.formDetail = action.payload;
        state.isFormDetailReady = true;
      },
    );
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormPdfDetail.matchFulfilled,
      (state, action) => {
        // console.debug(action.payload)
      },
    );
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormConditions.matchFulfilled,
      (state, action) => {
        state.formConditions.conditions = action.payload;
      },
    );
  },
});

export const {
  setPages,
  setFieldSettingVisible,
  setFieldMenuVisible,
  setDevice,
  setOrientation,
  setIsEditMode,
  setFormDetail,
  setFormDetailReady,
  setWorkflowNodes,
  setWorkflowEdges,
  setSelectedStep,
  setSelectedTeamId,
  setWorkflowValidation,
  setIsStepInfoVisible,
  setTempResource,
  setHasConditions,
  setConditionMode,
  setCurrentConditionId,
  setConditions,
  setUpdateNestedConditionComparisonType,
  setUpdateNestedConditionLogicType,
  setUpdateNestedConditionDoFieldType,
  setSelectedSection,
  setSelectedField,
} = formTemplateSettingSlice.actions;
export default formTemplateSettingSlice.reducer;
