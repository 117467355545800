"use client";

import "./globals";

// Form
import DragContainer from "./form/DragContainer";
import { DragArea } from "./form/DragArea";
import DragItemList from "./form/DragItemList";
import DragSettingArea from "./form/DragSettingArea";
import ItemTypesEnum, {
  AllItemKeys,
  InputTableEnum,
  OtherSettingTypesEnum,
} from "./form/type/ItemTypes";
import { FormState } from "./form/type/AttrTypes";

// Flow
import FlowContainer from "./flow/FlowContainer";
import FlowArea from "./flow/FlowArea";
import SettingArea from "./flow/SettingArea";
import FlowState from "./flow/type/FlowState";

import { generateDefaultNodeAndEdge, validateFlowData } from "./utils/methods";

const form = {
  DragContainer,
  DragArea,
  DragItemList,
  DragSettingArea,
  ItemTypes: ItemTypesEnum,
  InputTableEnum: InputTableEnum,
  OtherSettingTypes: OtherSettingTypesEnum,
  AllItemTypes: AllItemKeys,
  FormState,
};

const flow = {
  FlowContainer,
  FlowArea,
  SettingArea,
  FlowState,
};

const utils = { generateDefaultNodeAndEdge, validateFlowData };

export { form, flow, utils };

export type FormStateType = (typeof FormState)[keyof typeof FormState];
export type FlowStateType = (typeof FlowState)[keyof typeof FlowState];
