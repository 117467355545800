import React, { useEffect, useState, useRef, useMemo } from "react";
import { ResizableBox } from "react-resizable";
import { useList } from "../store/listContext";
import { FormState, tableChildrenKey } from "../type/AttrTypes";

const CustomTable = (props: {
  tableId: string;
  data: any[][];
  children: React.ReactNode;
}) => {
  const { tableId, data, children } = props;
  const column = data?.[0] || [];
  const tableRef = useRef<HTMLTableElement>(null);
  const [tableWidths, setTableWidths] = useState<number[]>([]);

  const { state, updateChildrenAttr } = useList();

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  useEffect(() => {
    if (tableRef.current) {
      setTableWidths(
        column.map(
          (c) =>
            c.width ||
            (tableRef.current
              ? tableRef.current.scrollWidth / column.length
              : 743 / column.length)
        )
      );
    }
  }, []);

  const handleOnResize =
    (index: number) =>
    (_event: React.SyntheticEvent, { size }: any) => {
      setTableWidths((prev) => {
        const newTableWidths = [...prev];
        newTableWidths[index] = size.width;
        return newTableWidths;
      });
    };

  const handleOnResizeStop =
    (index: number) =>
    (_event: React.SyntheticEvent, { size }: any) => {
      const newTable = structuredClone(data);
      newTable.forEach((row) => {
        row.forEach((col, i) => {
          // eslint-disable-next-line no-param-reassign
          col.width = i === index ? size.width : col.width;
        });
      });
      updateChildrenAttr(tableId, { [tableChildrenKey]: newTable });
    };

  return (
    <div
      style={{
        // overflow: "hidden",
        minWidth: "max-content",
      }}
    >
      <table ref={tableRef}>
        <thead>
          <tr>
            {column.map((th, index) => (
              <th
                key={`th-${index}`}
                style={{
                  width: tableWidths[index],
                }}
              >
                {readOnly ? null : (
                  <ResizableBox
                    width={tableWidths[index]}
                    height={10}
                    minConstraints={[10, Infinity]}
                    // maxConstraints={[parentParentWidth, Infinity]}
                    onResize={handleOnResize(index)}
                    onResizeStop={handleOnResizeStop(index)}
                    axis="x"
                    // draggableOpts={{ grid: [6, 6] }}
                  >
                    {/* {tableWidths[index]} */}
                  </ResizableBox>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default CustomTable;
