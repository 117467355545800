import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgVerticalLayoutIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M7.333 2A1.333 1.333 0 0 0 6 3.333v1.334A1.333 1.333 0 0 0 7.333 6v1.333h-2A2.667 2.667 0 0 0 2.667 10a1.333 1.333 0 0 0-1.334 1.333v1.334A1.333 1.333 0 0 0 2.667 14H4a1.334 1.334 0 0 0 1.333-1.333v-1.334A1.333 1.333 0 0 0 4 10a1.333 1.333 0 0 1 1.333-1.333h5.334A1.333 1.333 0 0 1 12 10a1.334 1.334 0 0 0-1.333 1.333v1.334A1.334 1.334 0 0 0 12 14h1.333a1.334 1.334 0 0 0 1.334-1.333v-1.334A1.334 1.334 0 0 0 13.333 10a2.667 2.667 0 0 0-2.666-2.667h-2V6A1.333 1.333 0 0 0 10 4.667V3.333A1.333 1.333 0 0 0 8.667 2zm0 1.333h1.334v1.334H7.333zm4.667 8h1.333v1.334H12zm-8 0H2.667v1.334H4z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVerticalLayoutIcon;
