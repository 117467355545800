import React from "react";
import type { XYCoord } from "react-dnd";
import { useDrag, useDrop } from "react-dnd";
import { isEqual, throttle } from "lodash";
import { useList } from "../store/listContext";
import ItemTypesEnum, { AllItemKeys } from "../type/ItemTypes";
import { tempPageId, tempRowId, generateDefaultItem } from "../type/Interface";
import { IsPathIdInTable } from "../../utils/methods";

export interface SortPropertyProps {
  id: string;
  item: any;
  pathIndex: number[];
  itemType: string;
  dropRef: React.RefObject<HTMLDivElement>;
  canDrag: boolean;
}

const isOpenMoveLog = !true;
const consolelog = isOpenMoveLog ? console.log : () => {};
const consoledebug = isOpenMoveLog ? console.debug : () => {};

export const useMoveFormProperty = (props: SortPropertyProps) => {
  const {
    id,
    item: originalItem,
    pathIndex,
    itemType,
    dropRef,
    canDrag,
  } = props;

  // TODO
  // const { draggingItemId, setDraggingItemId } = useDragStatus();

  const {
    list,
    pageDefaultSetting,
    rowDefaultSetting,
    recursionFindChildrenPathById,
    getChildrenByPathId,

    findFirstLayer,
    addFirstLayer,
    // updateFirstLayerId,
    updateTempFirstLayerId,

    addSecondLayer,
    moveSecondLayer,
    moveSecondLayerOnSameLayer,
    // updateSecondLayerId,
    updateTempSecondLayerId,

    addChildren,
    moveChildren,
    moveTableChildren,
    // updateChildrenId,
  } = useList();

  const handleMovabilityVertical = (
    dragIndex: number,
    hoverIndex: number,
    hoverClientY: number,
    hoverMiddleY: number
  ) => {
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return false;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return false;
    }

    return true;
  };

  const handleMovabilityHorizontal = (
    dragIndex: number,
    hoverIndex: number,
    hoverClientX: number,
    hoverMiddleX: number
  ) => {
    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      return false;
    }

    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      return false;
    }

    return true;
  };

  const handleHover = throttle(
    (
      item: {
        id: string;
        item: any;
        pathIndex: number[];
        isIndependent?: boolean;
      },
      monitor
    ) => {
      const hoverBoundingRect =
        dropRef.current?.getBoundingClientRect() as DOMRect;
      const clientOffset = monitor.getClientOffset();
      if (!hoverBoundingRect || !clientOffset) return;

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      const hoverMiddleX =
        (hoverBoundingRect.left + hoverBoundingRect.right) / 2;
      const hoverClientX = (clientOffset as XYCoord).x;

      // consolelog(pathIndex, item.pathIndex, itemType, item.item.type);

      let dragIndex = -1;
      let hoverIndex = -1;

      // consolelog({ props, item });
      // props 被拖曳到的區域
      // item 拖曳的物件
      // consolelog(props.itemType, item.item.type);

      // consolelog(props, item);
      // 1. 新增 Item 的時候沒有 Page 預設新增一個 用 tmpePageId
      // 2. 新增 Item 的時候檢查每個 Page 底下的 Row, 如果 Row.Components > 1 就在該 Page 先新增一個空的 Row 用 tmpeRowId
      //    2-1. 如果有新增多個 Row 則在 End 的時候判斷 Row.Components, > 1 則更新 id, === 0 則刪除
      // 3.

      switch (item.item.type) {
        case ItemTypesEnum.DRAGAREA: {
          consolelog("DRAGAREA");
          return;
        }
        case ItemTypesEnum.PAGE: {
          switch (itemType) {
            case ItemTypesEnum.DRAGAREA: {
              consolelog("PAGE-DRAGAREA");
              return;
            }
            case ItemTypesEnum.PAGE: {
              consolelog("PAGE-PAGE");
              return;
            }
            case ItemTypesEnum.ROW: {
              consolelog("PAGE-ROW");
              return;
            }
            default: {
              consolelog("PAGE-default");
              return;
            }
          }
        }
        case ItemTypesEnum.ROW: {
          switch (itemType) {
            case ItemTypesEnum.DRAGAREA: {
              consolelog("ROW-DRAGAREA");
              return;
            }
            case ItemTypesEnum.PAGE: {
              consolelog("ROW-PAGE");
              dragIndex = item.pathIndex[0];
              hoverIndex = pathIndex[0];

              if (dragIndex === hoverIndex) {
                return;
              }

              moveSecondLayer(item.item.id, hoverIndex);
              item.pathIndex = [hoverIndex];
              return;
            }
            case ItemTypesEnum.ROW: {
              consolelog("ROW-ROW");
              dragIndex = item.pathIndex[1];
              hoverIndex = pathIndex[1];

              if (dragIndex === hoverIndex) {
                return;
              }

              if (
                !handleMovabilityVertical(
                  dragIndex,
                  hoverIndex,
                  hoverClientY,
                  hoverMiddleY
                )
              ) {
                return;
              }
              consolelog("ROW-ROW-Movability");

              moveSecondLayerOnSameLayer(
                item.item.id,
                hoverIndex,
                pathIndex[0]
              );
              item.pathIndex = [pathIndex[0], hoverIndex];

              return;
            }
            default: {
              consolelog("ROW-default");
              return;
            }
          }
          return;
        }
        default: {
          switch (itemType) {
            case ItemTypesEnum.DRAGAREA: {
              consolelog("default-DRAGAREA");
              const findData = findFirstLayer(tempPageId);
              if (list.length === 0 && !findData.item) {
                const defaultPageItem = generateDefaultItem(
                  ItemTypesEnum.PAGE,
                  pageDefaultSetting
                );
                defaultPageItem.id = tempPageId;
                addFirstLayer(defaultPageItem);
                return;
              }
              return;
            }
            case ItemTypesEnum.PAGE: {
              consolelog("default-PAGE");
              const findParent = getChildrenByPathId([pathIndex[0]]);
              if (
                !recursionFindChildrenPathById(tempRowId) &&
                findParent?.components[findParent?.components.length - 1]
                  ?.components.length !== 0
              ) {
                consolelog("default-PAGE-generateROW");
                const defaultRowItem = generateDefaultItem(
                  ItemTypesEnum.ROW,
                  rowDefaultSetting
                );
                defaultRowItem.id = tempRowId;
                addSecondLayer(pathIndex[0], defaultRowItem);
              }
              return;
            }
            case ItemTypesEnum.ROW: {
              // 需要檢查是否在 Table 內
              consolelog("default-ROW");

              // 目前 Table 不放 Table
              if (
                IsPathIdInTable(pathIndex) &&
                [
                  ItemTypesEnum.TABLE,
                  ItemTypesEnum.INPUTTABLE,
                  ItemTypesEnum.SUBFORM,
                ].includes(item.item.type)
              ) {
                return;
              }

              // 判斷預設新增一個空的 Row
              const findParent = getChildrenByPathId([pathIndex[0]]);
              if (
                !recursionFindChildrenPathById(tempRowId) &&
                findParent?.components[findParent?.components.length - 1]
                  ?.components.length !== 0
              ) {
                consolelog("default-ROW-generateROW");
                const defaultRowItem = generateDefaultItem(
                  ItemTypesEnum.ROW,
                  rowDefaultSetting
                );
                defaultRowItem.id = tempRowId;
                addSecondLayer(pathIndex[0], defaultRowItem);
              }

              const findData = recursionFindChildrenPathById(item.item.id);
              if (!findData) {
                addChildren(item.item, pathIndex);
                item.pathIndex = [
                  pathIndex[0],
                  pathIndex[1],
                  originalItem.components.length,
                ];
                return;
              }

              consolelog(pathIndex, item.pathIndex);

              // Row 和 Table Row 的交互移動事件
              if (IsPathIdInTable(pathIndex)) {
                if (isEqual(pathIndex, item.pathIndex)) {
                  return;
                }
                consolelog("default-default-ROW-Table");

                if (
                  !handleMovabilityHorizontal(
                    dragIndex,
                    hoverIndex,
                    hoverClientX,
                    hoverMiddleX
                  ) ||
                  !handleMovabilityVertical(
                    dragIndex,
                    hoverIndex,
                    hoverClientY,
                    hoverMiddleY
                  )
                ) {
                  return;
                }
                // consoledebug({
                //   dragIndex,
                //   hoverIndex,
                //   hoverClientX,
                //   hoverMiddleX,
                // });

                // 判斷 item 是在 Table 外還是內
                if (IsPathIdInTable(item.pathIndex)) {
                  moveTableChildren(item.item.id, item.pathIndex, pathIndex);
                  item.pathIndex = pathIndex.concat(
                    item.pathIndex.slice(pathIndex.length)
                  );
                } else {
                  moveChildren(item.item.id, pathIndex);
                  item.pathIndex = pathIndex;
                }

                return;
              } else {
                if (item.pathIndex[1] === pathIndex[1]) {
                  return;
                }

                dragIndex = item.pathIndex[2];
                hoverIndex = pathIndex[2];

                moveChildren(item.item.id, [pathIndex[0], pathIndex[1]]);
                const findParent = getChildrenByPathId([
                  pathIndex[0],
                  pathIndex[1],
                ]);

                if (findParent) {
                  item.pathIndex = [...pathIndex, findParent.components.length];
                } else {
                  item.pathIndex = pathIndex;
                }
              }

              return;
            }
            case ItemTypesEnum.TABLE: {
              consolelog("default-TABLE");
              return;
            }
            default: {
              consolelog("default-default");
              if (isEqual(pathIndex, item.pathIndex)) {
                return;
              }

              consolelog("default-default-isEqual");

              if (
                IsPathIdInTable(item.pathIndex) &&
                IsPathIdInTable(pathIndex)
              ) {
                // table 內的移動
                consolelog("default-default-Table-Table");
                consoledebug({
                  dragIndex,
                  hoverIndex,
                  hoverClientX,
                  hoverMiddleX,
                });

                dragIndex = item.pathIndex[5];
                hoverIndex = pathIndex[5];

                if (
                  !handleMovabilityHorizontal(
                    dragIndex,
                    hoverIndex,
                    hoverClientX,
                    hoverMiddleX
                  )
                ) {
                  return;
                }

                moveTableChildren(item.item.id, item.pathIndex, pathIndex);
                item.pathIndex = pathIndex;
              } else {
                dragIndex = item.pathIndex[2];
                hoverIndex = pathIndex[2];

                if (dragIndex === hoverIndex) {
                  return;
                }

                if (
                  !handleMovabilityHorizontal(
                    dragIndex,
                    hoverIndex,
                    hoverClientX,
                    hoverMiddleX
                  )
                ) {
                  return;
                }

                moveChildren(item.item.id, pathIndex.slice(0, -1), hoverIndex);
                item.pathIndex = [pathIndex[0], pathIndex[1], hoverIndex];
              }

              return;
            }
          }
          return;
        }
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
    },
    300
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    canDrag,
    item: { id, item: originalItem, pathIndex },
    // item: () => {
    //   return { id, index };
    // },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    end: (item: any, monitor) => {
      const didDrop = monitor.didDrop();

      switch (item.item.type) {
        case ItemTypesEnum.DRAGAREA: {
          consolelog("end", "DRAGAREA");
          break;
        }
        // 1. Page 物件
        case ItemTypesEnum.PAGE: {
          switch (itemType) {
            case ItemTypesEnum.DRAGAREA: {
              consolelog("end", "PAGE-DRAGAREA");
              break;
            }
            case ItemTypesEnum.PAGE: {
              consolelog("end", "PAGE-PAGE");
              if (!didDrop) {
                // removeFirstLayerById(item.item.id);
              } else {
                // updateFirstLayerId(item.item.id, uuidv4());
              }
              break;
            }
            case ItemTypesEnum.ROW: {
              consolelog("end", "PAGE-ROW");
              break;
            }
            default: {
              consolelog("end", "PAGE-default");
              break;
            }
          }
          break;
        }

        // 2. Row 物件
        case ItemTypesEnum.ROW: {
          switch (itemType) {
            case ItemTypesEnum.DRAGAREA: {
              consolelog("end", "ROW-DRAGAREA");
              break;
            }
            case ItemTypesEnum.PAGE: {
              consolelog("end", "ROW-PAGE");
              break;
            }
            case ItemTypesEnum.ROW: {
              consolelog("end", "ROW-ROW");
              if (!didDrop) {
                // removeSecondLayerById(item.item.id);
              } else {
                // updateSecondLayerId(item.item.id, uuidv4());
              }
              break;
            }
            default: {
              consolelog("end", "ROW-default");
              break;
            }
          }
          break;
        }

        // 3. Item 物件
        default: {
          switch (itemType) {
            case ItemTypesEnum.DRAGAREA: {
              consolelog("end", "default-DRAGAREA");
              break;
            }
            case ItemTypesEnum.PAGE: {
              consolelog("end", "default-PAGE");
              break;
            }
            case ItemTypesEnum.ROW: {
              consolelog("end", "default-ROW");
              break;
            }
            default: {
              consolelog("end", "default-default");
              if (!didDrop) {
                // 沒有拖曳到畫面上
                // removeChildren(item.item.id);
              } else {
                // 更新ID
                // updateChildrenId(item.item.id, uuidv4());
              }

              // 更新 tempRow Id or 刪除 components === 0 的 tempRow
              updateTempSecondLayerId();
              // updateFirstLayerId(tempRowId, uuidv4());
              updateTempFirstLayerId();
              // removeEmptySecondLayers();
              break;
            }
          }
          break;
        }
      }

      // setDraggingItemId(null); // 拖動結束時清除
    },
  });

  const [{ handlerId }, drop] = useDrop<
    any,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: AllItemKeys,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(
      item: {
        id: string;
        item: any;
        pathIndex: number[];
        isIndependent?: boolean;
      },
      monitor
    ) {
      if (
        !dropRef.current ||
        !item ||
        item?.item?.type === ItemTypesEnum.DRAGAREA ||
        item.isIndependent
        // isEqual(pathIndex, item.pathIndex)
      ) {
        return;
      }

      // 設定目前拖動的物件 ID
      // if (id !== draggingItemId) {
      //   setDraggingItemId(id);
      // }

      if (monitor.isOver({ shallow: true })) {
        handleHover(item, monitor);
      }
      return;
    },
  });

  // const { currentOffset } = useDragLayer((monitor) => ({
  //   isDragging: monitor.isDragging(),
  //   currentOffset: monitor.getClientOffset(),
  // }));

  // useEffect(() => {
  //   if (!isDragging || !currentOffset) return;

  //   const handleScroll = () => {
  //     const { y } = currentOffset;
  //     const scrollMargin = 50; // 距離頁面頂部或底部多少像素時開始滾動
  //     const scrollSpeed = 10; // 滾動速度（像素）

  //     if (y < scrollMargin) {
  //       // 向上滾動
  //       window.scrollBy(0, -scrollSpeed);
  //     } else if (window.innerHeight - y < scrollMargin) {
  //       // 向下滾動
  //       window.scrollBy(0, scrollSpeed);
  //     }
  //   };

  //   const interval = setInterval(handleScroll, 50); // 每 50ms 檢查一次
  //   return () => clearInterval(interval); // 清理
  // }, [isDragging, currentOffset]);

  return {
    drag,
    drop,
    preview,
    isDragging,
    handlerId,
    isOtherDragging: false, // draggingItemId !== null && draggingItemId !== id,
  };
};

export interface DragSortItemProps {
  children: React.ReactNode;
  item: any;
  index: number;
}
