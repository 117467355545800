import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgDownIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#BFC1C7"}
      d="M8.803 2.16a.535.535 0 0 0-.813.06L5 6.043 2.01 2.22a.535.535 0 0 0-.813-.06.7.7 0 0 0-.054.909l3.07 3.924a1 1 0 0 0 1.575 0l3.07-3.924a.7.7 0 0 0-.055-.91"
    />
  </svg>
);
export default SvgDownIcon;
