import type { CSSProperties, FC } from "react";
import React, { useCallback, useMemo, useRef, memo } from "react";
import { isEqual } from "lodash";
import { Flipper } from "react-flip-toolkit";
import ItemTypesEnum from "./type/ItemTypes";
import { BaseData, getDefaultItem } from "./type/Interface";
import { FormState } from "./type/AttrTypes";
import { useList } from "./store/listContext";
import { useMoveFormProperty } from "./hook/useMoveFormProperty";
// import DragPreviewLayer from "./DragPreviewLayer";
import Page from "./items/base/Page";
import { FormControl, FormBuilderEmptyState, StepButton } from "./components";
import { AddIcon } from "../assets/icons";

const style: CSSProperties = {
  maxWidth: "210mm",
  height: "100%",
  /* 設定 div 以 A4 比例 (210mm x 297mm) */
  width: "100%",
  paddingBottom: "calc(297mm / 210mm * 100%)",
  boxSizing: "border-box",
  padding: "40px 0px ",
  position: "relative",
  margin: "0 auto",
};

const containerStyle: CSSProperties = {
  position: "relative",
  display: "grid",
  gridTemplateColumns: "minmax(0, 50px) 1fr minmax(0, 50px)",
  justifyContent: "center",
  width: "100%",
};

export const DragArea: FC = memo(() => {
  const {
    state,
    list,
    stepButtons,
    addFirstLayer,
    clearTargetItem,
    pageDefaultSetting,
    customStepButtons,
  } = useList();

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const pages = useMemo(() => {
    return list.filter((v) => v.type === ItemTypesEnum.PAGE);
  }, [list]);

  const dropRef = useRef<HTMLDivElement>(
    null
  ) as React.RefObject<HTMLDivElement>;

  const { drop } = useMoveFormProperty({
    id: "dragArea",
    pathIndex: [],
    item: null,
    itemType: ItemTypesEnum.DRAGAREA,
    dropRef,
    canDrag: false,
  });

  const handleClearTarget = (_event: any) => {
    _event.stopPropagation();
    clearTargetItem();
  };

  const handleAddPage = () => {
    const defaultPageItem = getDefaultItem(
      ItemTypesEnum.PAGE,
      pageDefaultSetting
    );
    addFirstLayer(defaultPageItem.originalItem);
  };

  const buttonsMemo = useMemo(() => {
    if (list.length === 0) return null;
    if (!stepButtons) return null;
    return (
      <div
        className={`flex flex-col gap-2 md:flex-row items-center w-full px-4 pt-8 pb-4 overflow-x-auto`}
      >
        {stepButtons.map((button, index) => (
          <StepButton
            key={index}
            color={button.color}
            label={button.name}
            onClick={button.onClick}
            alignment={button.alignment as "left" | "center" | "right"}
            isMultipleButtons={stepButtons.length > 1}
          />
        ))}
      </div>
    );
  }, [stepButtons]);

  const renderItem = useCallback((item: BaseData, index: number) => {
    return (
      <Page.Item key={item.id} id={item.id} item={item} pathIndex={[index]} />
    );
  }, []);

  drop(dropRef);

  return (
    <div
      style={
        readOnly
          ? {
              display: "flex",
            }
          : containerStyle
      }
    >
      {!readOnly && <FormControl />}
      <div
        ref={dropRef}
        style={{
          ...style,
          padding: readOnly ? "0px" : "40px 0px ",
        }}
        onClick={handleClearTarget}
      >
        {/* <DragPreviewLayer /> */}
        {pages.length > 0 ? (
          <div>
            <Flipper flipKey={pages}>{pages.map(renderItem)}</Flipper>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "8px",
              }}
            >
              {!readOnly && (
                <button onClick={handleAddPage}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <AddIcon />
                    <span>Add section</span>
                  </div>
                </button>
              )}
            </div>

            {state !== FormState.CREATE && (
              <>{customStepButtons ?? buttonsMemo}</>
            )}
          </div>
        ) : (
          <FormBuilderEmptyState state={state} />
        )}
      </div>
    </div>
  );
}, isEqual);
