import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgFlowIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M2 2.667h1.447a2 2 0 0 1 3.772 0h4.447c1.697 0 3 1.265 3 3 0 1.734-1.303 3-3 3H4.333c-.97 0-1.666.679-1.666 1.666 0 .988.696 1.667 1.666 1.667H8.78a2 2 0 0 1 3.773 0H14a.667.667 0 1 1 0 1.334h-1.447a2 2 0 0 1-3.773 0H4.333c-1.696 0-3-1.266-3-3 0-1.735 1.304-3 3-3h7.333c.97 0 1.667-.68 1.667-1.667 0-.988-.696-1.667-1.667-1.667H7.22a2 2 0 0 1-3.772 0H2a.667.667 0 1 1 0-1.333m3.333 0a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333m6 10a.667.667 0 1 0-1.333 0 .667.667 0 0 0 1.333 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFlowIcon;
