import type { CSSProperties, FC } from "react";
import React, { useState, memo } from "react";
import { useList } from "./store/listContext";
import { Tabs, TabFields, TabCurrentFields, TabPages } from "./components";
import {
  convertTest,
  getDefautlHeaderData,
  getDefautlEndData,
} from "../utils/conversion";

const style: CSSProperties = {
  // boxSizing: "border-box",
  // border: "1px solid black",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  minHeight: 0,
  flex: 1,
};

const jsonStyle: CSSProperties = {
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  overflow: "auto",
};

const buttonStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2px 5px",
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Roboto', sans-serif",
  borderRadius: "6px",
  border: "none",
  color: "#fff",
  background: "linear-gradient(180deg, #4B91F7 0%, #367AF6 100%)",
  backgroundOrigin: "border-box",
  boxShadow:
    "0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2)",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
};

const formGroupStyle: CSSProperties = {
  position: "relative",
  display: "flex",
  width: "100%",
  maxWidth: "360px",
  marginBottom: "32px",
};
const formFieldStyle: CSSProperties = {
  display: "block",
  width: "100%",
  padding: "2px 5px",
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "inherit",
  borderRadius: "6px",
  WebkitAppearance: "none",
  color: "#99A3BA",
  border: "1px solid #CDD9ED",
  background: "#fff",
  transition: "border 0.3s ease",
  outline: "none",
  borderColor: "#275EFE",
};
const spanStyle: CSSProperties = {
  textAlign: "center",
  padding: "2px 4px",
  fontSize: "14px",
  color: "#99A3BA",
  background: "#EEF4FF",
  border: "1px solid #CDD9ED",
  transition: "background 0.3s ease, border 0.3s ease, color 0.3s ease",
};

const DragItemList: FC = () => {
  const { list, setList, showList } = useList();
  const [temps, setTemps] = useState<string>("[]");

  const tebList = [
    {
      key: "Fields",
      tab: <TabFields />,
    },
    {
      key: "Current Fields",
      tab: <TabCurrentFields />,
    },
    {
      key: "Sections",
      tab: <TabPages />,
    },
  ];

  if (showList) {
    tebList.push({
      key: "Form Data",
      tab: (
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              gap: 6,
            }}
          >
            <div>
              <button
                style={buttonStyle}
                onClick={() =>
                  navigator.clipboard.writeText(JSON.stringify(list, null, 2))
                }
              >
                Copy
              </button>
            </div>

            <div style={formGroupStyle}>
              <input
                type="text"
                style={formFieldStyle}
                value={temps}
                onChange={(e: any) => setTemps(e.target.value)}
              />
              <span
                style={spanStyle}
                onClick={() => {
                  if (!temps) return;
                  try {
                    setList(JSON.parse(temps));
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                Change
              </span>
            </div>

            <div>
              <button
                style={buttonStyle}
                onClick={() => {
                  const _d = convertTest();
                  console.log(_d);
                  setList(_d);
                }}
              >
                Get conversion data
              </button>
            </div>

            <div>
              <button
                style={buttonStyle}
                onClick={() => {
                  const _s = getDefautlHeaderData();
                  const _d = getDefautlEndData();
                  setList((preList: any) => {
                    const newList = structuredClone(preList);
                    newList[0].components = [
                      ..._s,
                      ...newList[0].components,
                      ..._d,
                    ];
                    return newList;
                  });
                }}
              >
                Add default data
              </button>
            </div>
          </div>
          <hr />
          <div style={jsonStyle}>{JSON.stringify(list, null, 2)}</div>
        </div>
      ),
    });
  }

  return (
    <div style={style}>
      <div
        style={{
          flex: 1,
          minHeight: 0,
        }}
      >
        <Tabs list={tebList} />
      </div>
    </div>
  );
};

export default memo(DragItemList);
