import type { CSSProperties, FC } from "react";
import React from "react";
import {
  renderCustomComponent,
  inputTableAddRow,
  inputTableAddColumn,
  inputTableUpdateCell,
  inputTableChangeType,
  inputTableChangeAllType,
  inputTableDeleteRow,
  inputTableDeleteColumn,
} from "../utils/methods";
import { useList } from "./store/listContext";
import { tableChildrenKey } from "./type/AttrTypes";
import ItemTypes, {
  ItemComponents,
  TableData,
  InputTableEnum,
} from "./type/ItemTypes";
import { ComponentAndSettingProps } from "./items/base/CommonItemHOC";
import { DraggableRow, DraggableColumn } from "./items/tableComponents";

const style: CSSProperties = {
  height: "100%",
  flex: 1,
  minHeight: 0,
  // minWidth: "300px",
  // boxSizing: "border-box",
  // border: "1px solid black",
};

const DragSettingArea: FC = () => {
  const {
    state,
    targetItem,
    targetCell,
    customItemList,
    updateChildrenValue,
    updateChildrenAttr,
    updateChildrensAttr,
    updateSecondLayersAttr,
    updateFirstLayerAttr,
    getChildrenByPathId,
    recursionFindChildrenPathById,
  } = useList();

  if (!targetItem.item) {
    return <div></div>;
  }
  const id = targetItem.id;

  const findItem = recursionFindChildrenPathById(id);
  const parentItem = findItem
    ? getChildrenByPathId(findItem?.pathId.slice(0, -1))
    : null;

  const item = findItem?.originalItem;
  const itemType = findItem?.originalItem.type;

  const handleOnChangeAllRowsAttr = (e: any) => {
    if (parentItem) {
      updateFirstLayerAttr(e);
    }
    updateSecondLayersAttr(e);
  };

  const inputTableProps = {
    DraggableRow,
    DraggableColumn,
    targetCell,
    onAddRow: (attrs: any[]) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableAddRow(item.components as TableData, attrs);
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onAddColumn: (attrs: any[]) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableAddColumn(item.components as TableData, attrs);
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onUpdateCell: (rowIndex: number, colIndex: number, attr: any) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableUpdateCell(
        item.components as TableData,
        rowIndex,
        colIndex,
        attr
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onChangeType: (colIndex: number, type: InputTableEnum) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableChangeType(
        item.components as TableData,
        colIndex,
        type
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onChangeAllType: (type: InputTableEnum) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableChangeAllType(
        item.components as TableData,
        type
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onDeleteRow: (index: number) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableDeleteRow(item.components as TableData, index);
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onDeleteColumn: (index: number) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableDeleteColumn(
        item.components as TableData,
        index
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
  };

  const newProps: ComponentAndSettingProps = {
    state,
    parent: parentItem,
    data: findItem?.originalItem,
    onChange: (e: any) => updateChildrenValue(id, e),
    onChangeAttr: (e: any) => updateChildrenAttr(id, e),
    onChangeAllItemsAttr: (e: any) => updateChildrensAttr(e),
    onChangeAllRowsAttr: handleOnChangeAllRowsAttr,
    // isCurrentStep: isCurrentStep,
    tableProps: null,
    inputTableProps: inputTableProps,
  };

  const ComponentMemo = customItemList?.[targetItem.item.type]
    ? renderCustomComponent(
        customItemList,
        targetItem.item.type,
        "Setting",
        newProps
      )
    : null;

  return (
    <div style={style}>
      {ComponentMemo
        ? ComponentMemo
        : React.createElement(
            ItemComponents[targetItem.item.type].Setting,
            newProps
          )}
    </div>
  );
};

export default DragSettingArea;
