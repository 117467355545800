import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgDateTimeIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#E89B09"}
      d="M9.593 10.446v.887H6.667v-.74q.34-.273.646-.527.307-.26.687-.606.186-.167.273-.36a.9.9 0 0 0 .087-.367.41.41 0 0 0-.087-.28Q8.2 8.38 7.5 8.38l-.447.006a3 3 0 0 1-.213.007 4 4 0 0 1-.047-.367 6 6 0 0 1-.02-.406 7.4 7.4 0 0 1 1.254-.134q.68 0 1.08.26.406.255.406.88 0 .3-.153.62-.153.315-.6.66-.3.234-.787.554z"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#E89B09"}
      fillRule="evenodd"
      d="M5.333 1.333c.368 0 .667.298.667.667h4a.667.667 0 0 1 1.333 0h2c.737 0 1.334.597 1.334 1.333v9.333c0 .737-.597 1.334-1.334 1.334H2.667a1.333 1.333 0 0 1-1.334-1.334V3.333C1.333 2.597 1.93 2 2.667 2h2c0-.369.298-.667.666-.667m-.666 2h-2v2h10.666v-2h-2V4A.667.667 0 0 1 10 4v-.667H6V4a.667.667 0 0 1-1.333 0zm8.666 3.333H2.667v6h10.666z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDateTimeIcon;
