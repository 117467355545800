import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgSingleSelectionIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#A96ECE"}
      fillRule="evenodd"
      d="M1.333 8a6.667 6.667 0 1 1 13.334 0A6.667 6.667 0 0 1 1.333 8M8 2.666a5.333 5.333 0 1 0 0 10.667A5.333 5.333 0 0 0 8 2.666"
      clipRule="evenodd"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#A96ECE"}
      fillRule="evenodd"
      d="M10.667 8a2.667 2.667 0 1 0-5.334 0 2.667 2.667 0 0 0 5.334 0M8 6.666a1.333 1.333 0 1 1 0 2.667 1.333 1.333 0 0 1 0-2.667"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSingleSelectionIcon;
