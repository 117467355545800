import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgSettingIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M6.99 2.956A1.09 1.09 0 0 1 8.072 2h.856c.55 0 1.014.41 1.082.956l.065.522q.263.09.51.21l.414-.322a1.09 1.09 0 0 1 1.441.09l.605.605a1.09 1.09 0 0 1 .09 1.44l-.323.416q.12.246.21.507l.522.066c.546.068.956.532.956 1.082v.855c0 .55-.41 1.015-.956 1.083l-.521.065q-.094.27-.218.522l.256.329a1.09 1.09 0 0 1-.09 1.44l-.604.606a1.09 1.09 0 0 1-1.441.09l-.33-.256a5 5 0 0 1-.52.217l-.066.521A1.09 1.09 0 0 1 8.928 14h-.856a1.09 1.09 0 0 1-1.082-.956l-.065-.521a5 5 0 0 1-.509-.211l-.415.323a1.09 1.09 0 0 1-1.441-.09l-.605-.604a1.09 1.09 0 0 1-.09-1.442l.323-.415a5 5 0 0 1-.21-.509l-.522-.065A1.09 1.09 0 0 1 2.5 8.427v-.855c0-.55.41-1.014.956-1.082l.522-.066a5 5 0 0 1 .199-.484l-.446-.573a1.09 1.09 0 0 1 .09-1.442l.604-.604a1.09 1.09 0 0 1 1.441-.09l.574.446q.236-.113.485-.2zm1.938.135h-.856L7.918 4.33l-.358.094q-.482.127-.905.372l-.318.183-1.14-.887-.605.605.887 1.14-.183.318c-.162.28-.289.585-.373.905l-.093.357-1.24.155v.855l1.24.155.093.357q.13.495.384.925l.186.32-.767.985.605.605.986-.767.319.187q.43.252.924.383l.358.093.154 1.24h.856l.154-1.24.358-.093c.332-.087.646-.22.935-.39l.319-.188.901.701.605-.604-.701-.902.188-.32c.17-.288.303-.602.39-.935l.093-.357 1.24-.155v-.855l-1.24-.155-.094-.357a3.7 3.7 0 0 0-.383-.924l-.186-.318.767-.986-.605-.605-.985.767-.32-.187a3.7 3.7 0 0 0-.924-.383l-.357-.094zM8.5 6.364a1.636 1.636 0 1 0 0 3.272 1.636 1.636 0 0 0 0-3.272M5.773 8a2.727 2.727 0 1 1 5.454 0 2.727 2.727 0 0 1-5.454 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSettingIcon;
