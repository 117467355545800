import type { FC } from "react";
import React, { memo } from "react";

const InputTableTextInput: FC<any> = (props) => {
  const { item, onChange, readOnly } = props;

  const handleChange = (e: any) => {
    onChange({ value: e.target.value });
  };

  return (
    <div>
      <input
        type="text"
        value={item?.value || ""}
        onChange={handleChange}
        style={{ width: "100%" }}
        disabled={readOnly}
      />
    </div>
  );
};

export default memo(InputTableTextInput);
