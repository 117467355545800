import ItemTypesEnum from "./ItemTypes";
import { v4 as uuidv4 } from "uuid";

export interface DropItem {
  originalIndex: number;
  originalItem: BaseContainerData | any;
}

export interface BaseData {
  id: string;
  type: string;
  components: BaseData[] | BaseItemData[] | any[];
  setting?: {
    steps?: any[];
    [key: string]: any;
  };
}

export interface FindParentItem {
  index: number;
  item?: BaseContainerData;
}

export interface FindSecondContainer {
  parentIndex: number;
  parentId: string;
  index: number;
  item?: BaseData;
}

export interface SearchItem extends DropItem {
  pathId: number[];
}

export interface FindItem {
  rowIndex: number;
  rowId: string;
  index: number;
  item?: BaseItemData;
}

export interface BaseContainerData extends BaseData {
  class?: string;
  height?: number;
  childSetting?: any;
  name?: string;
}

export interface BaseItemData extends BaseData {
  class?: string;
  value: any;
  width?: number;
  height?: number;
  // title: string;
  // placeholder: string;
  visible: boolean;
  required: boolean;
  readonly: boolean;
  // size: number;
  // max: number;
  // min: number;
  // step: number;
}

export const tempPageId = "tempPage";
export const tempRowId = "tempRow";

export function getDefaultItem(type: string, attr: any = {}): DropItem {
  const item = {
    id: uuidv4(),
    name: "Untitled",
    class: "",
    type: type, // ItemTypes.ROW || PAGE,
    childSetting: attr,
    components: [],
  };
  return { originalIndex: -1, originalItem: item };
}

export function generateDefaultItem(type: string, attr: any = {}): any {
  const item = {
    id: uuidv4(),
    class: "",
    type: type, // ItemTypes.ROW || PAGE,
    childSetting: attr,
    components: [],
  };
  return item;
}

export function getDefaultChildItem(
  defaultItem: any,
  attr: any = {}
): SearchItem {
  const child = {
    id: uuidv4(),
    class: "",
    value: "",
    visible: false,
    required: false,
    readonly: false,
    ...defaultItem,
    ...attr,
  };
  return {
    pathId: [],
    originalIndex: -1,
    originalItem: child,
  };
}

export function isDropPageItem(item: DropItem | SearchItem): item is DropItem {
  return (item as DropItem).originalItem.type === ItemTypesEnum.PAGE;
}

export function isDropRowItem(item: DropItem | SearchItem): item is DropItem {
  return (item as DropItem).originalItem.type === ItemTypesEnum.ROW;
}

export function isDropChildItem(item: DropItem | SearchItem): item is DropItem {
  return (
    (item as SearchItem).originalItem.type !== ItemTypesEnum.PAGE &&
    (item as SearchItem).originalItem.type !== ItemTypesEnum.ROW
  );
}
