import type { FC } from "react";
import React, { useMemo, memo } from "react";
import { useList } from "../../store/listContext";
import { FormState } from "../../type/AttrTypes";

const InputTableCellHead: FC<any> = (props) => {
  const { item, onChange, defaultValue = "" } = props;
  const { state } = useList();

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const handleChange = (e: any) => {
    onChange({ title: e.target.value });
  };

  return (
    <div>
      <input
        type="text"
        value={item.title || defaultValue}
        onChange={handleChange}
        style={{ width: "100%" }}
        disabled={readOnly}
      />
    </div>
  );
};

export default memo(InputTableCellHead);
