import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgImageIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#7A9BFF"}
      d="M5.9 7.2a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#7A9BFF"}
      fillRule="evenodd"
      d="M2 4.2A1.2 1.2 0 0 1 3.2 3h9.6A1.2 1.2 0 0 1 14 4.2v7.2a1.2 1.2 0 0 1-1.2 1.2H3.2A1.2 1.2 0 0 1 2 11.4zm10.8 0v5.774L10.32 6.55a1.2 1.2 0 0 0-1.943 0l-1.68 2.32-.171-.233a1.2 1.2 0 0 0-1.937 0L3.2 10.535V4.2zM9.348 7.254l3.003 4.146H4.054l1.504-2.055.657.898a.6.6 0 0 0 .97-.002z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgImageIcon;
