import React from "react";
import { v4 as uuidv4 } from "uuid";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import get from "lodash/get";

import {
  BaseContainerData,
  BaseItemData,
  SearchItem,
} from "../form/type/Interface";
import ItemTypesEnum, {
  TableData,
  InputTableEnum,
} from "../form/type/ItemTypes";
import { CustomItemList } from "../form/DragContainer";

import FlowTypesEnum from "../flow/type/ItemTypes";

/**
 * 檢查資料結構是否正確
 */
export const validateDataStructure = (data: BaseContainerData[]): boolean => {
  if (!isArray(data)) return false;

  for (const page of data) {
    if (!isObject(page) || get(page, "type") !== ItemTypesEnum.PAGE) {
      return false;
    }

    for (const row of page.components) {
      if (!isObject(row) || get(row, "type") !== ItemTypesEnum.ROW) {
        return false;
      }

      // TODO 第三層暫不檢查
      // for (const item of row.components) {
      //   if (!isObject(item)) {
      //     return false;
      //   }
      //   if (!("value" in item)) return false;
      // }
    }
  }

  return true;
};

/**
 * 修正資料結構不正確
 */
export const normalizeData = (
  data: BaseContainerData[],
  defaultSetting: {
    pageDefaultSetting: any;
    rowDefaultSetting: any;
  }
): BaseContainerData[] => {
  // 檢查第一層是否為 `page`，如果不是，包一層 `type = page`
  const fixedData = data.every((item) => item.type === ItemTypesEnum.PAGE)
    ? data
    : [
        {
          id: uuidv4(),
          type: ItemTypesEnum.PAGE,
          childSetting: defaultSetting.pageDefaultSetting || {},
          components: data,
        },
      ];

  // 修正第二層，確保 `page` 的 components 為 `type = row`
  fixedData.forEach((page) => {
    page.components = page.components.map((item) => {
      if (item.type !== ItemTypesEnum.ROW) {
        // 包一層 `type = row`
        return {
          id: uuidv4(),
          type: ItemTypesEnum.ROW,
          childSetting: defaultSetting.rowDefaultSetting || {},
          components: [item],
        };
      }
      return item;
    });
  });

  return fixedData;
};

/**
 * 檢查 data 中是否有重複的 id
 * @param data ListContext 中的資料
 * @returns 沒有發現重複的 id 則回傳 true，否則回傳 false
 */
export const checkDuplicateIdsOptimized = (
  data: BaseContainerData[]
): boolean => {
  const ids = new Set<string>();

  // 遞迴遍歷所有層級的物件
  function traverse(itemList: BaseContainerData[]): boolean {
    for (const item of itemList) {
      // 檢查當前物件的 id 是否重複
      if (item.id) {
        if (ids.has(item.id)) {
          // console.error(`Duplicate ID found: ${item.id}`);
          return false; // 發現重複 id，提前退出
        }
        ids.add(item.id);
      }

      // TODO Table 裡面的物件

      // 如果有 components，則遞迴檢查
      if (item.components && Array.isArray(item.components)) {
        const result = traverse(item.components);
        if (!result) {
          return false; // 發現重複 id，提前退出
        }
      }
    }
    return true;
  }

  // 開始遞迴檢查
  return traverse(data);
};

/**
 * 檢查 data 中是否有重複的 id
 * @param data
 * @returns
 */
export const findDuplicateIds = (data: BaseContainerData[]): any => {
  const idSet = new Set();
  const duplicates = new Set();

  function traverseAndCollectIds(obj: any) {
    if (Array.isArray(obj)) {
      for (const item of obj) {
        traverseAndCollectIds(item);
      }
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.id) {
        if (idSet.has(obj.id)) {
          duplicates.add(obj.id); // 如果已經存在，標記為重複
        } else {
          idSet.add(obj.id); // 否則加入集合
        }
      }

      // 遞歸處理其餘屬性
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          traverseAndCollectIds(obj[key]);
        }
      }
    }
  }

  // 開始遍歷
  traverseAndCollectIds(data);

  return {
    hasDuplicates: duplicates.size > 0,
    duplicateIds: Array.from(duplicates),
  };
};

export const checkNotDuplicateIds = (data: BaseContainerData[]): boolean => {
  const idSet = new Set();
  let isUnique = true;

  function traverseAndCheckIds(obj: any) {
    if (Array.isArray(obj)) {
      for (const item of obj) {
        traverseAndCheckIds(item);
      }
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.id) {
        if (idSet.has(obj.id)) {
          isUnique = false; // 發現重複 ID
          return; // 提早結束檢查
        }
        idSet.add(obj.id);
      }

      // 遞歸處理其餘屬性
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (["components"].includes(key)) {
            traverseAndCheckIds(obj[key]);
          }
        }
      }
    }
  }

  // 開始遍歷
  traverseAndCheckIds(data);
  return isUnique;
};

/**
 * 檢查路徑是否超過 4 個
 * 目前結構為 components => components => components => [[ROW.components],[ROW.components]]
 * @param pathId number[]
 * @returns boolean
 */
export const IsPathIdInTable = (pathId: number[]): boolean => {
  return pathId.length > 4;
};

/**
 * 遞歸複製物件並替換 ID
 * @param obj
 * @returns
 */
export const deepCopyWithNewId = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopyWithNewId(item));
  }

  if (typeof obj === "object" && obj !== null) {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (key.toLocaleLowerCase() === "steps") {
        // steps 裡面的 id 不變更
      } else if (
        !["id", "groupid"].includes(key.toLocaleLowerCase()) &&
        Object.prototype.hasOwnProperty.call(newObj, key)
      ) {
        newObj[key] = deepCopyWithNewId(newObj[key]);
      } else if (["id", "groupid"].includes(key.toLocaleLowerCase())) {
        newObj[key] = uuidv4();
      }
    }
    return newObj;
  }

  return obj;
};

/**
 * 渲染自訂元件
 * @param customItemList
 * @param type
 * @param key
 * @param props
 * @returns
 */
export const renderCustomComponent = (
  customItemList: CustomItemList,
  type: string,
  key: "Component" | "Setting" | "DailogComponent",
  props: any
) => {
  const customItem = customItemList[type];

  // 如果無對應的類型或鍵，返回占位元件，確保結構一致 (改從各個 Item 判斷)
  if (!customItem || !customItem[key]) {
    return null;
  }

  const Element = customItem[key] as any;

  // 使用 createElement 統一處理
  try {
    return React.createElement(Element, props);
  } catch (error) {
    console.error("Error rendering component:", error);
    return React.createElement("div", null, "");
  }
};

/**
 * 渲染自訂元件
 * @param customItemList
 * @param type
 * @param key
 * @param props
 */
export const renderCustomItemSetting = (
  customItemList: CustomItemList | undefined,
  type: string,
  key:
    | "onCopy"
    | "onSetting"
    | "onDelete"
    | "onMenu"
    | "onPage"
    | "onUndo"
    | "onRedo"
    | "onWorkflows"
    | "onRowDuplicate"
    | "onColumnDuplicate"
    | "onRowAssignFieldsToSteps"
    | "onRowDelete"
    | "onColumnDelete"
    | "onColumnAssignFieldsToSteps",
  props?: any
) => {
  if (customItemList && typeof customItemList?.[type]?.[key] === "function") {
    customItemList[type][key](props);
  }
};

export const generateEdge = (
  sourceId: string,
  targetId: string,
  sourceType: string,
  targetIdType: string
) => {
  return {
    id: uuidv4(),
    type: "smoothstep",
    source: sourceId,
    target: targetId,
    sourceHandle: `${sourceType}Out`,
    targetHandle: `${targetIdType}In`,
    animated: !true, // targetIdType !== ItemTypes.SENDEMAIL,
  };
};

/**
 *
 * @param id 連接的來源 id
 * @param sourceType 連接的來源 Handle Type
 * @param attr 新增 Node 的 Data
 * @param position 新增 Node 的 Position
 * @returns node 和 edge
 */
export const generateNodeAndEdge = (
  id: string,
  sourceType: string,
  targetType: string,
  attr: any,
  position: { x: number; y: number }
) => {
  const _id = uuidv4();
  const node = {
    id: _id,
    type: targetType,
    position: {
      x: position.x,
      y: position.y,
    },
    data: {
      ...(attr || {}),
    },
    origin: [0.5, 0.0],
  };

  const edge = generateEdge(id, _id, sourceType, targetType);

  return { node, edge };
};

export const generateDefaultNodeAndEdge = (
  formData: BaseContainerData[] | any[],
  customItemList?: CustomItemList
): { node: any[]; edge: any[] } => {
  const newNode = [];
  const newEdge = [];
  const newButtonNode = [];
  for (let index = 0; index < formData.length; index++) {
    const stepId = uuidv4();
    const step = {
      id: stepId,
      type: FlowTypesEnum.STEP,
      position: {
        x: 400 * index,
        y: 0,
      },
      data: {
        ...(customItemList?.[FlowTypesEnum.STEP]?.["defaultSetting"] || {}),
      },
      origin: [0.5, 0.0],
    };
    newNode.push(step);

    if (newButtonNode.length !== 0) {
      const previousButtonNode = newButtonNode[newButtonNode.length - 1];
      newEdge.push(
        generateEdge(
          previousButtonNode.id,
          step.id,
          previousButtonNode.type,
          step.type
        )
      );
    }

    const newNodeAndEdge = generateNodeAndEdge(
      step.id,
      step.type,
      FlowTypesEnum.BUTTON,
      customItemList?.[FlowTypesEnum.BUTTON]?.["defaultSetting"] || {},
      {
        x: step.position.x + 200,
        y: step.position.y + 250,
      }
    );
    newButtonNode.push(newNodeAndEdge.node);
    newEdge.push(newNodeAndEdge.edge);

    if (index === formData.length - 1) {
      const lastStepNode = newButtonNode[newButtonNode.length - 1];
      const newCloseNodeAndEdge = generateNodeAndEdge(
        lastStepNode.id,
        lastStepNode.type,
        FlowTypesEnum.CLOSE,
        customItemList?.[FlowTypesEnum.CLOSE]?.["defaultSetting"] || {},
        {
          x: lastStepNode.position.x + 250,
          y: lastStepNode.position.y + 0,
        }
      );
      newNode.push(newCloseNodeAndEdge.node);
      newEdge.push(newCloseNodeAndEdge.edge);
    }
  }

  return { node: [...newNode, ...newButtonNode], edge: newEdge };
};

/**
 * 1. 每條 step 的結尾只會是 CLOSE, BACKTOPREVIOUS, CREATENEWVERSION
 * 2. 每個 sendEmail nodes 要有其他 stpe
 * 3. BUTTON, BACKTOPREVIOUS, CREATENEWVERSION 必須連到一個 step
 * 4. 是否有 CLOSE 存在
 */
export const validateFlowData = (_nodes: any[], _edges: any[]) => {
  // Rule 1.

  // Rule 2.
  const allSendEmail = _edges.filter(
    (v) => v.targetHandle === `${FlowTypesEnum.SENDEMAIL}In`
  );
  const allButtonToSendEmail = _edges.filter((v) =>
    allSendEmail.map((vv) => vv.source).includes(v.source)
  );
  const isAllSendEmail =
    allSendEmail.length * 2 === allButtonToSendEmail.length;

  // Rule 3. BUTTON
  const allButton = _nodes
    .filter((v) => v.type === FlowTypesEnum.BUTTON)
    .map((v) => v.id);
  const isAllButtonClose = allButton.every((v) => {
    const buttonEdged = _edges.filter(
      (vv) =>
        v === vv.source && vv.targetHandle !== `${FlowTypesEnum.SENDEMAIL}In`
    );
    return !(buttonEdged.length === 0);
  });

  // Rule 3. BACKTOPREVIOUS
  const isAllBackToPreviousClose =
    _nodes.filter((v) => v.type === FlowTypesEnum.BACKTOPREVIOUS).length ===
    _edges.filter(
      (v) => v.sourceHandle === `${FlowTypesEnum.BACKTOPREVIOUS}Out`
    ).length;

  // Rule 3. CREATENEWVERSION
  const isAllCreateNewVersionClose =
    _nodes.filter((v) => v.type === FlowTypesEnum.CREATENEWVERSION).length ===
    _edges.filter(
      (v) => v.sourceHandle === `${FlowTypesEnum.CREATENEWVERSION}Out`
    ).length;

  // Rule 4. CLOSE
  const isAllClose =
    _nodes.filter((v) => v.type === FlowTypesEnum.CLOSE).length > 0;

  return {
    isAllSendEmail,
    isAllButtonClose,
    isAllBackToPreviousClose,
    isAllCreateNewVersionClose,
    isAllClose,
  };
};

// 結構比較
export const isStructureDifferent = (obj1: any, obj2: any): boolean => {
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    // 比較陣列長度
    if (obj1.length !== obj2.length) return true;
    // 遞迴比較每個元素
    return obj1.some((item, index) => isStructureDifferent(item, obj2[index]));
  }

  if (typeof obj1 === "object" && typeof obj2 === "object") {
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    // 比較屬性數量
    if (keys1.length !== keys2.length) return true;

    // 比較每個屬性名稱與子結構
    return keys1.some((key) => {
      if (!keys2.includes(key)) return true;
      return isStructureDifferent(obj1[key], obj2[key]);
    });
  }

  // 其他類型不需要比較結構，視為不變
  return false;
};

export const parseAndIncrement = (input: string): string => {
  const num = Number(input);

  // 判斷是否為有效數字（排除 NaN 和 Infinity）
  if (!isNaN(num) && isFinite(num)) {
    return (num + 1).toString();
  }

  return input;
};

// InputTable methods
/**
 *
 * @param tableData
 * @param attrs [[{ title: 'Col' }, { value: "test" }], [{},{}]]
 * @returns
 */
export const inputTableAddRow = (tableData: TableData, attrs: any[] = []) => {
  const newTable = structuredClone(tableData);
  if (attrs.length === 0) {
    const newRow = new Array(tableData[0].length).fill({});
    const lastRow = tableData[tableData.length - 1];
    for (let index = 0; index < newRow.length; index++) {
      newRow[index] = {
        id: uuidv4(),
        ...(index === 0
          ? { title: parseAndIncrement(lastRow[index].title || "Row") }
          : { value: lastRow[index]?.value || "" }),
        type: lastRow[index]?.type,
      };
    }
    newTable.push(newRow);
  } else {
    attrs.forEach((attr) => {
      const newRow = new Array(tableData[0].length).fill({});
      for (let index = 0; index < newRow.length; index++) {
        newRow[index] = {
          id: uuidv4(),
          ...(index === 0 ? { title: "Row" } : { value: "" }),
          type: tableData?.[tableData.length - 1]?.[index]?.type,
          ...(attr?.[index] || {}),
        };
      }
      newTable.push(newRow);
    });
  }
  return newTable;
};

/**
 *
 * @param tableData
 * @param attrs [{ title: 1 }, {}]
 * @returns
 */
export const inputTableAddColumn = (
  tableData: TableData,
  attrs: any[] = [{}]
) => {
  const newTable = structuredClone(tableData);
  newTable.forEach((row, index) =>
    row.push(
      ...attrs.map((attr) => ({
        id: uuidv4(),
        type: row?.[row.length - 1]?.type || InputTableEnum.TEXTINPUT,
        ...(index === 0 ? { title: "Col" } : { value: "" }),
        ...(attr || {}),
      }))
    )
  );
  return newTable;
};

export const inputTableUpdateCell = (
  tableData: TableData,
  rowIndex: number,
  colIndex: number,
  attr: any
) => {
  const newTable = structuredClone(tableData);
  newTable[rowIndex][colIndex] = {
    ...newTable[rowIndex][colIndex],
    ...attr,
  };
  return newTable;
};
export const inputTableChangeType = (
  tableData: TableData,
  colIndex: number,
  type: InputTableEnum
) => {
  const newTable = structuredClone(tableData);
  newTable.forEach((row: any[]) => {
    row[colIndex].type = type;
  });
  return newTable;
};

export const inputTableChangeAllType = (
  tableData: TableData,
  type: InputTableEnum
) => {
  const newTable = structuredClone(tableData);
  newTable.forEach((row: any[]) => {
    row.forEach((col: any) => {
      col.type = type;
    });
  });
  return newTable;
};
export const inputTableDeleteRow = (tableData: TableData, index: number) => {
  const newTable = structuredClone(tableData);
  newTable.splice(index || newTable.length - 1, 1);
  return newTable;
};
export const inputTableDeleteColumn = (tableData: TableData, index: number) => {
  const newTable = structuredClone(tableData);
  newTable.forEach((row: any[]) => {
    row.splice(index, 1);
  });
  return newTable;
};
