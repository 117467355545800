import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgUploadIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#E89B09"}
      fillRule="evenodd"
      d="M4 4a.667.667 0 0 0-.667.667v4.667c0 .368.299.666.667.666h.667a.667.667 0 0 1 0 1.334H4a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4.667a2 2 0 0 1-2 2h-.667a.667.667 0 0 1 0-1.334H12a.667.667 0 0 0 .667-.666V4.667A.667.667 0 0 0 12 4zM7.529 5.53c.26-.26.682-.26.942 0l2 2a.667.667 0 1 1-.942.943l-.862-.862v5.724a.667.667 0 0 1-1.334 0V7.61l-.862.862a.667.667 0 1 1-.942-.943z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUploadIcon;
