import type { CSSProperties, FC } from "react";
import React, { useState, useRef, memo } from "react";
import { OtherSettingTypesEnum } from "../type/ItemTypes";
import { useList } from "../store/listContext";

import { renderCustomComponent } from "../../utils/methods";
import { icons } from "../../assets";
const { DeleteIcon } = icons;

const deleteHandleStyle: CSSProperties = {};

const confirm_box: CSSProperties = {
  position: "absolute",
  backgroundColor: "white",
  border: "1px solid #ccc",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  padding: 2,
  zIndex: 9999,
};

const confirm_buttons: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  gap: 2,
};

const confirm_text: CSSProperties = { padding: 0, margin: 0 };

export interface DeleteConfirmProps {
  onClick: () => void;
}

export const DeleteConfirm: FC<DeleteConfirmProps> = (props) => {
  const { customItemList } = useList();

  const { onClick } = props;

  const [showConfirm, setShowConfirm] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const handleDeleteClick = (_event: any) => {
    _event.stopPropagation();
    setShowConfirm(true);
    if (buttonRef.current) {
      const rect = _event.target.getBoundingClientRect();
      const relativeX = _event.clientX - rect.left;
      const relativeY = _event.clientY - rect.top + rect.height + 30;
      setTop(relativeY);
      setLeft(relativeX);
    }
  };

  const handleYesClick = (_event: any) => {
    _event?.stopPropagation();
    onClick();
    setShowConfirm(false);
  };

  const handleNoClick = (_event: any) => {
    _event?.stopPropagation();
    setShowConfirm(false);
  };

  const ComponentMemo = customItemList
    ? renderCustomComponent(
        customItemList,
        OtherSettingTypesEnum.DELETECOMPONENT,
        "Component",
        {
          isOpen: showConfirm,
          onClose: handleNoClick,
          onDelete: handleYesClick,
        }
      )
    : null;

  return (
    <div>
      <div
        ref={buttonRef}
        onClick={handleDeleteClick}
        style={deleteHandleStyle}
      >
        <DeleteIcon
          width={24}
          height={24}
          viewBox="0 0 17 16"
          preserveAspectRatio="xMidYMid meet"
        />
      </div>

      {ComponentMemo}
      {/* {showConfirm && (
        <div
          style={{
            ...confirm_box,
            // top,
            // left: left,
          }}
        >
          <p style={confirm_text}>delete?</p>
          <div style={confirm_buttons}>
            <div onClick={handleYesClick}>✔️</div>
            <div onClick={handleNoClick}>❌</div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default memo(DeleteConfirm);
