import type { CSSProperties, FC } from "react";
import React, { useEffect, useRef, useState, memo, useMemo } from "react";
import { isEqual } from "lodash";
import { ResizableBox } from "react-resizable";

import { BaseItemData, getDefaultChildItem } from "../../type/Interface";
import { FormState } from "../../type/AttrTypes";

import { DeleteConfirm } from "../../components";
import { useList } from "../../store/listContext";
import { useMoveFormProperty } from "../../hook/useMoveFormProperty";

import { icons } from "../../../assets";
import "react-resizable/css/styles.css";

const { DragIcon } = icons;

const itemStyle: CSSProperties = {
  padding: "1rem 1rem",
  // marginBottom: ".5rem",
  position: "relative", // 为了定位拖拽符号
  cursor: "default", // 避免整个行都显示为可拖拽
};

const editStyle: CSSProperties = {
  // TODO 加上 focus 判斷
  // border: "1px dashed gray",
};

const dragAreaStyle: CSSProperties = {
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  top: 0,
  right: 0,
};

const dragHandleStyle: CSSProperties = {
  cursor: "move",
};

const deleteHandleStyle: CSSProperties = {
  cursor: "pointer",
};

const addChildStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",

  padding: "4px",
  cursor: "grab",
  gap: "4px",

  fontFamily: "poppins",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",

  color: "#262D42",
};

export const textStyle: CSSProperties = {
  flex: 1,
  overflow: "hidden", // 隱藏超出的文字
  textOverflow: "ellipsis", // 顯示省略號
  textAlign: "left",
  textWrap: "nowrap", // 防止文字換行
};

const addDraggingStyle: CSSProperties = {
  // border: "1px dashed gray",
};

export const iconStyle: CSSProperties = {
  display: "flex",
  padding: "4px",
  borderRadius: "4px",
  // backgroundColor: "#ECFBF0",
};

// 控制鼠標懸停狀態
const HoveredContainer: FC<{
  children: React.ReactNode;
  readOnly?: boolean;
  isDragging?: boolean;
  drag?: any;
  onDelete: () => void;
}> = ({ children, readOnly, isDragging, drag, onDelete }) => {
  const [hovered, setHovered] = useState(false); // 控制鼠標懸停狀態

  const handleonMouseEnter = () => {
    setHovered(!readOnly && true);
  };
  const handleonMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      style={{
        ...itemStyle,
        ...(readOnly ? {} : editStyle),
        opacity: isDragging ? 0.5 : 1,
        // width: dimensions.width ? `${dimensions.width}px` : "auto",
        // width: "100%",
      }}
      onMouseEnter={handleonMouseEnter}
      onMouseLeave={handleonMouseLeave}
    >
      {children}

      {hovered && (
        <div style={dragAreaStyle}>
          <div
            ref={drag} // 拖拽行为绑定在这个符号上
            style={dragHandleStyle}
          >
            <DragIcon
              width={24}
              height={24}
              viewBox="0 0 17 16"
              preserveAspectRatio="xMidYMid meet"
            />
          </div>

          <div style={deleteHandleStyle}>
            <DeleteConfirm onClick={onDelete} />
          </div>
        </div>
      )}
    </div>
  );
};

interface BaseChildProps {
  id: string;
  item: BaseItemData;
  pathIndex: number[];
  itemType: string;
  children: React.ReactNode;
}

export const Children: FC<BaseChildProps> = memo(
  function Children(props) {
    const { id, item, pathIndex, itemType, children } = props;

    const {
      state,
      isCanResizableBox,
      removeChildren,
      updateChildrenWidth,
      targetChildrenId,
    } = useList();

    const dropRef = useRef<HTMLDivElement>(null);
    const [hovered, setHovered] = useState(false); // 控制鼠標懸停狀態
    const [parentParentWidth, setParentParentWidth] = useState(0);

    const ref = useRef<HTMLDivElement>(null);

    // const [isVisible, setIsVisible] = useState(false);

    const readOnly = useMemo(() => {
      return !(state === FormState.CREATE);
    }, [state]);

    // 拖曳寬度
    const [dimensions, setDimensions] = useState({
      width: item?.width || 0,
      height: 0,
      widthRatio: 1,
    });

    const handleonMouseEnter = () => {
      setHovered(!readOnly && true);
    };
    const handleonMouseLeave = () => {
      setHovered(false);
    };

    const handleDelete = () => {
      removeChildren(id);
    };

    const handleSettingTarget = (_event: React.MouseEvent<HTMLDivElement>) => {
      _event.stopPropagation();
      targetChildrenId(id);
    };

    // 拖曳寬度
    const handleOnResize = (_event: React.SyntheticEvent, { size }: any) => {
      setDimensions((prev) => {
        const newWidth =
          (isNaN(size.width)
            ? dropRef?.current?.getBoundingClientRect()?.width
            : size.width) / parentParentWidth;
        updateChildrenWidth(id, newWidth);
        return {
          ...prev,
          width: newWidth < 0 ? 0 : newWidth,
          widthRatio: size.width / parentParentWidth,
        };
      });
    };

    const { drag, drop, preview, isDragging, handlerId, isOtherDragging } =
      useMoveFormProperty({
        id,
        item,
        pathIndex,
        itemType,
        dropRef,
        canDrag: true,
      });

    // 使用不渲染提升效能
    // useEffect(() => {
    //   const observer = new IntersectionObserver(
    //     ([entry]) => {
    //       if (entry.isIntersecting) {
    //         // 進入可視範圍，顯示元素
    //         setIsVisible(true);
    //       } else {
    //         // 離開可視範圍，隱藏元素
    //         setIsVisible(false);
    //       }
    //     },
    //     {
    //       threshold: 0.01, // 只要 1% 可見就觸發
    //       rootMargin: "500px", // 預先渲染可視範圍外 ?px 的內容
    //     }
    //   );

    //   if (ref.current) observer.observe(ref.current);

    //   return () => observer.disconnect();
    // }, []);

    useEffect(() => {
      if (dimensions.width !== item?.width) {
        setDimensions((prev) => {
          return {
            ...prev,
            width: item?.width || 0,
          };
        });
      }
    }, [item]);
    // 拖曳寬度 end

    useEffect(() => {
      if (dropRef.current) {
        // 取得父層的父層元素
        const parentParent =
          dropRef.current.parentElement?.parentElement?.parentElement;
        if (parentParent) {
          // 取得父層的父層的寬度
          const width = parentParent?.getBoundingClientRect()?.width;
          setParentParentWidth(width);
        }
      }
    }, []);

    preview(drop(dropRef)); // 绑定 drop 和 preview

    const childrenItem = (
      <div
        ref={dropRef}
        data-handler-id={handlerId}
        onClick={handleSettingTarget}
      >
        <HoveredContainer
          isDragging={isDragging}
          readOnly={readOnly}
          drag={drag}
          onDelete={handleDelete}
        >
          {/* <div>{isVisible ? children : null}</div> */}
          {children}
        </HoveredContainer>
      </div>
    );

    // TODO 沒有 ref?.current?.getBoundingClientRect()?.width 會影響拖曳的位置
    // const itemWidth =
    //   dimensions.width * parentParentWidth ||
    //   ref?.current?.getBoundingClientRect()?.width ||
    //   Infinity;
    const itemWidth = dimensions.width * parentParentWidth || Infinity;

    if (!readOnly && isCanResizableBox)
      return (
        <ResizableBox
          width={itemWidth}
          height={Infinity}
          minConstraints={[78, Infinity]}
          maxConstraints={[parentParentWidth, Infinity]}
          onResize={handleOnResize}
          axis="x"
          draggableOpts={{ grid: [6, 6] }}
        >
          {childrenItem}
        </ResizableBox>
      );
    else
      return (
        <div
          ref={ref}
          style={{ width: Number.isFinite(itemWidth) ? itemWidth : "auto" }}
        >
          {childrenItem}
        </div>
      );
  },
  (prevProps, nextProps) => {
    // console.debug(prevProps, nextProps);

    return isEqual(prevProps, nextProps);
    // return isEqual(omit(prevProps, "children"), omit(nextProps, "children"));
  }
);

interface BaseAddProps {
  itemType: string;
  data?: any;
  getNewData?: () => any;
  children: React.ReactNode;
}
export const Add: FC<BaseAddProps> = memo(function Add({
  itemType,
  data: defaultData,
  getNewData,
  children,
}: BaseAddProps) {
  const { customItemList } = useList();
  const data = getNewData ? getNewData() : defaultData;
  const item = getDefaultChildItem({
    ...data,
    ...(customItemList?.[itemType]?.defaultSetting || {}),
    type: itemType,
  });
  const dropRef = useRef<HTMLDivElement>(null);

  const { drag, preview, isDragging } = useMoveFormProperty({
    id: item.originalItem.id,
    pathIndex: item.pathId,
    item: item.originalItem,
    itemType: item.originalItem.type,
    dropRef,
    canDrag: true,
  });

  preview(drag(dropRef));

  return (
    <div
      ref={dropRef}
      style={{
        ...addChildStyle,
        ...(isDragging ? addDraggingStyle : {}),
      }}
    >
      {children}
    </div>
  );
});

interface PreviewProps {
  children: React.ReactNode;
}
export const Preview: FC<PreviewProps> = memo(function Preview({
  children,
}: PreviewProps) {
  return children;
});

interface ShortItemProps {
  onClick: () => void;
  children: React.ReactNode;
}
export const ShortItem: FC<ShortItemProps> = memo(function ShortItem({
  onClick,
  children,
}: ShortItemProps) {
  return (
    <button
      type="button"
      style={{
        width: "100%",
        borderRadius: 4,
        padding: "2px 4px",
      }}
      className=" hover:shadow-[999px_999px_0px_0px_#0008210A_inset] focus:bg-primary-100 line-clamp-1"
      onClick={onClick}
    >
      {children}
    </button>
  );
});

export default { Children, Add, Preview, iconStyle, ShortItem };
