import type { CSSProperties, FC } from "react";
import React from "react";
import { useList } from "../store/listContext";
import { OtherSettingTypesEnum } from "../type/ItemTypes";
import { renderCustomItemSetting } from "../../utils/methods";
import { useHistory } from "../hook/useStepHistory";
import { icons } from "../../assets";

const { MenuIcon, PageIcon, RedoIcon, UndoIcon } = icons;

const containerStyle: CSSProperties = {
  position: "sticky",
  top: 16,
  left: 40,
  height: "52px",
  padding: "8px",
  backgroundColor: "#FEFEFE",
  border: "1px solid #F0F0F2",
  borderRadius: "8px",
  width: "160px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 100,
};

const buttonStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "36px",
  height: "36px",
  borderRadius: "8px",
};

const ControlButton: FC<{
  icon: FC<{ fill?: string }>;
  onClick: () => void;
  disabled?: boolean;
}> = ({ icon: Icon, onClick, disabled }) => {
  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
      className=" hover:bg-gray-300"
    >
      <Icon fill={disabled ? "gray" : ""} />
    </button>
  );
};

const FormControl = () => {
  const {
    customItemList,
    onChangreActiveTab,
    handlePreviousStep,
    handleNextStep,
  } = useList();

  const history = useHistory();

  const isStart = history.past.length === 0;
  const isEnd = history.future.length === 0;

  const handleonMenu = () => {
    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.FORMCONTROL,
      "onMenu",
      1
    );
  };
  const handleonPage = () => {
    onChangreActiveTab("Sections");
    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.FORMCONTROL,
      "onPage",
      2
    );
  };
  const handleonUndo = () => {
    handlePreviousStep();
    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.FORMCONTROL,
      "onUndo",
      3
    );
  };
  const handleonRedo = () => {
    handleNextStep();
    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.FORMCONTROL,
      "onRedo",
      4
    );
  };

  return (
    <div style={containerStyle}>
      <ControlButton icon={MenuIcon} onClick={handleonMenu} />
      <ControlButton icon={PageIcon} onClick={handleonPage} />
      <ControlButton
        icon={UndoIcon}
        onClick={handleonUndo}
        disabled={isStart}
      />
      <ControlButton icon={RedoIcon} onClick={handleonRedo} disabled={isEnd} />
    </div>
  );
};

export default FormControl;
