import ButtonNode from "../customNodes/ButtonNode";
import StepNode from "../customNodes/StepNode";
import SendEmailNode from "../customNodes/SendEmailNode";
import BackToPreviousNode from "../customNodes/BackToPreviousNode";
import CreateNewVersionNode from "../customNodes/CreateNewVersionNode";
import CloseNode from "../customNodes/CloseNode";

export const CustomNodes = {
  button: ButtonNode,
  step: StepNode,
  sendEmail: SendEmailNode,
  backToPrevious: BackToPreviousNode,
  createNewVersion: CreateNewVersionNode,
  close: CloseNode,
};

enum ItemTypes {
  BUTTON = "button",
  STEP = "step",
  SENDEMAIL = "sendEmail",
  BACKTOPREVIOUS = "backToPrevious",
  CREATENEWVERSION = "createNewVersion",
  CLOSE = "close",

  SETTINGAREA = "settingArea",
  NODETOOLBAR = "nodeToolbar",
}

export const allItem = Object.keys(CustomNodes);

export default ItemTypes;
