import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DeleteFormConditionsRequest,
  FormBody,
  FormConditionsParams,
  FormConditionsRequest,
  FormDetail,
  FormWorkflow,
  OperateFormBodyRequest,
  OperateFormWorkflowRequest,
  UpdateFormDetailRequest,
  // pdf
  GetFormPdfDetailResponse,
  UpdateFormPdfDetailRequest,
} from '@/lib/form/formTemplateSetting/formTemplateSetting.interface';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';

export const formTemplateSettingApi = createApi({
  reducerPath: 'formTemplateSettingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Form Builder', 'Form Workflow', 'Form PDF', 'Form Detail'], // 加入 Form Detail
  endpoints: (builder) => ({
    getFormDetail: builder.query<FormDetail, any>({
      query: ({ formTemplateId }) => ({
        url: `/api/FormTemplateSetting/GetFormDetail/${formTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['Form Detail'], // 加入 tag
    }),
    updateFormDetail: builder.mutation<true, UpdateFormDetailRequest>({
      query: (formTemplateSetting) => ({
        url: `/api/FormTemplateSetting/UpdateFormDetail`,
        method: 'PUT',
        data: formTemplateSetting,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form Detail' as const },
          ]),
        );
      },
    }),
    getFormBody: builder.query<FormBody, any>({
      query: (templateId) => ({
        url: `/api/FormTemplateSetting/GetFormBody/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['Form Builder'],
    }),
    updateFormBody: builder.mutation<true, OperateFormBodyRequest>({
      query: (request) => ({
        url: `/api/FormTemplateSetting/UpdateFormBody`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form Builder' as const },
          ]),
        );
      },
    }),
    getFormWorkflow: builder.query<FormWorkflow, any>({
      query: (templateId) => ({
        url: `/api/FormTemplateSetting/GetFormWorkflow/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['Form Workflow'],
    }),
    updateFormWorkflow: builder.mutation<true, OperateFormWorkflowRequest>({
      query: (request) => ({
        url: `/api/FormTemplateSetting/UpdateFormWorkflow`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form Workflow' as const },
          ]),
        );
      },
    }),
    getFormPdfDetail: builder.query<GetFormPdfDetailResponse, string>({
      query: (templateId) => ({
        url: `/api/FormTemplateSetting/GetFormPdfDetail/${templateId}`,
        method: 'GET',
      }),
      async transformResponse(response) {
        const data = response.data.data;

        try {
          if (data.templateFileUrl) {
            const templateResponse = await fetch(data.templateFileUrl);
            const content = await templateResponse.text();
            return {
              ...data,
              content,
            };
          }
        } catch (error) {
          console.error('Failed to fetch template:', error);
        }

        return data;
      },
      providesTags: ['Form PDF'],
    }),
    updateFormPdfDetail: builder.mutation<true, UpdateFormPdfDetailRequest>({
      query: (request) => ({
        url: `/api/FormTemplateSetting/UpdateFormPdfDetail`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // 1. 等待 API 請求完成
        await queryFulfilled;

        // 2. 使用 dispatch 觸發快取更新
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form PDF' as const },
          ]),
        );
        // 3. RTK Query 會自動重新獲取標記為 'Form PDF' 的資料
      },
    }),
    updateFormPdfTemplate: builder.mutation<true, FormData>({
      query: (request) => ({
        url: `/api/FormTemplateSetting/UpdateFormPdfTemplate`,
        method: 'PUT',
        data: request,
      }),
    }),
    getFormConditions: builder.query<any, FormConditionsParams>({
      query: (params) => ({
        url: `/api/FormTemplateSetting/GetFormConditions/${params.templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    createFormCondition: builder.mutation<any, FormConditionsRequest>({
      query: (data) => ({
        url: `/api/FormTemplateSetting/CreateFormCondition`,
        method: 'POST',
        data,
      }),
    }),
    deleteFormConditions: builder.mutation<any, DeleteFormConditionsRequest>({
      query: (data) => ({
        url: `/api/FormTemplateSetting/DeleteFormConditions`,
        method: 'DELETE',
        data,
      }),
    }),
  }),
});

export const {
  useGetFormDetailQuery,
  useUpdateFormDetailMutation,
  useGetFormBodyQuery,
  useUpdateFormBodyMutation,
  // Workflow
  useGetFormWorkflowQuery,
  useUpdateFormWorkflowMutation,
  // PDF
  useGetFormPdfDetailQuery,
  useUpdateFormPdfDetailMutation,
  useUpdateFormPdfTemplateMutation,
  useGetFormConditionsQuery,
  useCreateFormConditionMutation,
  useDeleteFormConditionsMutation,
} = formTemplateSettingApi;
