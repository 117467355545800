import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';
import {
  OperateSubmissionFormBodyRequest,
  OperateSubmissionFormWorkflowRequest,
  SubmissionFormBody,
  SubmissionFormDetail,
  UpdateSubmissionFormDetailRequest,

  GetSubmissionFormPdfDetailResponse,
  UpdateSubmissionFormPdfDetailRequest,
} from '@/lib/submissionTemplates/submissionTemplateSetting.interface';

export const submissionTemplateSettingApi = createApi({
  reducerPath: 'submissionTemplateSettingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Submission Form Workflow', 'Submission Form PDF'],
  endpoints: (builder) => ({
    getSubmissionFormBody: builder.query<SubmissionFormBody, any>({
      query: (submissionTemplateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormBody/${submissionTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionFormDetail: builder.query<SubmissionFormDetail, any>({
      query: (submissionTemplateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormDetail/${submissionTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionFormDetailSubForm: builder.query<SubmissionFormDetail, any>({
      query: (submissionTemplateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormDetail/${submissionTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    updateSubmissionFormBody: builder.mutation<
      true,
      OperateSubmissionFormBodyRequest
    >({
      query: (data) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormBody`,
        method: 'PUT',
        data,
      }),
    }),
    getSubmissionWorkflow: builder.query<any, any>({
      query: (templateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormWorkflow/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionWorkflowSubForm: builder.query<any, any>({
      query: (templateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormWorkflow/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    updateFormWorkflow: builder.mutation<
      true,
      OperateSubmissionFormWorkflowRequest
    >({
      query: (request) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormWorkflow`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionTemplateSettingApi.util.invalidateTags([
            { type: 'Submission Form Workflow' as const },
          ]),
        );
      },
    }),
    updateFormDetail: builder.mutation<any, UpdateSubmissionFormDetailRequest>({
      query: (formTemplateSetting) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormDetail`,
        method: 'PUT',
        data: formTemplateSetting,
      }),
    }),
    getSubmissionFormPdfDetail: builder.query<GetSubmissionFormPdfDetailResponse, any>({
      query: (templateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormPdfDetail/${templateId}`,
        method: 'GET',
      }),
      async transformResponse(response) {
        const data = response.data.data;

        try {
          if (data.templateFileUrl) {
            const templateResponse = await fetch(data.templateFileUrl);
            const content = await templateResponse.text();
            return {
              ...data,
              content,
            };
          }
        } catch (error) {
          console.error('Failed to fetch template:', error);
        }

        return data;
      },
      providesTags: ['Submission Form PDF'],
    }),
    updateSubmissionFormPdfDetail: builder.mutation<true, UpdateSubmissionFormPdfDetailRequest>({
      query: (request) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormPdfDetail`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // 1. 等待 API 請求完成
        await queryFulfilled;

        // 2. 使用 dispatch 觸發快取更新
        dispatch(
          submissionTemplateSettingApi.util.invalidateTags([
            { type: 'Submission Form PDF' as const },
          ]),
        );
        // 3. RTK Query 會自動重新獲取標記為 'Form PDF' 的資料
      },
    }),
    updateSubmissionFormPdfTemplate: builder.mutation<true, FormData>({
      query: (request) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormPdfTemplate`,
        method: 'PUT',
        data: request,
      }),
    }),
  }),
});

export const {
  useGetSubmissionFormBodyQuery,
  useGetSubmissionFormDetailQuery,
  useGetSubmissionFormDetailSubFormQuery,
  useUpdateSubmissionFormBodyMutation,
  useGetSubmissionWorkflowQuery,
  useGetSubmissionWorkflowSubFormQuery,
  // Workflow
  useUpdateFormWorkflowMutation,
  useUpdateFormDetailMutation,
  // PDF
  useGetSubmissionFormPdfDetailQuery,
  useUpdateSubmissionFormPdfDetailMutation,
  useUpdateSubmissionFormPdfTemplateMutation,
} = submissionTemplateSettingApi;
