import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgHorizontalLayoutIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M2 8.667A1.333 1.333 0 0 0 3.333 10h1.334A1.333 1.333 0 0 0 6 8.667h1.333v2A2.667 2.667 0 0 0 10 13.334a1.333 1.333 0 0 0 1.333 1.333h1.334A1.333 1.333 0 0 0 14 13.334V12a1.333 1.333 0 0 0-1.333-1.333h-1.334A1.333 1.333 0 0 0 10 12a1.333 1.333 0 0 1-1.333-1.333V5.334A1.333 1.333 0 0 1 10 4a1.334 1.334 0 0 0 1.333 1.334h1.334A1.333 1.333 0 0 0 14 4V2.667a1.334 1.334 0 0 0-1.333-1.333h-1.334A1.333 1.333 0 0 0 10 2.667a2.667 2.667 0 0 0-2.667 2.667v2H6A1.333 1.333 0 0 0 4.667 6H3.333A1.333 1.333 0 0 0 2 7.334zm1.333 0V7.334h1.334v1.333zm8-4.667V2.667h1.334V4zm0 8v1.334h1.334V12z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHorizontalLayoutIcon;
