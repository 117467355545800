import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgSpaceIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#7A9BFF"}
      fillRule="evenodd"
      d="M2 2.566C2 2.253 2.253 2 2.566 2h10.868a.566.566 0 0 1 0 1.132H2.566A.566.566 0 0 1 2 2.566m0 4.415c0-1.063.862-1.924 1.925-1.924h8.15c1.063 0 1.925.861 1.925 1.924V9.02a1.924 1.924 0 0 1-1.924 1.924H3.925A1.925 1.925 0 0 1 2 9.02zm1.925-.792a.79.79 0 0 0-.793.792V9.02c0 .438.355.792.793.792h8.15a.79.79 0 0 0 .793-.792V6.98a.79.79 0 0 0-.793-.792zM2 13.434c0-.313.253-.566.566-.566h10.868a.566.566 0 0 1 0 1.132H2.566A.566.566 0 0 1 2 13.434"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpaceIcon;
