import { IconName } from '@/components/base/BaseIcon/interface';
import { form } from '@gobuid/flow-template';

const { ItemTypes } = form;

interface ComponentTypeMap {
  icon: IconName;
  color: string;
  label: string;
}

export const COMPONENT_TYPE_MAP = {
  shortText: {
    icon: 'ShortTextDefault',
    color: '#42DA6D',
    label: 'Short Text',
  },
  longText: {
    icon: 'LongTextDefault',
    color: '#42DA6D',
    label: 'Long Text',
  },
  dropdown: {
    icon: 'DropdownDefault',
    color: '#A96ECE',
    label: 'Dropdown',
  },
  multipleSelection: {
    icon: 'MultiSelectDefault',
    color: '#A96ECE',
    label: 'Multiple Selection',
  },
  singleSelection: {
    icon: 'SingleSelectDefault',
    color: '#A96ECE',
    label: 'Single Selection',
  },
  signature: {
    icon: 'SignatureDefault',
    color: '#E89B09',
    label: 'Signature',
  },
  upload: {
    icon: 'UploadDefault',
    color: '#E89B09',
    label: 'Upload',
  },
  dateTime: {
    icon: 'CalendarDefault',
    color: '#E89B09',
    label: 'Date & Time',
  },
  inputTable: {
    icon: 'TableDefault',
    color: '#E89B09',
    label: 'Input Table',
  },
  table: {
    icon: 'TableDefault',
    color: '#7A9BFF',
    label: 'Table',
  },
  image: {
    icon: 'PhotoDefault',
    color: '#7A9BFF',
    label: 'Image',
  },
  headerSection: {
    icon: 'SectionDefault',
    color: '#99958B',
    label: 'Header Section',
  },
  subForm: {
    icon: 'TemplateDefault',
    color: '#99958B',
    label: 'Sub Form',
  },
  richText: {
    icon: 'EditorDefault',
    color: '#99958B',
    label: 'Rich Text',
  },
  unknown: {
    icon: 'TemplateDefault',
    color: '#6B7280',
    label: 'Unknown',
  },
};

export const getComponentTypeInfo = (type) => {
  return COMPONENT_TYPE_MAP[type] || COMPONENT_TYPE_MAP.unknown;
};
