import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgAddIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M8.5 2c.473 0 .857.384.857.857v4.286h4.286a.857.857 0 0 1 0 1.714H9.357v4.286a.857.857 0 0 1-1.714 0V8.857H3.357a.857.857 0 0 1 0-1.714h4.286V2.857c0-.473.384-.857.857-.857"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAddIcon;
