import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgDividerIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#7A9BFF"}
      d="M6.333 3.334c0-.369.299-.667.667-.667h2A.667.667 0 0 1 9 4H7a.667.667 0 0 1-.667-.666M1 8c0-.368.298-.666.667-.666h12.666a.667.667 0 1 1 0 1.333H1.667A.667.667 0 0 1 1 8M6.333 12.667c0-.368.299-.667.667-.667h2a.667.667 0 0 1 0 1.334H7a.667.667 0 0 1-.667-.667M12.333 12a.667.667 0 0 0 0 1.334h2a.667.667 0 0 0 0-1.334zM1.667 12a.667.667 0 0 0 0 1.334h2a.667.667 0 0 0 0-1.334zM1 3.334c0-.369.298-.667.667-.667h2a.667.667 0 0 1 0 1.333h-2A.667.667 0 0 1 1 3.334M12.333 2.667a.667.667 0 0 0 0 1.333h2a.667.667 0 1 0 0-1.333z"
    />
  </svg>
);
export default SvgDividerIcon;
