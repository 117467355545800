import type { FC } from "react";
import React, { memo, useRef, useEffect, useMemo, useCallback } from "react";
import { isEqual } from "lodash";
import { BaseItemData } from "../../type/Interface";
import ItemTypes, {
  ItemComponents,
  TableData,
  InputTableEnum,
} from "../../type/ItemTypes";
import { FormState, tableChildrenKey } from "../../type/AttrTypes";
import BaseChildren from "./BaseChildren";
import { useList } from "../../store/listContext";

import { DraggableRow, DraggableColumn } from "../tableComponents";
import {
  renderCustomComponent,
  inputTableAddRow,
  inputTableAddColumn,
  inputTableUpdateCell,
  inputTableChangeType,
  inputTableChangeAllType,
  inputTableDeleteRow,
  inputTableDeleteColumn,
} from "../../../utils/methods";

export interface CommonItemHOCProps {
  id: string;
  item: BaseItemData;
  pathIndex: number[];
  itemType: string;
  defaultRender: FC<any>;
  isCurrentStep?: boolean;
  isFormTable?: boolean;
  tableProps?: any;
}

export interface ComponentAndSettingProps {
  state: FormState;
  parent: any;
  data: any;
  onChange: (e: any) => void;
  onChangeAttr: (e: any) => void;
  onChangeAllItemsAttr: (e: any) => void;
  onChangeAllRowsAttr: (e: any) => void;
  isCurrentStep?: boolean;
  tableProps?: any;
  inputTableProps?: any;
}

function useDeepMemo<T>(factory: () => T, deps: any[]): T {
  const ref = useRef<{ deps: any[]; value: T }>(null);

  if (!ref.current || !isEqual(ref.current.deps, deps)) {
    ref.current = { deps, value: factory() };
  }

  return ref.current.value;
}

const isEqualShallow = (objA: any, objB: any) => {
  return Object.keys(objA).every((key) => objA[key] === objB[key]);
};

function useShallowMemo<T>(factory: () => T, deps: any[]): T {
  const ref = useRef<{ deps: any[]; value: T }>(null);

  if (!ref.current || !isEqualShallow(ref.current.deps, deps)) {
    ref.current = { deps, value: factory() };
  }

  return ref.current.value;
}

const CommonItemHOC: React.FC<CommonItemHOCProps> = (props) => {
  const {
    id,
    item,
    pathIndex,
    itemType,
    defaultRender,
    isCurrentStep,
    // isFormTable,
  } = props;
  const {
    state,
    customItemList,
    targetCell,
    targetCellIndex,
    // clearTargetCellIndex,
    updateChildrenValue,
    updateChildrenAttr,
    updateChildrensAttr,
    updateSecondLayersAttr,
    updateFirstLayerAttr,
    getChildrenByPathId,
  } = useList();
  const containerRef = useRef<HTMLDivElement>(null);

  const parentItem = useMemo(
    () => getChildrenByPathId(pathIndex.slice(0, -1)),
    [pathIndex]
  );

  const handleOnChangeAllRowsAttr = useCallback(
    (e: any) => {
      if (parentItem) {
        updateFirstLayerAttr(e);
      }
      updateSecondLayersAttr(e);
    },
    [parentItem, updateFirstLayerAttr, updateSecondLayersAttr]
  );

  const handleCellClick = (event: any) => {
    // 事件觸發源
    const target = event.target;

    // 使用 closest 方法向上查找最近的 td 元素
    const cell = target.closest("td") || target.closest("th");

    if (!cell) {
      return;
    }

    // 獲取 colIndex
    const colIndex = cell.cellIndex;
    // 獲取 rowIndex
    const rowIndex = cell.parentElement.rowIndex;

    targetCellIndex(rowIndex, colIndex);
  };

  const inputTableProps = {
    DraggableRow,
    DraggableColumn,
    targetCell,
    onAddRow: (attrs: any[]) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableAddRow(item.components as TableData, attrs);
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onAddColumn: (attrs: any[]) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableAddColumn(item.components as TableData, attrs);
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onUpdateCell: (rowIndex: number, colIndex: number, attr: any) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableUpdateCell(
        item.components as TableData,
        rowIndex,
        colIndex,
        attr
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onChangeType: (colIndex: number, type: InputTableEnum) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableChangeType(
        item.components as TableData,
        colIndex,
        type
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onChangeAllType: (type: InputTableEnum) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableChangeAllType(
        item.components as TableData,
        type
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onDeleteRow: (index: number) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableDeleteRow(item.components as TableData, index);
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onDeleteColumn: (index: number) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = inputTableDeleteColumn(
        item.components as TableData,
        index
      );
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
    onChangeHeadWidth: (index: number, width: number) => {
      if (itemType !== ItemTypes.INPUTTABLE) return false;
      const newTable = structuredClone(item.components);
      newTable[0][index].width = width;
      updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    },
  };

  const newProps: ComponentAndSettingProps = {
    state,
    parent: parentItem,
    data: item,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      updateChildrenValue(id, e),
    onChangeAttr: (e: React.ChangeEvent<HTMLInputElement>) =>
      updateChildrenAttr(id, e),
    onChangeAllItemsAttr: (e: React.ChangeEvent<HTMLInputElement>) =>
      updateChildrensAttr(e),
    onChangeAllRowsAttr: handleOnChangeAllRowsAttr,
    isCurrentStep: isCurrentStep,
    tableProps: itemType === ItemTypes.TABLE ? props : null,
    inputTableProps: inputTableProps,
  };

  const ComponentMemo = customItemList?.[itemType]
    ? renderCustomComponent(customItemList, itemType, "Component", newProps)
    : null;

  const ItemComponent = ItemComponents[itemType]?.BaseItem
    ? React.createElement(ItemComponents[itemType].BaseItem, newProps)
    : React.createElement(defaultRender, newProps);

  useEffect(() => {
    if (containerRef.current) {
      const table = containerRef.current.querySelector("table");
      if (table && !table.dataset.hasClickListener) {
        table.addEventListener("click", handleCellClick);
        table.dataset.hasClickListener = "true"; // 標記事件已綁定
      }
    }
  }, []);

  if (
    itemType === ItemTypes.INPUTTABLE &&
    (!Array.isArray(item.components) ||
      item.components.every((v) => !Array.isArray(v)))
  ) {
    return (
      <span style={{ color: "red" }}>The data structure is incorrect.</span>
    );
  }

  return (
    <BaseChildren.Children
      id={id}
      item={item}
      itemType={itemType}
      pathIndex={pathIndex}
    >
      <div ref={containerRef}>{ComponentMemo || ItemComponent}</div>
    </BaseChildren.Children>
  );
};

export default memo(CommonItemHOC, (prevProps, nextProps) => {
  // console.debug(prevProps, nextProps);
  return isEqual(prevProps, nextProps);
});
