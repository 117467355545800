import type { FC, CSSProperties } from "react";
import React, { useEffect, useMemo, memo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CommonItemHOC from "./base/CommonItemHOC";
import CustomTable from "../components/CustomTable";
import { FormState, tableChildrenKey } from "../type/AttrTypes";
import ItemTypesEnum, {
  TableData,
  ItemComponents,
  OtherSettingTypesEnum,
} from "../type/ItemTypes";
import { useList } from "../store/listContext";
import Row from "./base/Row";
import BaseChild, { textStyle } from "./base/BaseChildren";
import { TableIcon } from "../../assets/icons";

import { DraggableRow, DraggableColumn } from "./tableComponents";
import TableCell from "./tableComponents/TableCell";

import {
  renderCustomItemSetting,
  renderCustomComponent,
  deepCopyWithNewId,
  parseAndIncrement,
} from "../../utils/methods";

const addButtonContainerStyle: CSSProperties = {
  display: "flex",
  gap: "0.5rem",
};

const addButtonStyle: CSSProperties = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  textAlign: "center",
  textDecorationLine: "underline",
  textDecorationStyle: "solid",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#0048FF",
};

export const BaseItem: FC<any> = memo((props) => {
  const { data, tableProps, parent, isCurrentStep } = props;
  const isDefault = data?.setting?.isDefault ?? true;
  const isCanDuplication = data?.setting?.isCanDuplication ?? false;
  const groupId = data?.setting?.groupId ?? "-1";

  const id = data.id;
  const {
    state,
    copyTable,
    customItemList,
    updateChildrenAttr,
    removeSecondLayerById,
  } = useList();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [targetItems, setTargetItems] = useState<any[]>([]);

  const tableData: TableData = data[tableChildrenKey];

  useEffect(() => {
    if (!data?.setting?.groupId) {
      updateChildrenAttr(id, {
        setting: {
          ...data?.setting,
          groupId,
        },
      });
    }
  }, [data?.setting?.groupId]);

  const generateEmptyRow = () => ({
    id: uuidv4(),
    type: ItemTypesEnum.ROW,
    components: [],
  });

  const onClose = () => setIsOpen(false);

  const updateOriginalData = (
    originalData: TableData,
    latestData: TableData[0]
  ) => {
    // 將最新資料轉換為以 ID 為鍵的查找對象
    const latestDataMap = Object.fromEntries(
      latestData.map((item) => [item.id, item])
    );

    // 遍歷原始資料
    const updatedData = originalData.map((group) =>
      group.map((item) => {
        // 如果最新資料中存在相同的 ID，則更新
        if (item?.id && latestDataMap[item.id]) {
          return {
            ...item,
            components: latestDataMap[item.id].components,
          };
        }
        return item; // 保留原始資料中的項目
      })
    );

    return updatedData;
  };

  const onChange = (values: any) => {
    setTargetItems(values);
    const newTable = updateOriginalData(structuredClone(tableData), values);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });
    onClose();
  };

  // Icon 事件
  const ComponentMemo = customItemList?.[ItemTypesEnum.TABLE]
    ? renderCustomComponent(
        customItemList,
        ItemTypesEnum.TABLE,
        "DailogComponent",
        {
          isOpen,
          onClose,
          onChange,
          targetItems,
        }
      )
    : null;

  const handleRowDuplicate = (index: number) => {
    const newRow = deepCopyWithNewId(structuredClone(tableData[index]));
    const newTable = structuredClone(tableData);
    newTable.splice(index + 1, 0, newRow);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });

    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.TABLE,
      "onRowDuplicate"
    );
  };
  const handleColumnDuplicate = (index: number) => {
    const newTable = structuredClone(tableData);
    newTable.forEach((row: any[]) => {
      const newCol = deepCopyWithNewId(structuredClone(row[index]));
      row.splice(index + 1, 0, newCol);
    });
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });

    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.TABLE,
      "onColumnDuplicate"
    );
  };
  const handleRowAssignFieldsToSteps = (index: number) => {
    setTargetItems(tableData[index]);
    setIsOpen(true);
    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.TABLE,
      "onRowAssignFieldsToSteps",
      index
    );
  };
  const handleColumnAssignFieldsToSteps = (index: number) => {
    setTargetItems(tableData.map((v) => v[index]));
    setIsOpen(true);
    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.TABLE,
      "onColumnAssignFieldsToSteps",
      index
    );
  };
  const handleRowDelete = (index?: number) => {
    const newTable = structuredClone(tableData);
    newTable.splice(index || newTable.length - 1, 1);
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });

    renderCustomItemSetting(customItemList, ItemTypesEnum.TABLE, "onRowDelete");
  };
  const handleColumnDelete = (index: number) => {
    const newTable = structuredClone(tableData);
    newTable.forEach((row: any[]) => {
      row.splice(index, 1);
    });
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });

    renderCustomItemSetting(
      customItemList,
      ItemTypesEnum.TABLE,
      "onColumnDelete"
    );
  };
  const RowFieldAction = (index: number) => {
    if (customItemList?.[OtherSettingTypesEnum.MENUCOMPONENT]?.Component)
      return renderCustomComponent(
        customItemList,
        OtherSettingTypesEnum.MENUCOMPONENT,
        "Component",
        {
          onRowDuplicate: () => handleRowDuplicate(index),
          onRowAssignFieldsToSteps: () => handleRowAssignFieldsToSteps(index),
          onRowDelete: () => handleRowDelete(index),
          iconName: "DragDefault",
        }
      );
    return null;
  };
  const ColumnFieldAction = (index: number) => {
    if (customItemList?.[OtherSettingTypesEnum.MENUCOMPONENT]?.Component)
      return renderCustomComponent(
        customItemList,
        OtherSettingTypesEnum.MENUCOMPONENT,
        "Component",
        {
          onColumnDuplicate: () => handleColumnDuplicate(index),
          onColumnAssignFieldsToSteps: () =>
            handleColumnAssignFieldsToSteps(index),
          onColumnDelete: () => handleColumnDelete(index),
          iconName: "DragDefault",
        }
      );
    return null;
  };
  // Icon 事件

  const addRow = () => {
    const newRow = new Array(tableData[tableData.length - 1].length).fill({});
    const lastRow = tableData[tableData.length - 1];
    for (let index = 0; index < newRow.length; index++) {
      newRow[index] = deepCopyWithNewId(lastRow[index]);
      if (newRow[index].components.length === 0) {
        newRow[index].name = parseAndIncrement(newRow[index].name);
      }
    }
    const newTable = [...tableData, newRow];
    updateChildrenAttr(id, { [tableChildrenKey]: newTable });
  };

  const addColumn = () => {
    const newTable = structuredClone(tableData);
    updateChildrenAttr(id, {
      [tableChildrenKey]: newTable.map((row) => [...row, generateEmptyRow()]),
    });
  };

  const duplicateTable = () => {
    // copyTable 裡面額外加上屬性資料
    copyTable(id, groupId);
  };
  const deleteTable = () => {
    removeSecondLayerById(parent.id);
  };

  const renderRow = (row: any[], rowIndex: number) => {
    if (!Array.isArray(row)) return null;

    return (
      <DraggableRow
        key={`${id}_draggable_${rowIndex}`}
        index={rowIndex}
        item={data}
        itemType={`${ItemTypesEnum.TABLE}_ROW`}
        iconSettingMenu={RowFieldAction(rowIndex)}
        valueKey={tableChildrenKey}
        useTdByDragIcon={false}
        // alwaysShowDrag
      >
        {row.map((cell: any, colIndex: number) => {
          return (
            <td
              key={`${id}_${rowIndex}_${colIndex}`}
              colSpan={cell?.colspan || 1}
              rowSpan={cell?.rowspan || 1}
              style={{
                border: "1px solid black",
                width: cell?.width || 150, // `${100 / row.length}%`,
                height: "auto",
                padding: 0,
                verticalAlign: "middle",
              }}
            >
              {rowIndex === 0 ? (
                <DraggableColumn
                  index={colIndex}
                  item={cell}
                  itemType={`${ItemTypesEnum.INPUTTABLE}_COLUMN`}
                  valueKey={tableChildrenKey}
                  // alwaysShowDrag
                  iconSettingMenu={ColumnFieldAction(colIndex)}
                  iconPosition="center"
                >
                  <TableCell>{renderItem(rowIndex, colIndex, cell)}</TableCell>
                </DraggableColumn>
              ) : (
                <TableCell>{renderItem(rowIndex, colIndex, cell)}</TableCell>
              )}
            </td>
          );
        })}
      </DraggableRow>
    );
  };

  // 渲染物件
  const renderItem = (rowIndex: number, colIndex: number, cell: any) => {
    if (cell.type === ItemTypesEnum.ROW) {
      return (
        <Row.Item
          key={cell.id}
          id={cell.id}
          item={cell}
          pathIndex={[...tableProps.pathIndex, rowIndex, colIndex]}
          canDrag={false}
          isFormTable
        />
      );
    }

    if (!cell.type || !ItemComponents[cell.type]) {
      return (
        <div style={{ height: "100px" }}>
          {rowIndex}-{colIndex}
        </div>
      );
    }

    return (
      <div>
        <CommonItemHOC
          {...props}
          id={cell.id}
          item={cell}
          itemType={cell.type}
          pathIndex={[...tableProps.pathIndex, rowIndex, colIndex]}
          defaultRender={() => <div>-</div>}
          isFormTable
        />
      </div>
    );
  };

  const allbuttons = {
    AddRow: (
      <button key={"addRow"} onClick={addRow}>
        <span style={addButtonStyle}>Add row</span>
      </button>
    ),
    DeleteRow: (
      <button key={"deleteRow"} onClick={() => handleRowDelete()}>
        <span style={addButtonStyle}>Delete row</span>
      </button>
    ),
    Duplicate: (
      <button key={"duplicate"} onClick={duplicateTable}>
        <span style={addButtonStyle}>Duplicate Table</span>
      </button>
    ),
    DeleteTable: (
      <button key={"deleteTable"} onClick={deleteTable}>
        <span style={addButtonStyle}>Delete Table</span>
      </button>
    ),
    AddColumn: (
      <button key={"addColumn"} onClick={addColumn}>
        <span style={addButtonStyle}>Add column</span>
      </button>
    ),
  };

  const buttons = [];
  switch (state) {
    case FormState.CREATE: {
      buttons.push(allbuttons.AddRow);
      buttons.push(allbuttons.AddColumn);
      break;
    }
    case FormState.FILLOUT: {
      if (isCanDuplication && isCurrentStep) {
        if (isDefault) buttons.push(allbuttons.Duplicate);
        else buttons.push(allbuttons.DeleteTable);

        buttons.push(allbuttons.AddRow);
        if (tableData.length > 1) buttons.push(allbuttons.DeleteRow);
      }
      break;
    }
    case FormState.PREVIEW: {
      break;
    }
    default:
      break;
  }

  if (!Array.isArray(tableData)) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          paddingTop: 15,
          paddingBottom: 5,
        }}
      >
        <CustomTable tableId={id} data={tableData}>
          {tableData.map(renderRow)}
        </CustomTable>
      </div>

      <div style={addButtonContainerStyle}>{buttons}</div>

      {ComponentMemo}
    </div>
  );
});

export const Item: FC<any> = memo(function Item(props) {
  return (
    <CommonItemHOC
      {...props}
      itemType={ItemTypesEnum.TABLE}
      defaultRender={BaseItem}
    />
  );
});

export const Add: FC = memo(function Add() {
  const getNewData = () => {
    const data = {
      [tableChildrenKey]: [
        [
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
        ],
        [
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
        ],
        [
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
          {
            id: uuidv4(),
            type: ItemTypesEnum.ROW,
            components: [],
          },
        ],
      ],
      setting: {
        groupId: uuidv4(),
        isDefault: true,
        isCanDuplication: false,
      },
    };
    return data;
  };

  return (
    <BaseChild.Add itemType={ItemTypesEnum.TABLE} getNewData={getNewData}>
      <div
        style={{
          ...BaseChild.iconStyle,
          backgroundColor: "#F2F5FF",
        }}
      >
        <TableIcon />
      </div>
      <span>Table</span>
    </BaseChild.Add>
  );
});

export const Preview: FC = memo(function Preview() {
  return (
    <BaseChild.Preview>
      <div
        style={{
          ...BaseChild.iconStyle,
          backgroundColor: "#F2F5FF",
        }}
      >
        <TableIcon />
      </div>
      <span>Table</span>
    </BaseChild.Preview>
  );
});

export const ShortItem: FC<{
  index: number;
  fieldName: string;
  onClick: () => void;
}> = memo(function ShortItem({ index, fieldName, onClick }) {
  return (
    <BaseChild.ShortItem onClick={onClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          fontSize: 14,
        }}
      >
        <div
          style={{
            ...BaseChild.iconStyle,
            backgroundColor: "#F2F5FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px",
            width: 48,
          }}
        >
          <TableIcon />
          <span>{index && `${index}.`}</span>
        </div>
        <span style={textStyle}>{fieldName ?? "Table"}</span>
      </div>
    </BaseChild.ShortItem>
  );
});

export const Setting = () => {
  return <div style={{ backgroundColor: "#ffffff" }} />;
};

export default {
  Item,
  Add,
  Preview,
  ShortItem,
  Setting: memo(Setting),
  BaseItem,
};
