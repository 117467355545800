import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgAddressIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#42DA6D"}
      fillRule="evenodd"
      d="M8 2.311c-2.071 0-3.75 1.761-3.75 3.934 0 .37.133.912.4 1.591.262.664.627 1.399 1.034 2.136.805 1.456 1.75 2.874 2.316 3.694.567-.82 1.511-2.238 2.316-3.694.407-.737.772-1.472 1.033-2.136.268-.679.401-1.22.401-1.591 0-2.173-1.679-3.934-3.75-3.934M3 6.245C3 3.348 5.239 1 8 1s5 2.348 5 5.245c0 .634-.21 1.37-.495 2.092a20 20 0 0 1-1.108 2.293c-.846 1.53-1.833 3.008-2.408 3.838-.49.71-1.487.71-1.978 0-.575-.83-1.562-2.307-2.408-3.838a20 20 0 0 1-1.108-2.293C3.211 7.614 3 6.879 3 6.245"
      clipRule="evenodd"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#42DA6D"}
      d="M9.875 6.245C9.875 7.33 9.035 8.21 8 8.21s-1.875-.88-1.875-1.966c0-1.087.84-1.967 1.875-1.967s1.875.88 1.875 1.967"
    />
  </svg>
);
export default SvgAddressIcon;
