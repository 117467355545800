import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgMiniMapIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M8 7.334A.667.667 0 0 0 7.333 8v2c0 .368.298.667.667.667h4a.667.667 0 0 0 .666-.667V8A.667.667 0 0 0 12 7.333z"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#262D42"}
      fillRule="evenodd"
      d="M3.333 3.334a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h9.333a2 2 0 0 0 2-2V5.334a2 2 0 0 0-2-2zm-.667 2c0-.369.299-.667.667-.667h9.333c.368 0 .667.298.667.667v5.333a.667.667 0 0 1-.667.667H3.333a.667.667 0 0 1-.667-.667z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMiniMapIcon;
