import type { FC } from "react";
import React, { memo } from "react";
import CommonItemHOC, { ComponentAndSettingProps } from "./base/CommonItemHOC";
import ItemTypesEnum from "../type/ItemTypes";
import BaseChild, { textStyle } from "./base/BaseChildren";
import { RichTextIcon } from "../../assets/icons";

export const BaseItem: FC<ComponentAndSettingProps> = memo(() => {
  return (
    <div>
      <span>RICHTEXT</span>
    </div>
  );
});

export const Item: FC<any> = memo(function Item(props) {
  return (
    <CommonItemHOC
      {...props}
      itemType={ItemTypesEnum.RICHTEXT}
      defaultRender={BaseItem}
    />
  );
});

export const Add: FC = memo(function Add() {
  const data = { value: "" };
  return (
    <BaseChild.Add itemType={ItemTypesEnum.RICHTEXT} data={data}>
      <div
        style={{
          ...BaseChild.iconStyle,
          backgroundColor: "#F5F4F3",
        }}
      >
        <RichTextIcon />
      </div>
      <span>Rich Text</span>
    </BaseChild.Add>
  );
});

export const Preview: FC = memo(function Preview() {
  return (
    <BaseChild.Preview>
      <div
        style={{
          ...BaseChild.iconStyle,
          backgroundColor: "#F5F4F3",
        }}
      >
        <RichTextIcon />
      </div>
      <span>Rich Text</span>
    </BaseChild.Preview>
  );
});

export const ShortItem: FC<{
  index: number;
  fieldName: string;
  onClick: () => void;
}> = memo(function ShortItem({ index, fieldName, onClick }) {
  return (
    <BaseChild.ShortItem onClick={onClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          fontSize: 14,
        }}
      >
        <div
          style={{
            ...BaseChild.iconStyle,
            backgroundColor: "#F5F4F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px",
            width: 48,
          }}
        >
          <RichTextIcon />
          <span>{index && `${index}.`}</span>
        </div>
        <span style={textStyle}>{fieldName ?? "Rich Text"}</span>
      </div>
    </BaseChild.ShortItem>
  );
});

export const Setting = (props: any) => {
  const { data } = props;

  return (
    <div>
      <div style={{ backgroundColor: "#ffffff" }} />
    </div>
  );
};

export default {
  Item,
  Add,
  Preview,
  ShortItem,
  Setting: memo(Setting),
  BaseItem,
};
