import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgCalculationIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#E89B09"}
      d="M5 1.834a.667.667 0 0 0-1.333 0V3.5H2a.667.667 0 1 0 0 1.334h1.667V6.5A.667.667 0 1 0 5 6.5V4.834h1.667a.667.667 0 0 0 0-1.334H5zM9.333 3.5a.667.667 0 1 0 0 1.334H14A.667.667 0 1 0 14 3.5zM6.69 10.253a.667.667 0 0 0-.943-.943l-1.414 1.414L2.92 9.31a.667.667 0 1 0-.943.943l1.414 1.414-1.414 1.414a.667.667 0 1 0 .943.943l1.414-1.414 1.414 1.414a.667.667 0 0 0 .943-.943l-1.414-1.414zM8.667 10.5c0-.368.298-.666.666-.666H14a.667.667 0 1 1 0 1.333H9.333a.667.667 0 0 1-.666-.667M8.667 13.167c0-.368.298-.667.666-.667H14a.667.667 0 0 1 0 1.334H9.333a.667.667 0 0 1-.666-.667"
    />
  </svg>
);
export default SvgCalculationIcon;
