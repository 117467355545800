import type { CSSProperties, FC } from "react";
import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { useFlow } from "../store/flowContext";
import CustomNodeToolbar from "../components/CustomNodeToolbar";
import CustomHandle from "../components/CustomHandle";
import ItemTypes from "../type/ItemTypes";
import FlowState from "../type/FlowState";
import {
  renderCustomComponent,
  generateNodeAndEdge,
} from "../../utils/methods";

const Node = styled.div`
  .react-flow__handle {
  }
`;

const text_updater_node_label: CSSProperties = {
  display: "block",
  color: "#777",
  fontSize: "12px",
};

export interface StepNodeProps {
  id: string;
  data: any;
  type: string;
  isConnectable: boolean;
  selected: boolean;
  positionAbsoluteX: number;
  positionAbsoluteY: number;
}

const StepNode: FC<StepNodeProps> = (props) => {
  const {
    id,
    data,
    type,
    isConnectable,
    selected,
    positionAbsoluteX,
    positionAbsoluteY,
  } = props;

  const {
    update,
    nodes,
    flowState,
    onChangeNodesData,
    onAddNodeAndEdge,
    onDeleteNodes,
    customItemList,
    findConnectButton,
    isFirstStep,
  } = useFlow();

  const readOnly = flowState === FlowState.PREVIEW;
  const connectNodes = findConnectButton(id);

  const handleOnChange = (e: any) => {
    onChangeNodesData(id, e);
  };

  const handleOnChangeById = (_id: string, e: any) => {
    onChangeNodesData(_id, e);
  };

  const handleOnAddButton = (attr: any) => {
    const newAttr = {
      ...(customItemList?.[ItemTypes.BUTTON]?.["defaultSetting"] || {}),
      ...(attr || {}),
    };
    const newNodeAndEdge = generateNodeAndEdge(
      id,
      type,
      ItemTypes.BUTTON,
      newAttr,
      {
        x: positionAbsoluteX + 250,
        y: positionAbsoluteY,
      }
    );
    onAddNodeAndEdge(newNodeAndEdge.node, newNodeAndEdge.edge);
  };

  const handleOnRemoveButton = (_id: string) => {
    onDeleteNodes(_id);
  };

  const newProps = {
    id,
    flowState,
    data,
    connectNodes, // 前面全部的 button node
    connectToSteps: [],
    onChange: handleOnChange,
    onChangeById: handleOnChangeById,
    selected,
    onAddButton: handleOnAddButton, // step 才有
    onRemoveButton: handleOnRemoveButton, // step 才有
    update,
    isFirstStep: isFirstStep[0]?.id === id,
  };

  const componentMemo = useMemo(() => {
    return customItemList?.[type] ? (
      renderCustomComponent(customItemList, type, "Component", newProps)
    ) : (
      <label htmlFor="text" style={text_updater_node_label}>
        {type}
      </label>
    );
  }, [customItemList]);

  const disabledDeleteMemo = useMemo(() => {
    return nodes.filter((v) => v.type === ItemTypes.STEP).length === 1;
  }, [nodes]);

  return (
    <Node>
      <CustomHandle id={type} type="target" isConnectable={isConnectable} />

      <div>{componentMemo}</div>
      {readOnly ? null : (
        <CustomNodeToolbar
          id={id}
          type={type}
          selected={selected}
          disabledDelete={disabledDeleteMemo}
        />
      )}

      <CustomHandle id={type} type="source" isConnectable={isConnectable} />
    </Node>
  );
};

export default memo(StepNode);
