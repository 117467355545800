import type { FC } from "react";
import React, { memo } from "react";

const InputTableMultipleSelection: FC<any> = (props) => {
  const { item, onChange, readOnly } = props;

  const handleChange = (e: any) => {
    onChange({ value: e.target.checked });
  };

  const handleClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    onChange({ value: !target.checked });
  };

  return (
    <div>
      <input
        checked={item?.value || false}
        onChange={handleChange}
        onClick={handleClick}
        type="checkbox"
        disabled={readOnly}
      />
    </div>
  );
};

export default memo(InputTableMultipleSelection);
