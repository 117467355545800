import Page from "../items/base/Page";
import Row from "../items/base/Row";

import ShortText from "../items/ShortText";
import LongText from "../items/LongText";

import Dropdown from "../items/Dropdown";
import MultipleSelection from "../items/MultipleSelection";
import SingleSelection from "../items/SingleSelection";

import Signature from "../items/Signature";
import Upload from "../items/Upload";
import DateTime from "../items/DateTime";
import InputTable from "../items/InputTable";

import Table from "../items/Table";
import Image from "../items/Image";

import HeaderSection from "../items/HeaderSection";
import SubForm from "../items/SubForm";
import RichText from "../items/RichText";

interface ItemComponentsType {
  [key: string]: any;
}
export const ItemComponents: ItemComponentsType = {
  page: Page,
  row: Row,

  shortText: ShortText,
  longText: LongText,

  dropdown: Dropdown,
  multipleSelection: MultipleSelection,
  singleSelection: SingleSelection,

  signature: Signature,
  upload: Upload,
  dateTime: DateTime,
  inputTable: InputTable,

  table: Table,
  image: Image,

  headerSection: HeaderSection,
  subForm: SubForm,
  richText: RichText,
};

export enum ItemTypesEnum {
  DRAGAREA = "DragArea",
  PAGE = "page",
  ROW = "row",

  SHORTTEXT = "shortText",
  LONGTEXT = "longText",

  DROPDOWN = "dropdown",
  MULTIPLESELECTION = "multipleSelection",
  SINGLESELECTION = "singleSelection",

  SIGNATURE = "signature",
  UPLOAD = "upload",
  DATETIME = "dateTime",
  INPUTTABLE = "inputTable",

  TABLE = "table",
  IMAGE = "image",

  HEADERSECTION = "headerSection",
  SUBFORM = "subForm",
  RICHTEXT = "richText",
}

export enum OtherSettingTypesEnum {
  FORMCONTROL = "formControl",
  ITEMSETTING = "itemSetting",
  DELETECOMPONENT = "deleteComponent",
  SELECTCOMPONENT = "selectComponent",
  MENUCOMPONENT = "menuComponent",
}

// export const ItemTypesEnum = Object.keys(ItemComponents).reduce(
//   (acc, key) => {
//     acc[key.toUpperCase()] = key;
//     return acc;
//   },
//   {} as Record<string, string>
// );

export const ItemTypesCategory = [
  // { type: "TEST", items: [ItemTypesEnum.PAGE, ItemTypesEnum.ROW] },
  { type: "Text", items: [ItemTypesEnum.SHORTTEXT, ItemTypesEnum.LONGTEXT] },
  {
    type: "Choices",
    items: [
      ItemTypesEnum.DROPDOWN,
      ItemTypesEnum.MULTIPLESELECTION,
      ItemTypesEnum.SINGLESELECTION,
    ],
  },
  {
    type: "Miscellaneous",
    items: [
      ItemTypesEnum.SIGNATURE,
      ItemTypesEnum.UPLOAD,
      ItemTypesEnum.DATETIME,
      ItemTypesEnum.INPUTTABLE,
    ],
  },
  { type: "Layout", items: [ItemTypesEnum.TABLE, ItemTypesEnum.IMAGE] },
  {
    type: "OTHER",
    items: [
      ItemTypesEnum.HEADERSECTION,
      ItemTypesEnum.SUBFORM,
      ItemTypesEnum.RICHTEXT,
    ],
  },
];

export const AllItemKeys = Object.keys(ItemComponents);

export enum InputTableEnum {
  HEAD = "Head", // 左上角第一個
  ROWHEAD = "RowHead",
  COLUMNHEAD = "ColumnHead",

  SINGLESELECTION = "InputTableSingleSelection",
  MULTIPLESELECTION = "InputTableMultipleSelection",
  DROPDOWN = "InputTableDropdown",
  TEXTINPUT = "InputTableTextInput",
  NONEDITABLETEXT = "InputTableNonEditableText",
}

// 定義每個子物件的類型
export type HeaderCell = {
  id?: string;
  type?: InputTableEnum | ItemTypesEnum;
  title?: string;
  value?: string | number | boolean | any;
};

// 定義表格的型別
export type TableData = Array<Array<HeaderCell>>;

export default ItemTypesEnum;
