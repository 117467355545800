import React from "react";
import { icons } from "../../assets";
import { FormState } from "../type/AttrTypes";

const { FingerIcon, TemplateIcon } = icons;

const FormBuilderEmptyState = ({ state }: { state: FormState }) => {
  if (state === FormState.CREATE) {
    return (
      <div className="py-8 px-4 flex flex-col justify-center items-center w-full gap-4">
        <FingerIcon />
        <p className=" typo-paragraph-sm text-gray-800 text-center">
          Drag or double click a field from the field panel
        </p>
      </div>
    );
  }

  return (
    <div className="py-8 px-4 flex flex-col justify-center items-center w-full gap-4">
      <TemplateIcon />
      <div className="flex flex-col justify-center items-center w-full gap-0.5 text-center">
        <h6 className=" typo-heading-xs text-[16px]">
          This form template is empty
        </h6>
        <p className="typo-paragraph-sm text-gray-800">
          Click Edit to open the form builder and start adding fields to collect
          the information you need.
        </p>
      </div>
    </div>
  );
};

export default FormBuilderEmptyState;
