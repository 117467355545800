import React, { CSSProperties } from "react";

const stepTeamStyle: CSSProperties = {
  width: "fit-content",
  height: "100%",
  backgroundColor: "#F5F5F6",
  borderRadius: "4px",
  fontSize: "12px",
  padding: "4px 8px",
  whiteSpace: "nowrap",
  maxWidth: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "left",
};

interface StepTeamProps {
  label: string;
}

const StepTeam = ({ label }: StepTeamProps) => {
  return <div style={stepTeamStyle}>{label}</div>;
};

export default StepTeam;
