import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';
import { ApiResponse, ByPageData } from '@/types/interface';
import {
  Brief,
  BriefQueryParams,
  CreateShareLinkRequest,
  CreateSubformParams,
  CreateSubmissionRequest,
  ProcessDetailRequest,
  SaveStepRequest,
  ShareLinks,
  Submission,
  SubmissionDetail,
  SubmissionHistoriesByPageFilter,
  SubmissionHistoriesByPageFilterParams,
  SubmissionProgress,
  SubmissionsQuery,
  SubmissionTodoByPage,
  SubmissionTodoParams,
  SubmitStepRequest,
  UpdateByManagerRequest,
} from '@/lib/submissions/submissions.interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const submissionApi = createApi({
  reducerPath: 'submissionApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'submissionApi',
    'filledSubmission',
    'trashedSubmission',
    'submissionProgress',
    'filledSubmissionSubForm',
    'submissionFormTodo',
    'shareLink',
    'submissionHistory',
  ],
  endpoints: (builder) => ({
    createSubmission: builder.mutation<any, CreateSubmissionRequest>({
      query: (body) => ({
        url: '/api/Submissions',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['submissionApi', 'submissionFormTodo'],
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    getSubmissions: builder.query<Submission, SubmissionsQuery>({
      query: (params) => ({
        url: `/api/Submissions`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['submissionApi', 'filledSubmission'],
    }),
    getSubmissionDetail: builder.query<SubmissionDetail, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Detail`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['filledSubmission'],
    }),
    // 一次性的查詢
    getSubmissionDetailOneTime: builder.query<SubmissionDetail, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Detail`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    // subForm
    getSubmissionDetailSubForm: builder.query<any, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Detail`,
        method: 'GET',
      }),
      providesTags: ['filledSubmissionSubForm'],
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    submitStep: builder.mutation<any, SubmitStepRequest>({
      query: (body) => ({
        url: `/api/Submissions/SubmitStep`,
        method: 'POST',
        data: body,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'filledSubmission' as const },
            { type: 'submissionProgress' as const },
            { type: 'filledSubmissionSubForm' as const },
            { type: 'submissionFormTodo' as const },
          ]),
        );
      },
    }),
    saveStepWithoutRefresh: builder.mutation<any, SaveStepRequest>({
      query: (data) => ({
        url: `/api/Submissions/SaveStep`,
        method: 'PUT',
        data,
      }),
    }),
    saveStep: builder.mutation<any, SaveStepRequest>({
      query: (data) => ({
        url: `/api/Submissions/SaveStep`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'filledSubmission' as const },
            { type: 'submissionHistory' as const },
          ]),
        );
      },
    }),
    subformSaveStep: builder.mutation<any, SaveStepRequest>({
      query: (data) => ({
        url: `/api/Submissions/SaveStep`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'filledSubmissionSubForm' as const },
          ]),
        );
      },
    }),
    getSubmissionProgress: builder.query<SubmissionProgress, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Progress`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['submissionProgress'],
    }),
    getSubmissionProgressSubForm: builder.query<SubmissionProgress, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Progress`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['submissionProgress'],
    }),
    batchMoveToTrash: builder.mutation<any, { submissionIds: number[] }>({
      query: (data) => ({
        url: `/api/Submissions/BatchMoveToTrash`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: [
        'submissionApi',
        'trashedSubmission',
        'submissionFormTodo',
      ],
      transformResponse: (response) => response.data.data,
    }),
    batchRestore: builder.mutation<any, { submissionIds: number[] }>({
      query: (data) => ({
        url: `/api/Submissions/BatchRestore`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['submissionApi', 'trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    getNewOneOverview: builder.query<any, number>({
      query: (templateId) => ({
        url: `/api/Submissions/NewOneOverview`,
        method: 'GET',
        params: { templateId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getTrashed: builder.query<any, SubmissionsQuery>({
      query: (params) => ({
        url: `/api/Submissions/GetTrashed`,
        method: 'GET',
        params,
      }),
      providesTags: ['trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    batchDeleteForever: builder.mutation<any, { submissionIds: number[] }>({
      query: (data) => ({
        url: `/api/Submissions/BatchDeleteForever`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    clearTrashed: builder.mutation<boolean, number>({
      query: (templateId) => ({
        url: `/api/Submissions/ClearTrashed/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    getBrief: builder.query<Brief, BriefQueryParams>({
      query: (params) => ({
        url: `/api/Submissions/Brief`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['submissionApi'],
      forceRefetch({ currentArg, previousArg }) {
        if (!previousArg) return true;
        return (
          currentArg.templateId !== previousArg.templateId ||
          currentArg.pageNumber !== previousArg.pageNumber
        );
      },
      merge: (currentData, newData, { arg }) => {
        // 如果是第一頁，則完全替換資料（用於資料更新的情況）
        if (arg.pageNumber === 1) {
          return newData;
        }

        // 否則，將新資料附加到現有資料（用於無限滾動的情況）
        return {
          ...newData,
          data: [...(currentData?.data || []), ...(newData?.data || [])],
        };
      },
      // 添加 serializeQueryArgs 以確保正確的緩存行為
      serializeQueryArgs: ({ queryArgs }) => {
        return { templateId: queryArgs.templateId };
      },
    }),
    getBriefSubForm: builder.query<Brief, BriefQueryParams>({
      query: (params) => ({
        url: `/api/Submissions/Brief`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
    getProcessDetail: builder.query<any, ProcessDetailRequest>({
      query: (params) => ({
        url: `/api/Submissions/${params.submissionId}/ProcessDetail`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
    getProcessDetailSubForm: builder.query<any, ProcessDetailRequest>({
      query: (params) => ({
        url: `/api/Submissions/${params.submissionId}/ProcessDetail`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
    createSubform: builder.mutation<any, CreateSubformParams>({
      query: (data) => ({
        url: `/api/Submissions/CreateSubform`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionTodos: builder.query<
      SubmissionTodoByPage,
      SubmissionTodoParams
    >({
      query: (params) => ({
        url: `/api/Submissions/Todo`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['submissionFormTodo'],
    }),
    getShareLinks: builder.query<ShareLinks, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/ShareLinks`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['shareLink'],
    }),
    createShareLink: builder.mutation<any, CreateShareLinkRequest>({
      query: (data) => ({
        url: `/api/Submissions/CreateShareLink`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([{ type: 'shareLink' as const }]),
        );
      },
    }),
    deleteShareLink: builder.mutation<any, number>({
      query: (submissionShareLinkId) => ({
        url: `/api/Submissions/DeleteShareLink/${submissionShareLinkId}`,
        method: 'DELETE',
        data: {
          submissionShareLinkId,
        },
      }),
      transformResponse: (response) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([{ type: 'shareLink' as const }]),
        );
      },
    }),
    getSubmissionHistoriesByPageFilter: builder.query<
      SubmissionHistoriesByPageFilter,
      SubmissionHistoriesByPageFilterParams
    >({
      query: (params) => ({
        url: `/api/Submissions/GetSubmissionHistoriesByPageFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { submissionId, version, pageNumber } = queryArgs;
        return `${endpointName}-${submissionId}-${version}`;
      },

      forceRefetch({ currentArg, previousArg }) {
        if (!previousArg) return true;
        return (
          currentArg.submissionId !== previousArg.submissionId ||
          currentArg.version !== previousArg.version ||
          currentArg.pageNumber !== previousArg.pageNumber
        );
      },
      merge: (currentData, newData, { arg }) => {
        // 如果是第一頁，則完全替換資料（用於資料更新的情況）
        if (arg.pageNumber === 1) {
          return newData;
        }
        // 否則，將新資料附加到現有資料（用於無限滾動的情況）
        return {
          ...newData,
          data: [...currentData.data, ...newData.data],
        };
      },
      providesTags: ['submissionHistory'],
    }),
    updateByManager: builder.mutation<any, UpdateByManagerRequest>({
      query: (data) => ({
        url: `/api/Submissions/UpdateByManager`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'submissionHistory' as const },
            { type: 'filledSubmission' as const },
            { type: 'submissionProgress' as const },
          ]),
        );
      },
    }),
  }),
});

export const {
  useCreateSubmissionMutation,
  useGetSubmissionsQuery,
  useGetSubmissionDetailQuery,
  useGetSubmissionDetailOneTimeQuery,
  useGetSubmissionDetailSubFormQuery,
  useSubmitStepMutation,
  useSaveStepWithoutRefreshMutation,
  useSaveStepMutation,
  useSubformSaveStepMutation,
  useGetSubmissionProgressQuery,
  useGetSubmissionProgressSubFormQuery,
  useBatchMoveToTrashMutation,
  useBatchRestoreMutation,
  useGetNewOneOverviewQuery,
  useGetTrashedQuery,
  useBatchDeleteForeverMutation,
  useClearTrashedMutation,
  useGetBriefQuery,
  useGetBriefSubFormQuery,
  useGetProcessDetailQuery,
  useGetProcessDetailSubFormQuery,
  useCreateSubformMutation,
  useGetSubmissionTodosQuery,
  useGetShareLinksQuery,
  useCreateShareLinkMutation,
  useDeleteShareLinkMutation,
  useGetSubmissionHistoriesByPageFilterQuery,
  useUpdateByManagerMutation,
} = submissionApi;
