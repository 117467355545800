import type { CSSProperties, FC } from "react";
import React, { useState, useMemo, memo } from "react";

import { ItemComponents, ItemTypesCategory } from "../type/ItemTypes";
import { icons } from "../../assets";
import { useList } from "../store/listContext";

const { SearchIcon } = icons;

const inputAreaStyle: CSSProperties = {
  display: "flex",
  padding: "12px 8px 12px 8px",
  gap: 4,
  borderRadius: 10,
  border: "1px solid #F0F0F2",
};

const inputDivStyle: CSSProperties = {
  flex: 1,
  alignContent: "center",
};

const inputStyle: CSSProperties = { all: "unset", fontSize: 16 };

const itemListStyle: CSSProperties = {
  paddingTop: 16,
  paddingBottom: 16,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: 8,
};

const itemTitleStyle: CSSProperties = {
  fontFamily: "poppins",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#BFC1C7",
};

const itemStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
};

const TabFields: FC = () => {
  const { itemList = [] } = useList();
  const [search, setSearch] = useState("");

  const listMemo = useMemo(() => {
    const listCategory = ItemTypesCategory.filter((v) =>
      v.items
        .filter((vv) => vv.toLowerCase().includes(search.toLowerCase()))
        .some((vv) => itemList.includes(vv))
    );

    return listCategory;
  }, [itemList, search]);

  const renderComponent = (componentName: string, index: number) => {
    const component = ItemComponents[componentName];
    if (component) {
      return <component.Add key={`${componentName}-${index}`} />;
    } else {
      return <div>-</div>;
    }
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          paddingTop: 16,
          paddingBottom: 8,
        }}
      >
        <div style={inputAreaStyle}>
          <div>
            <SearchIcon />
          </div>
          <div style={inputDivStyle}>
            <input
              style={inputStyle}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div style={itemListStyle}>
        {listMemo.map((item) => {
          return (
            <div key={item.type}>
              <div>
                <span style={itemTitleStyle}>{item.type.toUpperCase()}</span>
              </div>
              <div style={itemStyle}>
                {item.items
                  .filter((vv) =>
                    vv.toLowerCase().includes(search.toLowerCase())
                  )
                  .map(renderComponent)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TabFields);
