import React from "react";

const buttonStyle = {
  gap: "2px",
  backgroundColor: "#0029A5",
  color: "#FCFCFC",
  fontSize: "14px",
  fontWeight: "500",
  minWidth: "100px",
  width: "100%",
  padding: "10px 8px",
};

interface StepButtonProps {
  color?: string;
  label?: string;
  onClick: () => void;
  alignment?: "left" | "center" | "right";
  isMultipleButtons?: boolean;
}

const StepButton = ({
  color = "#0029A5",
  label = "Submit",
  onClick,
  alignment = "right",
  isMultipleButtons = false,
}: StepButtonProps) => {
  const containerStyle = {
    flex: isMultipleButtons ? "none" : 1,
    display: "flex",
    gap: "8px",
    justifyContent:
      alignment[0] === "left"
        ? "flex-start"
        : alignment[0] === "center"
          ? "center"
          : "flex-end",
  };

  return (
    <div style={containerStyle} className=" md:w-fit">
      <button
        type="button"
        style={{
          ...buttonStyle,
          backgroundColor: color || "#0029A5",
        }}
        className="w-full md:w-fit rounded-[10px] text-center"
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

export default StepButton;
