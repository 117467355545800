import React, { CSSProperties, FC } from "react";
import { useList } from "../store/listContext";
import { OtherSettingTypesEnum } from "../type/ItemTypes";
import { renderCustomItemSetting } from "../../utils/methods";
import ItemTypesEnum from "../type/ItemTypes";
import { DeleteConfirm } from ".";
import { icons } from "../../assets";

const { SettingIcon, CopyIcon } = icons;

const settingAreaStyle: CSSProperties = {
  position: "absolute",
  textAlign: "center",
  justifySelf: "center",
  display: "grid",
  zIndex: 1,
  right: "50%",
  transform: "translateX(50%)",
  bottom: -70,
  padding: 4,
};

const settingItemStyle: CSSProperties = {
  display: "flex",
  boxShadow: "0px 0px 8px 0px #0029A514",
  gap: 4,
  backgroundColor: "#FEFEFE",
  padding: 4,
  borderRadius: 8,
};

const buttonStyle: CSSProperties = {
  flex: 1,
  padding: 12,
  borderRadius: 8,
};

const ItemToolBar: FC<{ id: string; type?: string; hideSetting?: boolean }> = (
  props
) => {
  const { id, type, hideSetting } = props;
  const {
    customItemList,
    targetChildrenId,
    findSecondLayer,
    removeSecondLayerById,
    copyItem,
  } = useList();

  const handleOnSetting = (_event: any) => {
    _event.stopPropagation();

    // 顯示 components 第一個內容
    if (type === ItemTypesEnum.ROW) {
      const findItem = findSecondLayer(id);
      if (findItem?.item && findItem.item.components.length > 0) {
        targetChildrenId(findItem.item.components[0].id);
      }
    } else {
      targetChildrenId(id);
    }

    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.ITEMSETTING,
      "onSetting",
      id
    );
  };

  const handleOnCopy = (_event: any) => {
    _event.stopPropagation();
    copyItem(id);
    renderCustomItemSetting(
      customItemList,
      OtherSettingTypesEnum.ITEMSETTING,
      "onCopy",
      id
    );
  };

  const handleOnDelete = () => {
    removeSecondLayerById(id);

    // renderCustomItemSetting(
    //   customItemList,
    //   OtherSettingTypesEnum.ITEMSETTING,
    //   "onDelete",
    //   id
    // );
  };

  return (
    <div style={settingAreaStyle}>
      <div style={settingItemStyle}>
        {hideSetting ? null : (
          <button
            type="button"
            style={buttonStyle}
            onClick={handleOnSetting}
            className="hover:bg-gray-300"
          >
            <SettingIcon
              width={24}
              height={24}
              viewBox="0 0 17 16"
              preserveAspectRatio="xMidYMid meet"
            />
          </button>
        )}

        <button
          type="button"
          style={buttonStyle}
          onClick={handleOnCopy}
          className="hover:bg-gray-300"
        >
          <CopyIcon
            width={24}
            height={24}
            viewBox="0 0 17 16"
            preserveAspectRatio="xMidYMid meet"
          />
        </button>

        <button type="button" style={buttonStyle} className="hover:bg-gray-300">
          <DeleteConfirm onClick={handleOnDelete} />
        </button>
      </div>
    </div>
  );
};

export default ItemToolBar;
