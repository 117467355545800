import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgPageIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M2.667 3.333a2 2 0 0 1 2-2h4c.177 0 .346.07.471.195l4 4a.67.67 0 0 1 .195.472v6.666a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2zm2-.667A.667.667 0 0 0 4 3.333v9.333c0 .368.299.667.667.667h6.666a.667.667 0 0 0 .667-.667v-6H9.333A1.333 1.333 0 0 1 8 5.333V2.666zm4.666.943 1.724 1.724H9.333z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPageIcon;
