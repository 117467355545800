import type { ReactNode } from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  memo,
  useImperativeHandle,
  forwardRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash"; // 使用 lodash 的 isEqual 進行深度比較
import {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  reconnectEdge,
  // getOutgoers,
} from "@xyflow/react";
import { FlowContext } from "./store/flowContext";
import ItemTypes from "./type/ItemTypes";
import FlowState from "./type/FlowState";
import { validateFlowData, generateEdge } from "../utils/methods";

export type CustomItemList = {
  [key: string]: {
    Component?: ReactNode | ((e: any) => void);
    Setting?: ReactNode | ((e: any) => void);

    // node
    defaultSetting?: any;

    // nodeToolbar
    onSetting?: (e: string) => void;
    onCopy?: (e: string) => void;
    onDelete?: (e: string) => void;
  };
};

export interface FlowContainerProps {
  children?: React.ReactNode;

  nodes: any[];
  edges: any[];
  onNodesChange: (value: any) => void;
  onEdgesChange: (value: any) => void;
  customItemList?: CustomItemList;
  flowState: FlowState;
  showList?: boolean;
  screenfullElement?: any;
}

// 定義 Ref 類型
export interface FlowContainerRef {
  /**
   * 檢查 flow 完整度
   * @returns any
   */
  validateFlow: () => any;

  /**
   * 更新節點資料
   * @param newNodes node[]
   * @param newEdges edge[]
   * @returns void
   */
  update: (newNodes: any, newEdges: any) => void;

  /**
   * 清空 node edge
   * @returns void
   */
  reset: () => void;
}

const FlowContainer = forwardRef<FlowContainerRef, FlowContainerProps>(
  (
    {
      children,
      nodes: _nodes,
      edges: _edges,
      onNodesChange: _onNodesChange,
      onEdgesChange: _onEdgesChange,
      customItemList,
      flowState,
      showList = false,
      screenfullElement,
    },
    ref
  ) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(
      structuredClone(_nodes)
    ); // 物件節點
    const [edges, setEdges, onEdgesChange] = useEdgesState(
      structuredClone(_edges)
    ); // 連接條

    useImperativeHandle(ref, () => ({
      validateFlow() {
        return validateFlow(nodes, edges);
      },
      update: update,
      reset() {
        setNodes([]);
        setEdges([]);
      },
    }));

    // 資料更新到外層
    useEffect(() => {
      if (!isEqual(nodes, _nodes)) {
        _onNodesChange(structuredClone(nodes));
      }
    }, [nodes]);

    useEffect(() => {
      if (!isEqual(edges, _edges)) {
        _onEdgesChange(structuredClone(edges));
      }
    }, [edges]);
    // 資料更新到外層

    // const handleOnNodesChange = useCallback((value: any) => {
    //   console.log(value);
    //   onNodesChange(value);
    //   // _onNodesChange(nodes);
    // }, []);

    /**
     * 1. 一個 button 對應多個 node，但 node 類別只能以下擇一 ex:step/backToPrevious/createNewVersion/close
     * 2. 一個 button 最多兩條 edges，sendEmail 和其他 node 共兩條 edges
     * 3. 同一個 step 出來的多個 button 只能有一條連線到以下 type ex:step/backToPrevious/createNewVersion/close
     */
    const isValidConnectionButton = useCallback(
      (sourceId: string) => {
        const findEdges = edges
          .filter((edge: any) => edge.source === sourceId)
          .map((v) => v.target);

        const findNodes = nodes.filter((v) => findEdges.includes(v.id));
        // rule 1.
        const rule1 =
          findNodes.filter((v) => v.type !== ItemTypes.SENDEMAIL).length < 1;

        // rule 2.
        const rule2 =
          findNodes.filter((v) => v.type === ItemTypes.SENDEMAIL).length < 1;

        // rule 3.
        let rule3_STEP = true;
        let rule3_BACKTOPREVIOUS = true;
        let rule3_CREATENEWVERSION = true;
        const rule3_CLOSE = true; // 同個 step 可以有多個 close
        const findParentStep = edges.find((v) => v.target === sourceId);
        if (findParentStep) {
          const allSameButton = edges
            .filter((v) => v.source === findParentStep.source)
            .map((v) => v.target);

          const allSameEdges = edges
            .filter((v) => allSameButton.includes(v.source))
            .map((v) => v.target);

          const allSameNodes = nodes.filter((v) => allSameEdges.includes(v.id));

          rule3_STEP =
            allSameNodes.filter((v) => v.type === ItemTypes.STEP).length < 1;

          rule3_BACKTOPREVIOUS =
            allSameNodes.filter((v) => v.type === ItemTypes.BACKTOPREVIOUS)
              .length < 1;

          rule3_CREATENEWVERSION =
            allSameNodes.filter((v) => v.type === ItemTypes.CREATENEWVERSION)
              .length < 1;

          // rule3_CLOSE =
          //   allSameNodes.filter((v) => v.type === ItemTypes.CLOSE).length < 1;
        }

        return {
          [ItemTypes.STEP]: rule1 && rule3_STEP,
          [ItemTypes.SENDEMAIL]: rule2,
          [ItemTypes.BACKTOPREVIOUS]: rule1 && rule3_BACKTOPREVIOUS,
          [ItemTypes.CREATENEWVERSION]: rule1 && rule3_CREATENEWVERSION,
          [ItemTypes.CLOSE]: rule1 && rule3_CLOSE,
        };
      },
      [nodes, edges]
    );

    /**
     * 尋找自己往前連接的 edges
     */
    const findConnectionEdges = useCallback(
      (id: string) => {
        const lineSegment = []; // 用來存儲線段資料
        const visitedTargets = new Set(); // 記錄已訪問的 target，避免無限循環

        let currentTarget = id;
        const maxIterations = 100; // 防止意外的無限循環
        let iterationCount = 0;

        while (currentTarget && iterationCount < maxIterations) {
          // 如果該 target 已經訪問過，說明出現循環，停止查找
          if (visitedTargets.has(currentTarget)) {
            // console.warn(`Cycle detected at target: ${currentTarget}`);
            break;
          }

          // 將目前的 target 加入已訪問集合
          visitedTargets.add(currentTarget);

          // 找出以 currentTarget 為 source 的 edge
          const prevEdge = edges.find((edge) => edge.target === currentTarget);

          // 如果沒有更多的邊，結束迴圈
          if (!prevEdge) break;

          // 將該 edge 加入線段陣列
          lineSegment.unshift(prevEdge); // 插入到陣列前面

          // 更新 currentTarget 為該 edge 的 source，繼續下一輪查找
          currentTarget = prevEdge.source;

          // 更新迴圈計數
          iterationCount++;
        }

        // 檢查是否超出安全迭代次數
        if (iterationCount >= maxIterations) {
          console.error(
            "Exceeded maximum iterations, possible infinite loop detected."
          );
        }

        return lineSegment;
      },
      [edges]
    );

    /**
     * Check if the given connection is valid.
     * 目前邏輯
     * StepNode -> ButtonNode => StepNode => New ButtonNode
     *                        => Close
     *                        => BackToPrevious
     *                        => SendEmailNode
     *                        => CreateNewVersion
     * @param connection The connection to check.
     * @returns True if the connection is valid, false otherwise.
     */
    const isValidConnection = useCallback(
      (connection: any) => {
        const { source, target, sourceHandle, targetHandle } = connection;

        // button 連接判斷
        if (sourceHandle === `${ItemTypes.BUTTON}Out`) {
          const buttonRule = isValidConnectionButton(source);
          // Node Rule: 不能連到已經連接過的 node (前面的 node)
          const stepRule =
            edges.findIndex((v) => v.target === connection.target) === -1;

          switch (targetHandle) {
            case `${ItemTypes.BACKTOPREVIOUS}In`:
              return buttonRule[ItemTypes.BACKTOPREVIOUS] && stepRule;

            case `${ItemTypes.CLOSE}In`: // close 可以多個 step 連入
              return buttonRule[ItemTypes.CLOSE];

            case `${ItemTypes.CREATENEWVERSION}In`:
              return buttonRule[ItemTypes.CREATENEWVERSION] && stepRule;

            case `${ItemTypes.SENDEMAIL}In`:
              return buttonRule[ItemTypes.SENDEMAIL];

            case `${ItemTypes.STEP}In`:
              return buttonRule[ItemTypes.STEP] && stepRule;

            default:
              return false;
          }
        }

        // step 連接判斷
        // Rule1.只能連到 button
        // Rule2.不能往回連接到 button
        if (
          sourceHandle === `${ItemTypes.STEP}Out` &&
          targetHandle === `${ItemTypes.BUTTON}In`
        ) {
          return edges.findIndex((v) => v.source === connection.target) === -1;
        }

        // Rule 1: Back to previous, New version, 只能連到 step
        if (
          [
            `${ItemTypes.BACKTOPREVIOUS}Out`,
            `${ItemTypes.CREATENEWVERSION}Out`,
          ].includes(sourceHandle) &&
          targetHandle === `${ItemTypes.STEP}In`
        ) {
          const steps = findConnectionEdges(source)
            .filter((v) => v.sourceHandle === `${ItemTypes.STEP}Out`)
            .map((v) => v.source);

          return steps.includes(target);
        }
        return false;

        // Preventing Cycles
        // const target = nodes.find((node) => node.id === connection.target);
        // const hasCycle = (node: any, visited = new Set()) => {
        //   if (visited.has(node.id)) return false;

        //   visited.add(node.id);

        //   for (const outgoer of getOutgoers(node, nodes, edges)) {
        //     if (outgoer.id === connection.source) return true;
        //     if (hasCycle(outgoer, visited)) return true;
        //   }
        // };

        // if (target.id === connection.source) return false;
        // return !hasCycle(target);
      },
      [nodes, edges]
    );

    const validateFlow = useCallback(
      (_nodes: any[] = nodes, _edges: any[] = edges) => {
        return validateFlowData(_nodes, _edges);
      },
      [nodes, edges]
    );

    const onReconnect = useCallback(
      (oldEdge: any, newConnection: any) =>
        setEdges((els) => reconnectEdge(oldEdge, newConnection, els)),
      []
    );

    const onConnect = useCallback(
      (params: any) =>
        setEdges((els) =>
          addEdge(
            {
              ...params,
              animated:
                params.sourceHandle === "stepOut" &&
                params.targetHandle === "buttonIn",
              // type: "floating",
              // markerEnd: { type: MarkerType.Arrow }, // TODO 箭頭，但是沒有對齊節點
            },
            els
          )
        ),
      [setEdges]
    );

    // 更新 nodes 裡的 data
    const onChangeNodesData = useCallback(
      (id: string, params: any) =>
        setNodes((nodes) => {
          const newNodes = structuredClone(nodes);
          return newNodes.map((node) => {
            if (node.id === id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  ...params,
                },
              };
            }
            return node;
          });
        }),
      [setNodes]
    );

    // Copy
    const onCopyNodes = useCallback(
      (id: string) => {
        const findNodesIndex = nodes.findIndex((v) => v.id === id);
        if (findNodesIndex === -1) {
          return;
        } else {
          setNodes((eds) => {
            const newEds = structuredClone(eds);
            const newNode = {
              ...structuredClone(nodes[findNodesIndex]),
              id: uuidv4(),
              position: {
                x: (nodes[findNodesIndex].position.x += 20),
                y: (nodes[findNodesIndex].position.y += 20),
              },
              selected: false,
            };
            newEds.push(newNode);
            return newEds;
          });
        }
      },
      [nodes, setNodes]
    );
    // Delete
    const onDeleteNodes = useCallback(
      (id: string) => {
        const findNodesIndex = nodes.findIndex((v) => v.id === id);
        if (findNodesIndex === -1) {
          return;
        } else {
          // 刪除 step 要連同後面的 button 一起刪除
          if (nodes[findNodesIndex].type === ItemTypes.STEP) {
            const findEdges = edges.filter(
              (v) => v.source === nodes[findNodesIndex].id
            );
            const findEdgesTargetId = findEdges.map((v) => v.target);
            const connectNodes = nodes.filter((v) =>
              findEdgesTargetId.includes(v.id)
            );
            const connectNodesId = connectNodes.map((v) => v.id);
            findEdges.push(
              ...edges.filter((v) => connectNodesId.includes(v.source))
            );

            setEdges((els) => {
              const allDeleteEdges = findEdges.map((v) => v.id);
              const newEls = structuredClone(els).filter(
                (v) => !allDeleteEdges.includes(v.id)
              );
              return newEls;
            });

            setNodes((eds) => {
              const allDeleteNodes = connectNodesId.concat(
                nodes[findNodesIndex].id
              );
              const newEds = structuredClone(eds).filter(
                (v) => !allDeleteNodes.includes(v.id)
              );
              return newEds;
            });
          } else {
            setEdges((els) => {
              const newEls = structuredClone(els).filter(
                (v) =>
                  v.source !== nodes[findNodesIndex].id &&
                  v.target !== nodes[findNodesIndex].id
              );
              return newEls;
            });

            setNodes((eds) => {
              const newEds = structuredClone(eds);
              newEds.splice(findNodesIndex, 1);
              return newEds;
            });
          }
        }
      },
      [nodes, setNodes, edges, setEdges]
    );

    // Add node and edge
    const onAddNodeAndEdge = useCallback(
      (newNode: any, newEdge: any) => {
        setNodes((nds: any) => nds.concat(newNode));
        setEdges((eds: any) => eds.concat(newEdge));
      },
      [setNodes, setEdges]
    );

    // Add edge
    const onConnectNode = useCallback(
      (sourceId: string, targetId: string, removeAll: boolean = !false) => {
        const findTargetNode = nodes.find((v) => v.id === targetId);
        const findSourceNode = nodes.find((v) => v.id === sourceId);
        const isEdgesDuplicate = edges.find(
          (v) =>
            v.source === findSourceNode.id && v.target === findTargetNode.id
        );

        if (removeAll) {
          const isAllConnected = edges
            .filter((v) => v.source === findSourceNode.id)
            .map((v) => v.id);
          setEdges((eds: any) =>
            eds.filter((v: any) => !isAllConnected.includes(v.id))
          );
        } else if (isEdgesDuplicate) {
          setEdges((eds: any) =>
            eds.filter((v: any) => v.id !== isEdgesDuplicate.id)
          );
        }

        if (findSourceNode && findTargetNode) {
          const newEdge = generateEdge(
            findSourceNode.id,
            findTargetNode.id,
            findSourceNode.type,
            findTargetNode.type
          );
          setEdges((eds: any) => eds.concat(newEdge));
        }
      },
      [nodes, edges, setEdges]
    );

    // Step Connect Button
    const findConnectButton = useCallback(
      (sourceId: string) => {
        const findEdges = edges
          .filter((edge: any) => edge.source === sourceId)
          .map((v) => v.target);
        const connectNodes: any[] = nodes.filter(
          (v) => findEdges.includes(v.id) && v.type === ItemTypes.BUTTON
        );
        return connectNodes;
      },
      [nodes, edges]
    );

    // 前面可連線的 Step
    const findConnectStep = useCallback(
      (sourceId: string) => {
        const connectEdges = findConnectionEdges(sourceId)
          .filter((v) => v.sourceHandle === `${ItemTypes.STEP}Out`)
          .map((v) => v.source);
        const connectNodes = nodes.filter(
          (v) => connectEdges.includes(v.id) && v.type === ItemTypes.STEP
        );
        return connectNodes;
      },
      [nodes, edges, findConnectionEdges]
    );

    // 已經連到的 Step
    const findEdgesConnectStep = useCallback(
      (sourceId: string, connectNodes: any[]) => {
        const connectNodesId = connectNodes.map((vv) => vv.id);
        const findEdges = edges
          .filter(
            (v) => connectNodesId.includes(v.target) && v.source === sourceId
          )
          .map((v) => v.target);
        return findEdges;
      },
      [edges]
    );

    const isValidateFlow = useMemo(() => {
      return validateFlow(nodes, edges);
    }, [nodes, edges]);

    // 找出第一個 Step, 沒有 sourceHandle 或 只能是 BACKTOPREVIOUS 和 CREATENEWVERSION 連入
    const findFirstStep = useCallback(
      (_nodes: any[] = nodes, _edges: any[] = edges) => {
        const allStep = _nodes.filter((v) => v.type === ItemTypes.STEP);
        const findFirst = allStep.filter((v) => {
          const _findEdges = _edges.filter(
            (vv) =>
              vv.target === v.id &&
              vv.sourceHandle !== `${ItemTypes.BACKTOPREVIOUS}Out` &&
              vv.sourceHandle !== `${ItemTypes.CREATENEWVERSION}Out`
          );
          return _findEdges.length === 0;
        });
        return findFirst;
      },
      [edges, nodes]
    );

    const isFirstStep = useMemo(() => {
      return findFirstStep(nodes, edges);
    }, [nodes, edges]);

    // 更新資料
    const update = useCallback(
      (newNodes: any, newEdges: any) => {
        setNodes(structuredClone(newNodes));
        setEdges(structuredClone(newEdges));
      },
      [setEdges, setNodes]
    );

    return (
      <FlowContext.Provider
        value={{
          // 基礎資料
          nodes,
          edges,
          setNodes,
          setEdges,
          update,
          isValidConnection,
          isValidConnectionButton,
          findConnectionEdges,
          onNodesChange,
          onEdgesChange,
          customItemList,
          screenfullElement,

          flowState,
          showList,

          onReconnect,
          onConnect,

          onChangeNodesData,
          onCopyNodes,
          onDeleteNodes,
          onAddNodeAndEdge,
          onConnectNode,
          validateFlow,
          isValidateFlow,
          findFirstStep,
          isFirstStep,

          findConnectButton,
          findConnectStep,
          findEdgesConnectStep,
        }}
      >
        <ReactFlowProvider>{children}</ReactFlowProvider>
      </FlowContext.Provider>
    );
  }
);

export default memo(FlowContainer);
