import type { CSSProperties } from "react";
import React, { useState, useMemo, useRef } from "react";
import type { XYCoord } from "react-dnd";
import { useDrag, useDrop } from "react-dnd";
import { useList } from "../../store/listContext";
import { FormState } from "../../type/AttrTypes";
import { icons } from "../../../assets";
const { DragIcon } = icons;

interface DraggableColumnProps {
  index: number;
  item: any;
  children: React.ReactNode;
  itemType?: string;
  valueKey?: string;
  alwaysShowDrag?: boolean;
  iconSettingMenu?: React.ReactNode | React.JSX.Element;
  iconPosition?: "left" | "right" | "center";
  direction?: "vertical" | "horizontal";
}

const dragIconStyle: CSSProperties = {
  position: "absolute",
  cursor: "grab",
  // backgroundColor: "#FEFEFE",
  // border: "1px solid #F0F0F2",
  // boxShadow: "0px 0px 8px 0px #0029A514",
  // borderRadius: "6px",
};

const iconCenterTopStyle: CSSProperties = {
  position: "absolute",
  top: "-15px",
  left: "50%",
  transform: "translateX(-50%)",
};
const iconRightStyle: CSSProperties = {
  position: "absolute",
  transform: "translateY(-50%)",
  top: "50%",
  right: "4px",
};
const iconLeftStyle: CSSProperties = {
  position: "unset",
  left: 0,
};
const iconPositionStyle: { [key: string]: CSSProperties } = {
  right: iconRightStyle,
  center: iconCenterTopStyle,
  left: iconLeftStyle,
};

const DraggableColumn: React.FC<DraggableColumnProps> = ({
  index,
  item,
  children,
  itemType = "inputTableColumn",
  valueKey = "components",
  alwaysShowDrag = false,
  iconSettingMenu,
  iconPosition = "right",
  direction = "horizontal",
}) => {
  const { state, moveTableCol } = useList();

  const dropRef = useRef<HTMLTableCellElement>(null);
  const [hovered, setHovered] = useState(false); // 控制鼠標懸停狀態

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const handleonMouseEnter = () => {
    setHovered(!readOnly && true);
  };
  const handleonMouseLeave = () => {
    setHovered(false);
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: itemType,
    item: { index, item, isIndependent: true },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: itemType,
    hover: (item: { index: number; item: any }, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (item.index === index || !isOver) return;

      if (Array.isArray(item.item[valueKey])) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (direction === "vertical") {
          const hoverBoundingRect =
            dropRef.current?.getBoundingClientRect() as DOMRect;
          const clientOffset = monitor.getClientOffset();
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return false;
          }

          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return false;
          }
        } else if (direction === "horizontal") {
          const hoverBoundingRect =
            dropRef.current?.getBoundingClientRect() as DOMRect;
          const clientOffset = monitor.getClientOffset();
          const hoverMiddleX =
            (hoverBoundingRect.left + hoverBoundingRect.right) / 2;
          const hoverClientX = (clientOffset as XYCoord).x;

          if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return false;
          }

          if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return false;
          }
        }

        moveTableCol(item.item.id, dragIndex, hoverIndex);
        item.index = index;
      }
    },
  });

  preview(drop(dropRef));

  return (
    <div
      ref={dropRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        position: "relative",
        display: "flex",
        flexDirection: iconPosition === "left" ? "row-reverse" : "row",
        height: "100%",
        alignItems: "center",
      }}
      onMouseEnter={handleonMouseEnter}
      onMouseLeave={handleonMouseLeave}
    >
      <div style={{ flex: 1, height: "100%" }}>{children}</div>
      <div
        ref={drag} // 拖拽行为绑定在这个符号上
        style={{
          ...dragIconStyle,
          padding: iconSettingMenu ? 0 : "4px",
          ...(iconPositionStyle[iconPosition] || {}),
          display: hovered || alwaysShowDrag ? "block" : "none",
        }}
      >
        {iconSettingMenu || <DragIcon />}
      </div>
    </div>
  );
};

export default DraggableColumn;
