import type { FC } from "react";
import React, { useCallback, useState, memo, useRef } from "react";
import {
  ReactFlow,
  Background,
  Controls,
  MiniMap,
  BackgroundVariant,
  ControlButton,
  useReactFlow,
} from "@xyflow/react";
import dagre from "@dagrejs/dagre";

import FlowState from "./type/FlowState";
import ItemTypes, { CustomNodes } from "./type/ItemTypes";
import { useFlow } from "./store/flowContext";

import { CustomEdges, FloatingConnectionLine } from "./customEdges";
import { generateNodeAndEdge } from "../utils/methods";

import { icons } from "../assets";
import "@xyflow/react/dist/style.css";

const {
  SettingIcon,
  ScreenfullIcon,
  ScreenShrinkIcon,
  MiniMapIcon,
  HorizontalLayoutIcon,
  VerticalLayoutIcon,
} = icons;

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = CustomNodes as any;
const edgeTypes = CustomEdges;

const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const FlowArea: FC = () => {
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    isValidConnection,
    validateFlow,
    onReconnect,
    onConnect,
    flowState,
    showList = false,
    customItemList,
    screenfullElement,
    onAddNodeAndEdge,
  } = useFlow();

  const { screenToFlowPosition } = useReactFlow();
  const containerRef = useRef<HTMLDivElement>(null); // 參考容器
  const [isShowMiniMap, setIsShowMiniMap] = useState(false);
  const [fullScreenVisible, setFullScreenVisible] = useState(false);

  const readOnly = flowState === FlowState.PREVIEW;

  /**
   * 預設 step 連接 button
   * 目前除 step 以外不做自動新增
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConnectEnd = useCallback(
    (event: any, connectionState: any) => {
      if (connectionState.fromNode.type !== ItemTypes.STEP) {
        return;
      }

      // when a connection is dropped on the pane it's not valid
      if (!connectionState.isValid) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { clientX, clientY } =
          "changedTouches" in event ? event.changedTouches[0] : event;

        switch (connectionState.fromNode.type) {
          case ItemTypes.STEP: {
            const newNodeAndEdge = generateNodeAndEdge(
              connectionState.fromNode.id,
              connectionState.fromNode.type,
              ItemTypes.BUTTON,
              customItemList?.[ItemTypes.BUTTON]?.["defaultSetting"] || {},
              screenToFlowPosition({
                x: clientX,
                y: clientY,
              })
            );
            onAddNodeAndEdge(newNodeAndEdge.node, newNodeAndEdge.edge);

            break;
          }
          default:
            break;
        }
      }
    },
    [customItemList, onAddNodeAndEdge]
  );

  const getLayoutedElements = (direction = "TB") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, {
        width: node.measured.width * 1.5,
        height: node.measured.height * 1.5,
      });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const newNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const newNode = {
        ...node,
        targetPosition: isHorizontal ? "left" : "top",
        sourcePosition: isHorizontal ? "right" : "bottom",
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        position: {
          x: nodeWithPosition.x - node.measured.width / 2,
          y: nodeWithPosition.y - node.measured.height / 2,
        },
      };

      return newNode;
    });

    return { nodes: newNodes, edges };
  };

  const onLayout = useCallback(
    (direction: any) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const handleSetting = () => {
    if (
      typeof customItemList?.[ItemTypes.NODETOOLBAR]?.onSetting === "function"
    )
      customItemList[ItemTypes.NODETOOLBAR].onSetting("-1");
  };

  const handleScreenfull = () => {
    const element = screenfullElement || (containerRef.current as any);
    if (!element) return;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari, Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
    setFullScreenVisible(true);

    // 退出全屏
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
      setFullScreenVisible(false);
      return;
    }
  };

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onReconnect={onReconnect}
        onConnect={onConnect}
        // onConnectEnd={onConnectEnd}
        isValidConnection={isValidConnection}
        className="validationflow"
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={FloatingConnectionLine}
        fitView
        // elementsSelectable={!readOnly}
        nodesDraggable={!readOnly}
        nodesConnectable={!readOnly}
        deleteKeyCode={null}
      >
        <Background
          variant={BackgroundVariant.Dots}
          color="#F0F0F2"
          bgColor="#FAFAFB"
          size={5}
        />
        <Controls orientation="horizontal" showInteractive={false}>
          <ControlButton onClick={handleSetting}>
            <SettingIcon style={{ maxWidth: 26, maxHeight: 26 }} />
          </ControlButton>
          <ControlButton onClick={handleScreenfull}>
            {fullScreenVisible ? (
              <ScreenShrinkIcon style={{ maxWidth: 26, maxHeight: 26 }} />
            ) : (
              <ScreenfullIcon style={{ maxWidth: 26, maxHeight: 26 }} />
            )}
          </ControlButton>

          {showList ? (
            <>
              <ControlButton onClick={() => console.log(validateFlow())}>
                ✨
              </ControlButton>
            </>
          ) : null}
        </Controls>

        <Controls
          orientation="horizontal"
          showInteractive={false}
          position={"bottom-right"}
          style={{ right: 30 }}
          showZoom={false}
          showFitView={false}
        >
          <ControlButton onClick={() => onLayout("TB")}>
            <VerticalLayoutIcon style={{ maxWidth: 26, maxHeight: 26 }} />
          </ControlButton>

          <ControlButton onClick={() => onLayout("LR")}>
            <HorizontalLayoutIcon style={{ maxWidth: 26, maxHeight: 26 }} />
          </ControlButton>
        </Controls>

        <Controls
          orientation="horizontal"
          showInteractive={false}
          position={"bottom-right"}
          showZoom={false}
          showFitView={false}
        >
          <ControlButton onClick={() => setIsShowMiniMap(!isShowMiniMap)}>
            <MiniMapIcon style={{ maxWidth: 26, maxHeight: 26 }} />
          </ControlButton>
        </Controls>

        {isShowMiniMap ? <MiniMap pannable style={{ right: 30 }} /> : null}
      </ReactFlow>
    </div>
  );
};

export default memo(FlowArea);
