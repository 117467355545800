import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgDeleteIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#D6296C"}
      fillRule="evenodd"
      d="M6.1 2a1.2 1.2 0 0 0-1.2 1.2v.6H3.1a.6.6 0 0 0 0 1.2h.645l.614 7.893A1.2 1.2 0 0 0 5.555 14h5.89a1.2 1.2 0 0 0 1.196-1.107L13.255 5h.645a.6.6 0 1 0 0-1.2h-1.8v-.6A1.2 1.2 0 0 0 10.9 2zm4.8 1.8v-.6H6.1v.6zM4.948 5l.607 7.8h5.89l.607-7.8zM7.3 6.8a.6.6 0 0 1 .6.6v3a.6.6 0 1 1-1.2 0v-3a.6.6 0 0 1 .6-.6m3 .6a.6.6 0 1 0-1.2 0v3a.6.6 0 1 0 1.2 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDeleteIcon;
