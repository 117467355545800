import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgMoreIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M12.5 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M8 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M2 8a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0"
    />
  </svg>
);
export default SvgMoreIcon;
