import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgSignatureIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#E89B09"}
      fillRule="evenodd"
      d="M10.837 2.177a.62.62 0 0 1 .869 0l1.736 1.71c.24.237.24.62 0 .856l-2.496 2.459.943.53c.17.096.326.23.432.323l.032.027c.126.109.214.178.302.226C13.707 8.876 14 9.972 14 10.976 14 12.646 12.626 14 10.93 14h-.614a3.1 3.1 0 0 1-2.08-.8l-1.712-.26a1.85 1.85 0 0 1-.953-.445l-.812.8a.6.6 0 0 1-.33.168l-2.097.355a.62.62 0 0 1-.538-.169.6.6 0 0 1-.171-.53l.36-2.064v-.004a1.8 1.8 0 0 1-.456-.335 1.795 1.795 0 0 1 .024-2.564l1.475-1.443.014-.014.034-.035.012-.012.233-.23.001-.001.025-.023.029-.028c.278-.276.752-.748 1.492-.737l2.433.033zM7.8 6.878l.12-.117 3.351-3.301.869.855-4.386 4.337-.553.702h-.115C6.08 9.2 5.139 9.88 4.984 10.87q-.04.256-.008.5l-.948.934-1.048.177.18-1.032.296-.394c-.198.191 0 .016 0 0l2.202-2.067zm-1.712-.023-1.24-.017c-.18-.003-.307.089-.65.428l-.03.027-.013.011-.02.02-.183.183.013-.014-.013.014-.064.063-1.476 1.444a.6.6 0 0 0-.008.855c.12.121.28.182.44.181zM9.38 8.743l-.492.54v.014l.089.05c.082.047.21.12.31.207a.74.74 0 0 1 .217.312.63.63 0 0 1-.226.72.8.8 0 0 1-.355.14 2 2 0 0 1-.258.02 7 7 0 0 1-.698-.036l-.02-.001-1.05-.16a.613.613 0 0 0-.7.505.606.606 0 0 0 .513.69l2.117.322.145.152c.337.353.813.572 1.343.572h.614c1.017 0 1.842-.812 1.842-1.814 0-.826-.238-1.354-.708-1.608a3 3 0 0 1-.518-.375 2 2 0 0 0-.27-.214l-1.23-.69zM4.165 7.295l.032-.03z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSignatureIcon;
