import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgRedoIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      fillRule="evenodd"
      d="M2.742 12A.75.75 0 0 1 2 11.24l.021-.88c.109-4.573 5.771-6.79 9.081-3.555L12.48 8.15V5.743c0-.41.34-.743.76-.743s.76.333.76.743v4.201c0 .41-.34.743-.76.743h-4.3a.75.75 0 0 1-.76-.743c0-.41.34-.742.76-.742h2.465l-1.378-1.347c-2.364-2.31-6.408-.727-6.486 2.54l-.021.88a.753.753 0 0 1-.778.725"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRedoIcon;
