import type { CSSProperties, FC } from "react";
import React, {
  useEffect,
  useRef,
  useState,
  memo,
  useMemo,
  useCallback,
} from "react";
import { isEqual } from "lodash";
import { Flipped } from "react-flip-toolkit";
import { ResizableBox } from "react-resizable";
import ItemTypesEnum, {
  ItemComponents,
  OtherSettingTypesEnum,
} from "../../type/ItemTypes";
import {
  BaseData,
  BaseContainerData,
  generateDefaultItem,
} from "../../type/Interface";
import { FormState, PhoneSizeState } from "../../type/AttrTypes";
import { useList } from "../../store/listContext";
import { useMoveFormProperty } from "../../hook/useMoveFormProperty";
import { ItemToolBar } from "../../components";
import RowText from "./RowText";
import { icons } from "../../../assets";
import "react-resizable/css/styles.css";
import { renderCustomItemSetting } from "../../../utils/methods";

const { DragIcon } = icons;

const defaultHeight = 100;

const noChildrenStyle: CSSProperties = {
  // padding: "1rem 1rem",
  // marginBottom: ".5rem",
  position: "relative", // 为了定位拖拽符号
  cursor: "default", // 避免整个行都显示为可拖拽
  height: "100%",
  display: "flex", // 添加這行
  minHeight: defaultHeight,
};

const rowStyle: CSSProperties = {
  position: "relative", // 为了定位拖拽符号
  cursor: "default", // 避免整个行都显示为可拖拽
  display: "flex", // 添加這行
  minHeight: defaultHeight,
};

const row2Style: CSSProperties = {
  flex: 1,
  width: "100%",
};

const editStyle: CSSProperties = {
  // border: "1px solid #BFC1C7", // #BFC1C7
  // borderRadius: "8px",
};

const focusEditStyle: CSSProperties = {
  ...editStyle,
  border: "1px solid #3F8DFF", // 使用藍色邊框表示focus狀態
};

const dragAreaStyle: CSSProperties = {
  position: "absolute",
  height: "100%",
  width: "28px",
  backgroundColor: "#FFF0", // "#FEFEFE",
  cursor: "move",
  borderRadius: "0px 8px 8px 0px",
  left: "100%",
  top: 0,
};

const dragHandleStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const nestedRowStyle: CSSProperties = {
  display: "flex", // 使用 flex 布局实现左右排列
  gap: "1px", // 设置行之间的间距
};

const nestedRowChildrenStyle: CSSProperties = {
  flex: 1,
  height: "max-content",
};

// 控制鼠標懸停狀態
const HoveredContainer: FC<{
  children: React.ReactNode;
  readOnly?: boolean;
  isDragging?: boolean;
  isTarget?: boolean;
  isTargetChild?: boolean;
  isFormTable?: boolean;
  canDrag?: boolean;
  drag?: any;
}> = ({
  children,
  readOnly,
  isDragging,
  isTarget,
  isTargetChild,
  isFormTable,
  canDrag,
  drag,
}) => {
  const [hovered, setHovered] = useState(false); // 控制鼠標懸停狀態

  const handleonMouseEnter = () => {
    setHovered(!readOnly && true);
  };
  const handleonMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      style={{
        ...(children ? rowStyle : noChildrenStyle),
        ...(readOnly
          ? {}
          : isTarget || isTargetChild
            ? focusEditStyle
            : {
                border: hovered ? "1px solid #BFC1C7" : "1px solid transparent",
              }),
        opacity: isDragging ? 0.5 : 1,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: isFormTable ? 0 : "8px",
      }}
      onMouseEnter={handleonMouseEnter}
      onMouseLeave={handleonMouseLeave}
    >
      {children}

      {!readOnly && (hovered || isTarget) && canDrag && (
        <div style={dragAreaStyle}>
          <div
            ref={drag} // 拖拽行为绑定在这个符号上
            style={dragHandleStyle}
          >
            <DragIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export interface RowProps {
  id: string;
  item: BaseContainerData;
  pathIndex: number[];
  canDrag?: boolean;
  isFormTable?: boolean;
}

export const Item: FC<RowProps> = ({
  id,
  item,
  pathIndex,
  canDrag = true,
  isFormTable = false,
}) => {
  const {
    state,
    targetItem,
    isCanResizableBox,
    targetChildrenId,
    updateSecondLayerHeight,
    currentStepId,
    hasTeamPermission,
    isProjectManager,
    customItemList,
    findSecondLayer,
  } = useList();

  const children = item.components as BaseContainerData[];

  const dropRef = useRef<HTMLDivElement>(null);

  const isTarget = targetItem.id === id;
  const isTargetChild = item.components.some(
    (v: any) => v.id === targetItem.id
  );

  // 拖曳高度
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: item?.height || 0,
  });

  // 获取渲染后的高度
  const childrenDivRef = useRef<HTMLDivElement | null>(null);
  const [minHeight, setMinHeight] = useState(defaultHeight);

  // 判斷寬度調整 Row div 排版
  const nestedRowContainerRef = useRef<HTMLDivElement>(null);
  const [isVertical, setIsVertical] = useState(false);

  const readOnly = useMemo(() => {
    return !(state === FormState.CREATE);
  }, [state]);

  const { drag, drop, preview, isDragging, handlerId } = useMoveFormProperty({
    id,
    item,
    pathIndex,
    itemType: item.type,
    dropRef,
    canDrag,
  });

  const handleTargetItem = (_event: any) => {
    _event.stopPropagation();
    // 顯示 components 第一個內容
    if (item.components.length > 0) {
      targetChildrenId(item.components[0].id);
    } else {
      targetChildrenId(id);
    }
  };

  const handleOnSetting = (_event: any) => {
    _event.stopPropagation();

    // 顯示 components 第一個內容
    const findItem = findSecondLayer(id);
    if (findItem?.item && findItem.item.components.length > 0) {
      targetChildrenId(findItem.item.components[0].id);
      renderCustomItemSetting(
        customItemList,
        OtherSettingTypesEnum.ITEMSETTING,
        "onSetting",
        id
      );
    }
  };

  const renderItem = useCallback(
    (_item: BaseData, _index: number) => {
      const Component = ItemComponents[_item.type].Item;
      // 找出項目的setting.steps是否有包含currentStepId
      const isMatchingStep = _item.setting?.steps?.some(
        (step) => step.id === currentStepId
      );
      // 如果isMatchingStep和hasTeamPermission都為true，則表示這個人可以填寫這個欄位
      const isCurrentStep = isMatchingStep && hasTeamPermission;

      const dataStepId = _item.setting?.steps?.find(
        (step) => step.id === currentStepId
      )?.id;

      return (
        <Flipped
          key={isFormTable ? `formTable_${_item.id}` : _item.id}
          flipId={_item.id}
          translate={true}
          scale={false}
        >
          <div
            key={`nested_${_item.id}`}
            data-step-id={
              _item?.type !== ItemTypesEnum.HEADERSECTION &&
              _item.type !== ItemTypesEnum.IMAGE
                ? dataStepId
                : ""
            }
            data-field-id={_item.id}
            style={{
              ...nestedRowChildrenStyle,
              border:
                isCurrentStep &&
                _item?.type !== ItemTypesEnum.HEADERSECTION &&
                _item?.type !== ItemTypesEnum.IMAGE
                  ? "2px solid #F6DE04"
                  : "",
              borderRadius: isFormTable ? 0 : "8px",
              minWidth: 0,
            }}
          >
            <Component
              key={`item_${_item.id}`}
              id={_item.id}
              item={_item}
              pathIndex={[...pathIndex, _index]}
              itemType={_item.type}
              isCurrentStep={isCurrentStep || isProjectManager}
            />
          </div>
        </Flipped>
      );
    },
    [currentStepId, isFormTable, pathIndex, hasTeamPermission]
  );

  const renderItemMemo = useMemo(
    () => children.map(renderItem),
    [children, renderItem]
  );

  // 拖曳高度
  const handleOnResize = (_event: any, { size }: any) => {
    setDimensions((prev) => {
      const newHeight = size.height;
      updateSecondLayerHeight(id, newHeight);
      return {
        width: prev.width,
        height: newHeight < minHeight ? minHeight : newHeight,
      };
    });
  };
  // 拖曳高度 end

  // 最小高度
  useEffect(() => {
    if (childrenDivRef.current) {
      const divHeight = childrenDivRef.current.clientHeight; // 或者使用 offsetHeight
      if (minHeight !== divHeight) setMinHeight(divHeight);
    }
  }, [children]);
  // 最小高度 end

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        setIsVertical(width < PhoneSizeState.LARGE); // 當外層寬度小於 px 時切換為垂直排列
      }
    });

    if (nestedRowContainerRef.current) {
      observer.observe(nestedRowContainerRef.current);
    }

    return () => {
      if (nestedRowContainerRef.current) {
        observer.unobserve(nestedRowContainerRef.current);
      }
    };
  }, []);

  preview(drop(dropRef)); // 绑定 drop 和 preview

  const isShowItemToolBar = useMemo(() => {
    if (readOnly) return false;
    if (isFormTable) {
      return isTargetChild;
    } else {
      return isTarget || isTargetChild;
    }
  }, [isFormTable, isTarget, isTargetChild, readOnly]);

  const childrenItem = (
    <div
      ref={dropRef}
      data-handler-id={handlerId}
      onClick={handleTargetItem}
      onDoubleClick={handleOnSetting}
    >
      <HoveredContainer
        readOnly={readOnly}
        isFormTable={isFormTable}
        isDragging={isDragging}
        isTarget={isTarget}
        isTargetChild={isTargetChild}
        canDrag={canDrag}
        drag={drag}
      >
        <div ref={nestedRowContainerRef} style={row2Style}>
          {children.length > 0 ? (
            <div
              ref={childrenDivRef}
              style={{
                ...nestedRowStyle,
                flexDirection: isVertical ? "column" : "row",
              }}
            >
              {renderItemMemo}
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // minWidth: 150,
              }}
            >
              {isFormTable ? <RowText item={item} /> : ""}
            </div>
          )}
        </div>

        {isShowItemToolBar ? (
          <ItemToolBar
            id={id}
            type={ItemTypesEnum.ROW}
            hideSetting={
              children.length === 1 &&
              children[0].type === ItemTypesEnum.RICHTEXT
            }
          />
        ) : null}
      </HoveredContainer>
    </div>
  );

  const itemHeight =
    (children.length > 0 && !item?.height
      ? childrenDivRef?.current?.clientHeight
      : dimensions.height) || defaultHeight;

  return (
    <Flipped flipId={id} translate={true} scale={false}>
      {!readOnly && isCanResizableBox ? (
        <ResizableBox
          width={Infinity}
          height={itemHeight}
          minConstraints={[minHeight, minHeight]}
          onResize={handleOnResize}
          axis="y"
          draggableOpts={{ grid: [12, 12] }}
        >
          {childrenItem}
        </ResizableBox>
      ) : (
        <div
          style={{
            height: "100%",
            minWidth: 0,
          }}
        >
          {childrenItem}
        </div>
      )}
    </Flipped>
  );
};

const newRowStyle: CSSProperties = {
  padding: "4px",
  cursor: "grab",
  gap: "4px",
};

export const Add: FC = () => {
  const { rowDefaultSetting } = useList();
  const item = generateDefaultItem(ItemTypesEnum.ROW, rowDefaultSetting);
  const dropRef = useRef<HTMLDivElement>(null);

  const { drag, preview, isDragging } = useMoveFormProperty({
    id: item.id,
    pathIndex: [-1, -1],
    item,
    itemType: item.type,
    dropRef,
    canDrag: true,
  });

  preview(drag(dropRef));

  return (
    <div
      ref={dropRef}
      style={{
        ...newRowStyle,
        backgroundColor: isDragging ? "lightgreen" : "lightyellow",
      }}
    >
      {ItemTypesEnum.ROW.toLocaleUpperCase()}
    </div>
  );
};

export const Preview = () => {
  return (
    <div
      style={{
        padding: "8px",
        backgroundColor: "lightblue",
        borderRadius: "4px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      Row Preview
    </div>
  );
};

export const ShortItem = () => {
  return null;
};

export const Setting = () => {
  return <div style={{ backgroundColor: "#ffffff" }} />;
};

export default {
  Item: memo(Item, (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  }),
  Add,
  Preview,
  ShortItem,
  Setting: memo(Setting),
};
