import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;
}
const SvgDragIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#262D42"}
      d="M6.5 2h1.333v1.333H6.5zm2.667 0H10.5v1.333H9.167zM6.5 4.667h1.333V6H6.5zm2.667 0H10.5V6H9.167zM6.5 7.333h1.333v1.334H6.5zm2.667 0H10.5v1.334H9.167zM6.5 10h1.333v1.333H6.5zm2.667 0H10.5v1.333H9.167zM6.5 12.667h1.333V14H6.5zm2.667 0H10.5V14H9.167z"
    />
  </svg>
);
export default SvgDragIcon;
