import type { CSSProperties, FC } from "react";
import React, { useRef, useState, memo } from "react";
import { isEqual } from "lodash";
import { useList } from "../../store/listContext";
import { BaseContainerData } from "../../type/Interface";
import { FormState } from "../../type/AttrTypes";

const inputStyle: CSSProperties = {
  width: "100%",
  backgroundColor: "rgba(0, 8, 33, 0.04)",
  borderBottom: "1px #3F8DFF solid",
  outline: 'none',
};

const textStyle: CSSProperties = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#8C909B",
  paddingLeft: "8px",
  paddingRight: "8px",

  wordWrap: "break-word",
  overflowWrap: "break-word",
  wordBreak: "break-word",
  whiteSpace: "pre-line",
};

export interface RowTextProps {
  item: BaseContainerData;
}

const RowText: FC<RowTextProps> = ({ item }) => {
  const { state, updateChildrenAttr } = useList();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(item.name || "");

  const isReadonly = state !== FormState.CREATE;

  const handleOnClickSpan = () => {
    if (!showInput && !isReadonly) {
      setShowInput(true);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 500);
    }
  };

  const handleOnBlur = () => {
    setShowInput(false);
    updateChildrenAttr(item.id, { name: inputValue });
  };

  const handleOnChange = (e: any) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <textarea
        style={{
          ...inputStyle,
          display: showInput && !isReadonly ? "block" : "none",
        }}
        placeholder="Add text"
        ref={inputRef}
        value={inputValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <span
        style={{
          ...textStyle,
          display: !showInput ? "block" : "none",
        }}
        onClick={handleOnClickSpan}
      >
        {isReadonly
          ? item.name || ""
          : item.name || "Click to type or drag a field from the field panel"}
      </span>
    </>
  );
};

export default memo(RowText, isEqual);
