import { createContext, useContext } from "react";
import { CustomItemList } from "../FlowContainer";
import ItemTypes from "../type/ItemTypes";
import FlowState from "../type/FlowState";

interface FlowContextType {
  // 基礎資料
  nodes: any[];
  edges: any[];
  setNodes: (params: any) => void;
  setEdges: (params: any) => void;
  update: (newNodes: any, newEdges: any) => void;
  isValidConnection: (params: any) => boolean;
  isValidConnectionButton: (sourceId: string) => {
    [ItemTypes.STEP]: boolean;
    [ItemTypes.SENDEMAIL]: boolean;
    [ItemTypes.BACKTOPREVIOUS]: boolean;
    [ItemTypes.CREATENEWVERSION]: boolean;
    [ItemTypes.CLOSE]: boolean;
  };
  findConnectionEdges: (id: string) => any[];
  onNodesChange: (params: any) => void;
  onEdgesChange: (params: any) => void;
  customItemList?: CustomItemList;
  screenfullElement?: any;

  flowState: FlowState;
  showList?: boolean;

  onReconnect: (oldEdge: any, newConnection: any) => void;
  onConnect: (params: any) => void;

  onChangeNodesData: (id: string, params: any) => void;
  onCopyNodes: (id: string) => void;
  onDeleteNodes: (id: string) => void;
  onAddNodeAndEdge: (newNode: any, newEdge: any) => void;
  onConnectNode: (sourceId: string, targetId: string, removeAll?: boolean) => void;
  validateFlow: (nodes?: any[], edges?: any[]) => any;
  isValidateFlow: any;
  findFirstStep: (nodes?: any[], edges?: any[]) => any;
  isFirstStep: any;


  findConnectButton: (sourceId: string) => any[];
  findConnectStep: (sourceId: string) => any[];
  findEdgesConnectStep: (sourceId: string, connectNodes: any[]) => any[];
}

export const FlowContext = createContext<FlowContextType | undefined>(
  undefined
);

export const useFlow = () => {
  const context = useContext(FlowContext);
  if (context === undefined) {
    throw new Error("useFlow must be used within a ListProvider");
  }
  return context;
};
