import * as React from "react";
import type { SVGProps } from "react";
interface MySVGProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  currentColor?: string;

  fill2?: string;
  currentColor2?: string;
}
const SvgRichTextIcon = (props: MySVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    {...props}
  >
    <path
      fill={props.fill || props.currentColor || "#99958B"}
      d="M7.333 3c0-.369.299-.667.667-.667h6a.667.667 0 1 1 0 1.333H8A.667.667 0 0 1 7.333 3M7.333 6.333c0-.368.299-.667.667-.667h6A.667.667 0 1 1 14 7H8a.667.667 0 0 1-.667-.667M2 9a.667.667 0 0 0 0 1.333h12A.667.667 0 0 0 14 9zM1.333 13c0-.368.299-.667.667-.667h12a.667.667 0 0 1 0 1.333H2A.667.667 0 0 1 1.333 13"
    />
    <path
      fill={props.fill2 || props.currentColor2 || "#99958B"}
      fillRule="evenodd"
      d="M2 2.333A.667.667 0 0 0 1.333 3v3.333c0 .368.299.667.667.667h3.333A.667.667 0 0 0 6 6.333V3a.667.667 0 0 0-.667-.667zm.667 3.333v-2h2v2z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRichTextIcon;
